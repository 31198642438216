import React from 'react';
import { Form, Input, Button,Select,DatePicker,Spin,Image, Card,Divider,Result, message,Table } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import moment from 'moment';
import * as serverconfig from '../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';
import { UserOutlined, LockOutlined, LoadingOutlined,MailOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';

var CryptoJS = require("crypto-js");


const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;


var token= ''
var sacco= ''
var username=''


class DataUploadForm extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    excelfilefile:null,
    saccoboject:{},
    datalist:[],
    uploadtype:'',
    columns:[],
    chart_ofaccountsheaders:["Account name","Acc code",'Chart of account category','Chart of account subcategory','Currency'],
    operationaldepositsheaders:['Date(10 Jun 2023)','MemberId','savingsAmount','sharesAmount','savingsinterestAmount','supersaveAmount','defferedAmount','teebaGooloAMount','loanInstallment','loanID','Transaction details','Debit Account'],
    operationalwithdraws_headers:['Date(10 Jun 2023)','MemberId','savingsAmount','supersaveAmount','teebaGooloAMount','bankcharges','BankChargefrom(Savings/Expense)','BankChargeExpenseAccount','Transaction details','CreditAccount','Withdrawcharge'],
    loanheaders:["Date(10 Jun 2023)",'Amount','Period','Loan type','Account no','Principle balance','Interest balance','Fine balance','Register Incomes',"LoanNo"],

    message:'',
    companyprofile:{},

  }

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
  
      }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/saccos/${sacco}`)
    .then(res => {
        this.setState({
          saccoboject:res.data
        })
        this.setState({datarequested:false})
    })

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
        this.setState({datarequested:false})

    })
}


 handleFileChange = (e) => {
  this.setState({datarequested:true})
  const selectedFile = e.target.files[0];
  this.setState({excelfilefile:selectedFile});

  this.handleProcessFile(selectedFile)
};


   handleProcessFile = (file) => {
    this.setState({datalist:[]})
    if (!file) {
      alert('Please select an Excel file first.');
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      // Assuming you want to process the first sheet.
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      // Convert Excel data to a list of dictionaries.
      // Extract the keys from the first row.
      const dataAsArray = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const keys = dataAsArray[0];


      ////////////////////////////////////////////
      //chart of accounts
      /////////////////////////////////
      if (JSON.stringify(keys) === JSON.stringify(this.state.chart_ofaccountsheaders) && this.state.uploadtype==="chartofaccounts"){


      // Remove the first row from the data (it contains headers).
      //dataAsArray.shift();

      // Filter out empty rows and remove the first row (header).
      const filteredData = dataAsArray.slice(1).filter((row) => row.length > 0);

      // Convert the filtered data into dictionaries with keys.
      const dataAsDictionaries = filteredData.map((row) => {
        const dictionary = {};
        keys.forEach((key, index) => {
                    // Check for empty cells and replace with "NA"
                    const cellData = row[index] !== undefined && row[index] !== '' ? row[index] : 'NA';
                    dictionary[key] = cellData === 0 ? 0 : cellData; // Preserve cells with zero value
        });
        return dictionary;
      });

       this.setState({datalist:dataAsDictionaries});

        // Create columns for the Table component
        const columns = keys.map((key) => ({
        title: key,
        dataIndex: key,
        key,
        }));
         this.setState({columns:columns});

         this.setState({datarequested:false})

      }else{
        this.setState({datarequested:false})
        if (this.state.uploadtype==="chartofaccounts"){
          message.error("Chart of account template not valid")

        }

      }



      ///////////////////////////////////////
      //single mmebers
      ////////////////////////////////
      if (JSON.stringify(keys) === JSON.stringify(this.state.singlemembersheaders) && this.state.uploadtype==="singlemembers"){


        // Remove the first row from the data (it contains headers).
        //dataAsArray.shift();
  
        // Filter out empty rows and remove the first row (header).
        const filteredData = dataAsArray.slice(1).filter((row) => row.length > 0);
  
        // Convert the filtered data into dictionaries with keys.
        const dataAsDictionaries = filteredData.map((row) => {
          const dictionary = {};
          keys.forEach((key, index) => {
                    // Check for empty cells and replace with "NA"
                    const cellData = row[index] !== undefined && row[index] !== '' ? row[index] : 'NA';
                    dictionary[key] = cellData === 0 ? 0 : cellData; // Preserve cells with zero value
          });
          return dictionary;
        });
  
         this.setState({datalist:dataAsDictionaries});
  
          // Create columns for the Table component
          const columns = keys.map((key) => ({
          title: key,
          dataIndex: key,
          key,
          }));
           this.setState({columns:columns});
  
           this.setState({datarequested:false})
  
        }else{
          this.setState({datarequested:false})
          
          if (this.state.uploadtype==="singlemembers"){
            message.error("Single members template not valid")

          }
  
        }
  

        
       ///////////////////////////////////////////
      //Groups
      ////////////////////////////////
      if (JSON.stringify(keys) === JSON.stringify(this.state.group_membersheaders) && this.state.uploadtype==="groups"){


        // Remove the first row from the data (it contains headers).
        //dataAsArray.shift();
  
        // Filter out empty rows and remove the first row (header).
        const filteredData = dataAsArray.slice(1).filter((row) => row.length > 0);
  
        // Convert the filtered data into dictionaries with keys.
        const dataAsDictionaries = filteredData.map((row) => {
          const dictionary = {};
          keys.forEach((key, index) => {
                    // Check for empty cells and replace with "NA"
                    const cellData = row[index] !== undefined && row[index] !== '' ? row[index] : 'NA';
                    dictionary[key] = cellData === 0 ? 0 : cellData; // Preserve cells with zero value
          });
          return dictionary;
        });
  
         this.setState({datalist:dataAsDictionaries});
  
          // Create columns for the Table component
          const columns = keys.map((key) => ({
          title: key,
          dataIndex: key,
          key,
          }));
           this.setState({columns:columns});
  
           this.setState({datarequested:false})
  
        }else{
          this.setState({datarequested:false})
          
          if (this.state.uploadtype==="groups"){
            message.error("groups template not valid")

          }
  
        }


        /////////////////////////////////////////////////
        ////////////////////////////////////////////////
        ///// LOAN IMPORTS
        //////////////////////////////////
        ///////////////////////////
        if (JSON.stringify(keys) === JSON.stringify(this.state.loanheaders) && this.state.uploadtype==="loans"){

          // Remove the first row from the data (it contains headers).
          //dataAsArray.shift();
    
          // Filter out empty rows and remove the first row (header).
          const filteredData = dataAsArray.slice(1).filter((row) => row.length > 0);
    
          // Convert the filtered data into dictionaries with keys.
          const dataAsDictionaries = filteredData.map((row) => {
            const dictionary = {};
            keys.forEach((key, index) => {
                    // Check for empty cells and replace with "NA"
                    const cellData = row[index] !== undefined && row[index] !== '' ? row[index] : 'NA';
                    dictionary[key] = cellData === 0 ? 0 : cellData; // Preserve cells with zero value
            });
            return dictionary;
          });
    
                //data regex
                const dateRegex = /^([0-2]\d|3[0-1]) (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \d{4}$/;

                // Initialize separate arrays for valid and invalid data
                const validData = [];
                // Convert the filtered data into dictionaries with keys.
                dataAsDictionaries.map((row) => {    
                  // Validate the date format
                  if (dateRegex.test(row['Date(10 Jun 2023)'])) {
                    validData.push(row);
                  } 
                });

              // Set the sheet data to the valid data
                this.setState({datalist:validData});    
            // Create columns for the Table component
            const columns = keys.map((key) => ({
            title: key,
            dataIndex: key,
            key,
            }));
             this.setState({columns:columns});
    
             this.setState({datarequested:false})
    
          }else{
            this.setState({datarequested:false})
            
            if (this.state.uploadtype==="loans"){
              message.error("loans template not valid")
  
            }
    
          }

          ///////////////////////////////////////////
          ///// EXPENSES NOW
          ///////////////////////////////
          ////////////////////////
          if (JSON.stringify(keys) === JSON.stringify(this.state.expensesheaders) && this.state.uploadtype==="expenses"){

            // Remove the first row from the data (it contains headers).
            //dataAsArray.shift();
      
            // Filter out empty rows and remove the first row (header).
            const filteredData = dataAsArray.slice(1).filter((row) => row.length > 0);
      
            // Convert the filtered data into dictionaries with keys.
            const dataAsDictionaries = filteredData.map((row) => {
              const dictionary = {};
              keys.forEach((key, index) => {
                    // Check for empty cells and replace with "NA"
                    const cellData = row[index] !== undefined && row[index] !== '' ? row[index] : 'NA';
                    dictionary[key] = cellData === 0 ? 0 : cellData; // Preserve cells with zero value
              });
              return dictionary;
            });
      
                //data regex
                const dateRegex = /^([0-2]\d|3[0-1]) (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \d{4}$/;

                // Initialize separate arrays for valid and invalid data
                const validData = [];
                // Convert the filtered data into dictionaries with keys.
                dataAsDictionaries.map((row) => {    
                  // Validate the date format
                  if (dateRegex.test(row['Date(10 Jun 2023)'])) {
                    validData.push(row);
                  } 
                });

              // Set the sheet data to the valid data
                this.setState({datalist:validData});      
              // Create columns for the Table component
              const columns = keys.map((key) => ({
              title: key,
              dataIndex: key,
              key,
              }));
               this.setState({columns:columns});
      
               this.setState({datarequested:false})
      
            }else{
              this.setState({datarequested:false})
              
              if (this.state.uploadtype==="expenses"){
                message.error("expenses template not valid")
              }
      
            }

            ///////////////////////////////
            ////////////////////////////////
            //// Incomes 
            if (JSON.stringify(keys) === JSON.stringify(this.state.incomesheaders) && this.state.uploadtype==="incomes"){

              // Remove the first row from the data (it contains headers).
              //dataAsArray.shift();
        
              // Filter out empty rows and remove the first row (header).
              const filteredData = dataAsArray.slice(1).filter((row) => row.length > 0);
        
              // Convert the filtered data into dictionaries with keys.
              const dataAsDictionaries = filteredData.map((row) => {
                const dictionary = {};
                keys.forEach((key, index) => {
                  // Check for empty cells and replace with "NA"
                  const cellData = row[index] !== undefined && row[index] !== '' ? row[index] : 'NA';
                  dictionary[key] = cellData === 0 ? 0 : cellData; // Preserve cells with zero value
                });
                return dictionary;
                });
        
                //data regex
                const dateRegex = /^([0-2]\d|3[0-1]) (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \d{4}$/;

                // Initialize separate arrays for valid and invalid data
                const validData = [];
                // Convert the filtered data into dictionaries with keys.
                dataAsDictionaries.map((row) => {    
                  // Validate the date format
                  if (dateRegex.test(row['Date(10 Jun 2023)'])) {
                    validData.push(row);
                  } 
                });

              // Set the sheet data to the valid data
                this.setState({datalist:validData});        
                // Create columns for the Table component
                const columns = keys.map((key) => ({
                title: key,
                dataIndex: key,
                key,
                }));
                 this.setState({columns:columns});
        
                 this.setState({datarequested:false})
        
              }else{
                this.setState({datarequested:false})
                
                if (this.state.uploadtype==="incomes"){
                  message.error("incomes template not valid")
                }
        
              }

              //////////////////////////////////////////
              ///////////////////////////////////////
              //// LOAN REPAYMENTS
              if (JSON.stringify(keys) === JSON.stringify(this.state.loanrepaymentsheaders) && this.state.uploadtype==="loanrepayments"){

                // Remove the first row from the data (it contains headers).
                //dataAsArray.shift();
          
                // Filter out empty rows and remove the first row (header).
                const filteredData = dataAsArray.slice(1).filter((row) => row.length > 0);
          
                // Convert the filtered data into dictionaries with keys.
                const dataAsDictionaries = filteredData.map((row) => {
                  const dictionary = {};
                  keys.forEach((key, index) => {
                    // Check for empty cells and replace with "NA"
                    const cellData = row[index] !== undefined && row[index] !== '' ? row[index] : 'NA';
                    dictionary[key] = cellData === 0 ? 0 : cellData; // Preserve cells with zero value
                  });
                  return dictionary;
                });
          
                //data regex
                const dateRegex = /^([0-2]\d|3[0-1]) (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \d{4}$/;

                // Initialize separate arrays for valid and invalid data
                const validData = [];
                // Convert the filtered data into dictionaries with keys.
                dataAsDictionaries.map((row) => {    
                  // Validate the date format
                  if (dateRegex.test(row['Date(10 Jun 2023)'])) {
                    validData.push(row);
                  } 
                });

              // Set the sheet data to the valid data
                this.setState({datalist:validData});          
                  // Create columns for the Table component
                  const columns = keys.map((key) => ({
                  title: key,
                  dataIndex: key,
                  key,
                  }));
                   this.setState({columns:columns});
          
                   this.setState({datarequested:false})
          
                }else{
                  this.setState({datarequested:false})
                  
                  if (this.state.uploadtype==="loanrepayments"){
                    message.error("loanrepayments template not valid")
                  }
          
                }


               //////////////////////////////////////////
              ///////////////////////////////////////
              //// DEPOSITS  OPENING BALANCES
              if (JSON.stringify(keys) === JSON.stringify(this.state.openingdepositsheaders) && this.state.uploadtype==="deposits"){

                // Remove the first row from the data (it contains headers).
                //dataAsArray.shift();
          
                // Filter out empty rows and remove the first row (header).
                const filteredData = dataAsArray.slice(1).filter((row) => row.length > 0);
          


                // Convert the filtered data into dictionaries with keys.
                const dataAsDictionaries = filteredData.map((row) => {
                  const dictionary = {};
                  keys.forEach((key, index) => {
                    // Check for empty cells and replace with "NA"
                    const cellData = row[index] !== undefined && row[index] !== '' ? row[index] : 'NA';
                    dictionary[key] = cellData === 0 ? 0 : cellData; // Preserve cells with zero value
                  });
                  return dictionary;
                });

                //data regex
                const dateRegex = /^([0-2]\d|3[0-1]) (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \d{4}$/;

                // Initialize separate arrays for valid and invalid data
                const validData = [];
                // Convert the filtered data into dictionaries with keys.
                dataAsDictionaries.map((row) => {    
                  // Validate the date format
                  if (dateRegex.test(row['Date(10 Jun 2023)'])) {
                    validData.push(row);
                  } 
                });

              // Set the sheet data to the valid data
                this.setState({datalist:validData});
          
                  // Create columns for the Table component
                  const columns = keys.map((key) => ({
                  title: key,
                  dataIndex: key,
                  key,
                  }));
                   this.setState({columns:columns});
          
                   this.setState({datarequested:false})
          
                }else{
                  this.setState({datarequested:false})
                  
                  if (this.state.uploadtype==="deposits"){
                    message.error("deposits template not valid")
                  }
          
                }

                //OPERATIONAL DEPOSITS
                if (JSON.stringify(keys) === JSON.stringify(this.state.operationaldepositsheaders) && this.state.uploadtype==="operationalsavings_sharesdeposits"){

                  // Remove the first row from the data (it contains headers).
                  //dataAsArray.shift();
            
                  // Filter out empty rows and remove the first row (header).
                  const filteredData = dataAsArray.slice(1).filter((row) => row.length > 0);
            
  
  
                  // Convert the filtered data into dictionaries with keys.
                  const dataAsDictionaries = filteredData.map((row) => {
                    const dictionary = {};
                    keys.forEach((key, index) => {
                      // Check for empty cells and replace with "NA"
                      const cellData = row[index] !== undefined && row[index] !== '' ? row[index] : 'NA';
                      dictionary[key] = cellData === 0 ? 0 : cellData; // Preserve cells with zero value
                    });
                    return dictionary;
                  });
  
                  //data regex
                  const dateRegex = /^([0-2]\d|3[0-1]) (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \d{4}$/;
  
                  // Initialize separate arrays for valid and invalid data
                  const validData = [];
                  // Convert the filtered data into dictionaries with keys.
                  dataAsDictionaries.map((row) => {    
                    // Validate the date format
                    if (dateRegex.test(row['Date(10 Jun 2023)'])) {
                      validData.push(row);
                    } 
                  });
  
                // Set the sheet data to the valid data
                  this.setState({datalist:validData});
            
                    // Create columns for the Table component
                    const columns = keys.map((key) => ({
                    title: key,
                    dataIndex: key,
                    key,
                    }));
                     this.setState({columns:columns});
            
                     this.setState({datarequested:false})
            
                  }else{
                    this.setState({datarequested:false})
                    
                    if (this.state.uploadtype==="operationalsavings_sharesdeposits"){
                      message.error("deposits template not valid")
                    }
            
                  }



              //MULTI TXNS
              if (JSON.stringify(keys) === JSON.stringify(this.state.depositsheaders_multitxns) && this.state.uploadtype==="multitransactions"){

                // Remove the first row from the data (it contains headers).
                //dataAsArray.shift();
          
                // Filter out empty rows and remove the first row (header).
                const filteredData = dataAsArray.slice(1).filter((row) => row.length > 0);
          


                // Convert the filtered data into dictionaries with keys.
                const dataAsDictionaries = filteredData.map((row) => {
                  const dictionary = {};
                  keys.forEach((key, index) => {
                    // Check for empty cells and replace with "NA"
                    const cellData = row[index] !== undefined && row[index] !== '' ? row[index] : 'NA';
                    dictionary[key] = cellData === 0 ? 0 : cellData; // Preserve cells with zero value
                                        
                  });
                  return dictionary;
                });

                //data regex
                const dateRegex = /^([0-2]\d|3[0-1]) (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \d{4}$/;

                // Initialize separate arrays for valid and invalid data
                const validData = [];
                // Convert the filtered data into dictionaries with keys.
                dataAsDictionaries.map((row) => {    
                  // Validate the date format
                  if (dateRegex.test(row['date'])) {
                    validData.push(row);
                  } 
                });

              // Set the sheet data to the valid data
                this.setState({datalist:validData});
          
                  // Create columns for the Table component
                  const columns = keys.map((key) => ({
                  title: key,
                  dataIndex: key,
                  key,
                  }));
                   this.setState({columns:columns});
          
                   this.setState({datarequested:false})
          
                }else{
                  this.setState({datarequested:false})
                  
                  if (this.state.uploadtype==="multitransactions"){
                    message.error("multitransactions template not valid")
                  }
          
              } 
              
              
              //SENT SMS MESSAGES

              if (JSON.stringify(keys) === JSON.stringify(this.state.smsmessagesheaders) && this.state.uploadtype==="sent_smsmessages"){

                // Remove the first row from the data (it contains headers).
                //dataAsArray.shift();
          
                // Filter out empty rows and remove the first row (header).
                const filteredData = dataAsArray.slice(1).filter((row) => row.length > 0);
          

                // Convert the filtered data into dictionaries with keys.
                const dataAsDictionaries = filteredData.map((row) => {
                  const dictionary = {};
                  keys.forEach((key, index) => {
                    // Check for empty cells and replace with "NA"
                    const cellData = row[index] !== undefined && row[index] !== '' ? row[index] : 'NA';
                    dictionary[key] = cellData === 0 ? 0 : cellData; // Preserve cells with zero value
                                        
                  });
                  return dictionary;
                });

                //data regex
                const dateRegex = /^([0-2]\d|3[0-1]) (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \d{4}$/;

                // Initialize separate arrays for valid and invalid data
                const validData = [];
                // Convert the filtered data into dictionaries with keys.
                dataAsDictionaries.map((row) => {    
                  // Validate the date format
                  if (dateRegex.test(row['Date(10 Jun 2023)'])) {
                    validData.push(row);
                  } 
                });

              // Set the sheet data to the valid data
                this.setState({datalist:validData});
          
                  // Create columns for the Table component
                  const columns = keys.map((key) => ({
                  title: key,
                  dataIndex: key,
                  key,
                  }));
                   this.setState({columns:columns});
          
                   this.setState({datarequested:false})
          
                }else{
                  this.setState({datarequested:false})
                  
                  if (this.state.uploadtype==="sent_smsmessages"){
                    message.error("sent_smsmessages template not valid")
                  }
          
              }  
              
              //system users
              if (JSON.stringify(keys) === JSON.stringify(this.state.systemusersheaders) && this.state.uploadtype==="system_users"){

                // Remove the first row from the data (it contains headers).
                //dataAsArray.shift();
          
                // Filter out empty rows and remove the first row (header).
                const filteredData = dataAsArray.slice(1).filter((row) => row.length > 0);
          

                // Convert the filtered data into dictionaries with keys.
                const dataAsDictionaries = filteredData.map((row) => {
                  const dictionary = {};
                  keys.forEach((key, index) => {
                    // Check for empty cells and replace with "NA"
                    const cellData = row[index] !== undefined && row[index] !== '' ? row[index] : 'NA';
                    dictionary[key] = cellData === 0 ? 0 : cellData; // Preserve cells with zero value
                                        
                  });
                  return dictionary;
                });

                //data regex
                const dateRegex = /^([0-2]\d|3[0-1]) (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) \d{4}$/;

                // Initialize separate arrays for valid and invalid data
                const validData = [];
                // Convert the filtered data into dictionaries with keys.
                dataAsDictionaries.map((row) => {    
                  // Validate the date format
                  if (dateRegex.test(row['Date(10 Jun 2023)'])) {
                    validData.push(row);
                  } 
                });

              // Set the sheet data to the valid data
                this.setState({datalist:dataAsDictionaries});
          
                  // Create columns for the Table component
                  const columns = keys.map((key) => ({
                  title: key,
                  dataIndex: key,
                  key,
                  }));
                   this.setState({columns:columns});
          
                   this.setState({datarequested:false})
          
                }else{
                  this.setState({datarequested:false})
                  
                  if (this.state.uploadtype==="system_users"){
                    message.error("system_users template not valid")
                  }
          
              }  

              //ug districts
              if (JSON.stringify(keys) === JSON.stringify(this.state.ugdistrictssheaders) && this.state.uploadtype==="districts"){

                // Remove the first row from the data (it contains headers).
                //dataAsArray.shift();
          
                // Filter out empty rows and remove the first row (header).
                const filteredData = dataAsArray.slice(1).filter((row) => row.length > 0);
          

                // Convert the filtered data into dictionaries with keys.
                const dataAsDictionaries = filteredData.map((row) => {
                  const dictionary = {};
                  keys.forEach((key, index) => {
                    // Check for empty cells and replace with "NA"
                    const cellData = row[index] !== undefined && row[index] !== '' ? row[index] : 'NA';
                    dictionary[key] = cellData === 0 ? 0 : cellData; // Preserve cells with zero value
                                        
                  });
                  return dictionary;
                });


                // Initialize separate arrays for valid and invalid data
                const validData = [];
                // Convert the filtered data into dictionaries with keys.
                dataAsDictionaries.map((row) => {    
                  // Validate the date format
                    validData.push(row);
                   
                });

              // Set the sheet data to the valid data
                this.setState({datalist:dataAsDictionaries});
          
                  // Create columns for the Table component
                  const columns = keys.map((key) => ({
                  title: key,
                  dataIndex: key,
                  key,
                  }));
                   this.setState({columns:columns});
          
                   this.setState({datarequested:false})
          
                }else{
                  this.setState({datarequested:false})
                  
                  if (this.state.uploadtype==="districts"){
                    message.error("districts template not valid")
                  }
          
              }  



    };

    reader.readAsArrayBuffer(file);
  };



  //submit button pressed
  handleFormSubmit=() =>{

    if(this.state.datalist.length<=0){
      message.error("Datalist cant be empty.")
    }else{

      const dataSizeBytes = JSON.stringify(this.state.datalist).length;
      // Convert bytes to gigabytes
      const dataSizeGB = dataSizeBytes / (1024 * 1024 * 1024); // 1 GB = 1024 MB = 1024*1024 KB = 1024*1024*1024 Bytes
      message.info("Datasize: "+String(dataSizeGB)+" GBs")

      this.setState({datarequested:true})

      let form_data = new FormData();
     
      form_data.append('sacco', sacco);
      form_data.append('datalist', JSON.stringify(this.state.datalist));
      form_data.append('username', username);
      form_data.append('uploadtype', this.state.uploadtype);

      axios.post(serverconfig.backendserverurl+'/customqueries/final_dataupload', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>{
      this.setState({message:res.data.message})
      console.log(res.data)
      this.setState({datarequested:false})
      this.setState({datasubmittedsuccessfully:true})
    }    
    )
    .catch(error => console.log(error))

    }

  }



  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
            <Card>
            <Result
            status="success"
            title="Successful"
            subTitle={this.state.message}
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />
            </Card>
        );

      }else{

            return (
              <div>
                <h3>Data upload section</h3>
                <h6 style={{color:'red'}}>Note: Do not change excel template headings && maintain date formats. Records with wrong formats will not be added.</h6>
                <Card>
                <Form>

                <FormItem label="Upload type">
                    <Select 
                    value={this.state.uploadtype} 
                    onChange={(val)=>{this.setState({uploadtype:val})}}
                    placeholder="Upload type" >
                       <Option value='chartofaccounts'>Chart of accounts upload</Option>

                    </Select>
                </FormItem>


                <FormItem label="Excel data template">
                   <Input type="file" accept=".xlsx" onChange={this.handleFileChange} />
                </FormItem>
 
              </Form>
                </Card>


                <br></br>
                {this.state.datalist.length > 0 && (
                  <div>
                    <div style={{display:'flex',flexDirection:'row'}}>
                    <h2>Processed Data</h2>
                    &nbsp;&nbsp;
                    <Button  
                    type='primary'
                     onClick={()=>{
                      this.handleFormSubmit()

                    }}>Upload data</Button>

                    </div>
                    <div style={{overflowX:'auto'}}>
                    <Table 
                    dataSource={this.state.datalist}
                    columns={this.state.columns}
                    scroll={{ x: 1000 }}
                    bordered
                    pagination={{showQuickJumper:true,showSizeChanger:true }}
                    size='small'

                     /> {/* Display the data in an Ant Design Table */}
                    </div>

                  </div>
                )}

          </div>
        );

      }

    }
    
  }

}

export default DataUploadForm;



