import React from 'react';
import MemberForm from '../components/MemberForm'
import ActivateMemberForm from '../components/ActivateMemberForm'

import axios from 'axios'
import { Table, Input, Button,Form,DatePicker,Collapse,Typography,Space,Divider,Popover,Popconfirm,message,Descriptions,Modal,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,PrinterOutlined,DownloadOutlined,FundViewOutlined,EditOutlined,WalletFilled,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import ReactToPrint from 'react-to-print';
import * as reactstrp from 'reactstrap';
import moment from 'moment';

import {
  Col,
  Row,
} from 'reactstrap';
import ReactExport from "@ibrahimrahmani/react-export-excel";
import '../tablestyle.css'; // Create this CSS file for custom styles

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Title } = Typography;
const dateFormat = 'DD/MM/YYYY';
const FormItem=Form.Item;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function callback(key) {
  console.log(key);
}

var token= ''
var sacco= ''
var bizuserid= ''

class ReportToPrint extends React.Component {

  state = {
    companyprofile:{},
  };

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
       bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
    })

  }
  //calculate total savings
  calculateTotalSavings=()=>{
    var Total=0
    this.props.members.map(
      (item)=>{
        Total+=Number(item.ordinary_savings)
      })
    return Total;

  }

  calculateTotalShares=()=>{
    var Total=0
    this.props.members.map(
      (item)=>{
        Total+=Number(item.share_amount)
      })
    return Total;

  }

  calculateTotalSupersave=()=>{
    var Total=0
    this.props.members.map(
      (item)=>{
        Total+=Number(item.super_savings)
      })
    return Total;

  }

  calculateTotalTeebaGoolo=()=>{
    var Total=0
    this.props.members.map(
      (item)=>{
        Total+=Number(item.teeba_goolo)
      })
    return Total;

  }

  calculateTotalDef=()=>{
    var Total=0
    this.props.members.map(
      (item)=>{
        Total+=Number(item.def_deposit)
      })
    return Total;

  }


  calculateTotalsavingssec=()=>{
    var Total=0
    this.props.members.map(
      (item)=>{
        Total+=Number(item.savings_loansecurity)
      })
    return Total;

  }

  calculateTotalsupersavessec=()=>{
    var Total=0
    this.props.members.map(
      (item)=>{
        Total+=Number(item.supersave_loansecurity)
      })
    return Total;
  }

  calculateTotaltbsec=()=>{
    var Total=0
    this.props.members.map(
      (item)=>{
        Total+=Number(item.teebagoolo_loansecurity)
      })
    return Total;
  }

  calculateTotalsharessec=()=>{
    var Total=0
    this.props.members.map(
      (item)=>{
        Total+=Number(item.shares_loansecurity)
      })
    return Total;
  }


  calculateTotal_loanbalance=()=>{
    var Total=0
    this.props.members.map(
      (item)=>{
        Total+=Number(item.loan_account)
      })
    return Total;
  }

  calculateTotal_loaninterestbalance=()=>{
    var Total=0
    this.props.members.map(
      (item)=>{
        Total+=Number(item.loan_interest)
      })
    return Total;
  }

  //neww herees
  calculateTotal_saving_interestbalance=()=>{
    var Total=0
    this.props.members.map(
      (item)=>{
        Total+=Number(item.savings_interest)
      })
    return Total;
  }

  calculateTotal_teebagoolo_interestbalance=()=>{
    var Total=0
    this.props.members.map(
      (item)=>{
        Total+=Number(item.teebagoolo_interest)
      })
    return Total;
  }


  calculateTotal_supersave_interestbalance=()=>{
    var Total=0
    this.props.members.map(
      (item)=>{
        Total+=Number(item.supersavings_interest)
      })
    return Total;
  }

  render() {
    return (
      <div style={{padding:20}}>
        <style>
        {`@media print {
          @page { size: landscape; }
        }`}
        </style>
         <Row> 
              <Col xs="2" sm="2" lg="2">
              <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
            
                </Col>
                <Col xs="6" sm="6" lg="6">
                <h3>{this.state.companyprofile.sacco_name}</h3>
                <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                {
                this.state.companyprofile.RegisteredPhoneNumber2==""?
                "":","+this.state.companyprofile.RegisteredPhoneNumber2
                }
                {
                this.state.companyprofile.RegisteredPhoneNumber3==""?
                "":","+this.state.companyprofile.RegisteredPhoneNumber3
                }
                </h6>               
                <h5>Email: {this.state.companyprofile.company_email}</h5>
                <h5>Wesite: {this.state.companyprofile.website}</h5>
                <h5>{this.state.companyprofile.box_number}</h5>
              
                </Col>

     </Row>

           <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>MEMBER BALANCES REPORT</h3>

           <reactstrp.Table bordered>
                      <thead>
                        <tr>
                        <th>No.</th>

                          <th>Account Number</th>
                          <th>Account Name</th>
                          <th>Account Type</th>
                          <th>Savings</th>
                          <th>Shares</th>
                          <th>Supersave</th>
                          <th>TeebaGoolo</th>
                          <th>Def</th>

                          <th>Savings interest</th>
                          <th>Supersave interest</th>
                          <th>Teebagoolo interest</th>


                          <th>Savings Loan Security</th>
                          <th>SuperSave Loan Security</th>
                          <th>TeebaGoolo Loan Security</th>
                          <th>Shares Loan Security</th>

                          <th>Loan</th>
                          <th>Loan interest</th>

                        </tr>
                      </thead>
                      <tbody>
                      {this.props.members.map(
                        (item)=>(
                          <tr>
                                                      <td>{item.item_no}</td>

                          <td>{item.AccountNumber}</td>
                          <td>{item.FullName}</td>
                          <td>{item.accounttypename}</td>
                          <td className="align-right">{<CurrencyFormat value={item.ordinary_savings.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.share_amount.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.super_savings.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.teeba_goolo.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.def_deposit.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        
                          <td className="align-right">{<CurrencyFormat value={item.savings_interest.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.supersavings_interest.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.teebagoolo_interest.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>


                          <td className="align-right">{<CurrencyFormat value={item.savings_loansecurity.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.supersave_loansecurity.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.teebagoolo_loansecurity.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.shares_loansecurity.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>

                          <td className="align-right">{<CurrencyFormat value={item.loan_account.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.loan_interest.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td></td>
                        <td></td>
                        <td></td>

                        <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalsavings.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalshares.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalsupersave.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalteebagoolo.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totaldef.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>

                        <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotal_saving_interestbalance().toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotal_supersave_interestbalance().toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotal_teebagoolo_interestbalance().toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>


                        <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalsavingssec().toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalsupersavessec().toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotaltbsec().toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalsharessec().toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>

                        <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotal_loanbalance().toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotal_loaninterestbalance().toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>

                        </tr>
                    </tbody>
                </reactstrp.Table> 
      </div>
    );
  }
}


class MemberSavingsReport extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    members: [],
    viewpaymentmodal:false,
    expensedetails:{},
    companyprofile:{},
    userprofile:{},
    datarequested:true,
    memberactivationformvisible:false,
    selectedmemberid:'',
    date:moment().format(dateFormat).toString()

  };

  componentDidMount(){
      if(localStorage.getItem("sacco")){
        sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
        bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
        sacco= ''
        bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
        token= localStorage.getItem("token")
    }else{
        token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

    /*
    axios.get(serverconfig.backendserverurl+`/customqueries/get_members_withaccounts/${sacco}`)
    .then(res => {
        this.setState({
          members:JSON.parse(res.data.report)
        })

        this.setState({datarequested:false})

    }) */


    let form_data1 = new FormData();
    form_data1.append('sacco', sacco);
    form_data1.append('date', this.state.date);
    axios.post(serverconfig.backendserverurl+`/customqueries/getMembersummaryreport_byperiod`, form_data1)
    .then(res => {
      this.setState({members:JSON.parse(res.data.members_report)})
      this.setState({datarequested:false})

    })

}

    //calculate total savings
    calculateTotalSavings=()=>{
      var Total=0
      this.state.members.map(
        (item)=>{
          Total+=Number(item.ordinary_savings)
        })
      return Total;

    }

    calculateTotalShares=()=>{
      var Total=0
      this.state.members.map(
        (item)=>{
          Total+=Number(item.share_amount)
        })
      return Total;

    }

    calculateTotalSupersave=()=>{
      var Total=0
      this.state.members.map(
        (item)=>{
          Total+=Number(item.super_savings)
        })
      return Total;

    }

    calculateTotalTeebaGoolo=()=>{
      var Total=0
      this.state.members.map(
        (item)=>{
          Total+=Number(item.teeba_goolo)
        })
      return Total;

    }

    calculateTotalDef=()=>{
      var Total=0
      this.state.members.map(
        (item)=>{
          Total+=Number(item.def_deposit)
        })
      return Total;

    }


    calculateTotalsavingssec=()=>{
      var Total=0
      this.state.members.map(
        (item)=>{
          Total+=Number(item.savings_loansecurity)
        })
      return Total;

    }

    calculateTotalsupersavessec=()=>{
      var Total=0
      this.state.members.map(
        (item)=>{
          Total+=Number(item.supersave_loansecurity)
        })
      return Total;
    }

    calculateTotaltbsec=()=>{
      var Total=0
      this.state.members.map(
        (item)=>{
          Total+=Number(item.teebagoolo_loansecurity)
        })
      return Total;
    }

    calculateTotalsharessec=()=>{
      var Total=0
      this.state.members.map(
        (item)=>{
          Total+=Number(item.shares_loansecurity)
        })
      return Total;
    }


    calculateTotal_loanbalance=()=>{
      var Total=0
      this.state.members.map(
        (item)=>{
          Total+=Number(item.loan_account)
        })
      return Total;
    }

    calculateTotal_loaninterestbalance=()=>{
      var Total=0
      this.state.members.map(
        (item)=>{
          Total+=Number(item.loan_interest)
        })
      return Total;
    }

    //neww herees
    calculateTotal_saving_interestbalance=()=>{
      var Total=0
      this.state.members.map(
        (item)=>{
          Total+=Number(item.savings_interest)
        })
      return Total;
    }

    calculateTotal_teebagoolo_interestbalance=()=>{
      var Total=0
      this.state.members.map(
        (item)=>{
          Total+=Number(item.teebagoolo_interest)
        })
      return Total;
    }


    calculateTotal_supersave_interestbalance=()=>{
      var Total=0
      this.state.members.map(
        (item)=>{
          Total+=Number(item.supersavings_interest)
        })
      return Total;
    }


getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});


  render() {
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
            <div style={{display: "none"}}>
               &nbsp;&nbsp;
              <ReportToPrint
              totalsavings={this.calculateTotalSavings()} 
              members={this.state.members}
              totaldef={this.calculateTotalDef()}
              totalsupersave={this.calculateTotalSupersave()}
              totalteebagoolo={this.calculateTotalTeebaGoolo()}
              totalshares={this.calculateTotalShares()}
              ref={el => (this.componentRef = el)} /> 
             </div>

            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header={<h4 style={{fontWeight:'bolder'}}> MEMBER BALANCES REPORT BY {this.state.date}</h4>} key="1">
           
            <div style={{display:'flex',justifyContent:'flex-start',alignSelf:'flex-start',alignItems:'flex-start'}}>
              <ReactToPrint
                trigger={() => 
                <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
                Print
                </Button>
                }
                content={() => this.componentRef}
                />
                &nbsp;&nbsp;

          <ExcelFile filename={"Pisys - Member summary report. "} element={<Button type='primary' icon={<DownloadOutlined/>}>Download (Excel)</Button>}>
                <ExcelSheet data={this.state.members} name="Member summary report">
                    <ExcelColumn label="No" value="item_no"/>

                    <ExcelColumn label="Acc No" value="AccountNumber"/>
                    <ExcelColumn label="Acc Name" value="FullName"/>
                    <ExcelColumn label="Account type" value="accounttypename"/>

                    <ExcelColumn label="Ordinary savings" value="ordinary_savings"/>
                    <ExcelColumn label="Share amount" value="share_amount"/>
                    <ExcelColumn label="Supersave" value="super_savings"/>
                    <ExcelColumn label="Teeba goolo" value="teeba_goolo"/>
                    <ExcelColumn label="Defered" value="def_deposit"/>

                    <ExcelColumn label="Savings interest" value="savings_interest"/>
                    <ExcelColumn label="Supersave interest" value="supersavings_interest"/>
                    <ExcelColumn label="Teebagoolo interest" value="teebagoolo_interest"/>


                    <ExcelColumn label="Savings loan security" value="savings_loansecurity"/>
                    <ExcelColumn label="Supersave loan security" value="supersave_loansecurity"/>
                    <ExcelColumn label="Teeba goolo loan security" value="teebagoolo_loansecurity"/>
                    <ExcelColumn label="Shares loan security" value="shares_loansecurity"/>
                    <ExcelColumn label="Loan balance" value="loan_account"/>
                    <ExcelColumn label="Loan interest balance" value="loan_interest"/>

                </ExcelSheet>
            </ExcelFile>

            &nbsp;&nbsp;&nbsp;

          <Form  layout="inline" >

          <FormItem label="Summary by (Date)">
              <DatePicker onChange={(date, dateString) =>{
              this.setState({ date: dateString});
            }} format={dateFormat} />
          </FormItem>


          <FormItem>
          <Button onClick={()=>{

          if(this.state.date==='' ){
          alert("Date one missing")
          }else{
          this.setState({datarequested:true})

          let form_data1 = new FormData();
          form_data1.append('sacco', sacco);
          form_data1.append('date', this.state.date);
          axios.post(serverconfig.backendserverurl+`/customqueries/getMembersummaryreport_byperiod`, form_data1)
          .then(res => {
            this.setState({members:JSON.parse(res.data.members_report)})
            this.setState({datarequested:false})

          })

          }

          }} type="primary" htmlType="button">Search</Button>
          </FormItem> 
          </Form>


              </div>
              <br />


           <div style={{overflowX:'auto'}}>

           <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>Account Number</th>
                          <th>Account Name</th>
                          <th>Account Type</th>
                          <th>Savings</th>
                          <th>Shares</th>
                          <th>Supersave</th>
                          <th>TeebaGoolo</th>
                          <th>Def</th>

                          <th>Savings interest</th>
                          <th>Supersave interest</th>
                          <th>Teebagoolo interest</th>


                          <th>Savings Loan Security</th>
                          <th>SuperSave Loan Security</th>
                          <th>TeebaGoolo Loan Security</th>
                          <th>Shares Loan Security</th>
                          <th>Loan</th>
                          <th>Loan interest</th>

                        </tr>
                      </thead>
                      <tbody>
                      {this.state.members.map(
                        (item)=>(
                          <tr>
                                                      <td>{item.item_no}</td>

                          <td>{item.AccountNumber}</td>
                          <td>{item.FullName}</td>
                          <td>{item.accounttypename}</td>
                          <td className="align-right">{<CurrencyFormat value={item.ordinary_savings.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.share_amount.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.super_savings.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.teeba_goolo.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.def_deposit.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        
                          <td className="align-right">{<CurrencyFormat value={item.savings_interest.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.supersavings_interest.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.teebagoolo_interest.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>


                          <td className="align-right">{<CurrencyFormat value={item.savings_loansecurity.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.supersave_loansecurity.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.teebagoolo_loansecurity.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.shares_loansecurity.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>

                          <td className="align-right">{<CurrencyFormat value={item.loan_account.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.loan_interest.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>

                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td></td>
                        <td></td>
                        <td></td>

                        <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalSavings().toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalShares().toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalSupersave().toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalTeebaGoolo().toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalDef().toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>

                        <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotal_saving_interestbalance().toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotal_supersave_interestbalance().toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotal_teebagoolo_interestbalance().toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>

                        <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalsavingssec().toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalsupersavessec().toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotaltbsec().toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalsharessec().toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>

                        <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotal_loanbalance().toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right" style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotal_loaninterestbalance().toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>

                        </tr>

                    </tbody>
                </reactstrp.Table>  
           </div>
            
            </Panel>
            </Collapse>
        </div>
      )

    }

  }
}

export default MemberSavingsReport; 
