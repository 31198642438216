import React,{ useRef } from 'react';
import StaffForm from '../components/StaffForm'
import axios from 'axios'
import { Tooltip,Popover,Result,Table,Popconfirm,Input, Button,Collapse,Card,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { DeleteOutlined,SearchOutlined,CameraOutlined,PlusOutlined,EditOutlined,PlusSquareFilled,SaveOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';
import { ToWords } from 'to-words';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { RangePicker } = DatePicker;

const toWords = new ToWords();

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});


var token= ''
var sacco= ''
var username=''
var bizuserid= ''

class ReceiptToPrint extends React.Component {

  state = {
    companyprofile:{}
  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

 

  }

  render() {
    return (
      <div style={{padding:20}}>
            
          <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
          <h3 style={{color:'black'}}>{this.state.companyprofile.sacco_name}-{this.state.companyprofile.branchname}</h3>
          <h6 style={{color:'black'}}>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
          {
          this.state.companyprofile.RegisteredPhoneNumber2==""?
          "":","+this.state.companyprofile.RegisteredPhoneNumber2
          }
          {
          this.state.companyprofile.RegisteredPhoneNumber3==""?
          "":","+this.state.companyprofile.RegisteredPhoneNumber3
          }
          </h6>               
          <h5 style={{color:'black'}}>Email: {this.state.companyprofile.company_email}</h5>
          <h5 style={{color:'black'}}>Wesite: {this.state.companyprofile.website}</h5>
          <h5 style={{color:'black'}}>{this.state.companyprofile.box_number}</h5>
           <h4 style={{color:'black'}}>SLIP NO: {this.props.voucher_number}</h4>
           <h4 style={{color:'black'}}>DATE: {this.props.date}</h4>
           <h5 style={{color:'black'}}>Country: {this.state.companyprofile.country} || Currency: {this.state.companyprofile.currency}</h5>
           <h3 style={{strong:true,color:'black',fontWeight:'bolder'}}>DEPOSIT RECEIPT</h3>

           <table cellpadding="2" cellSpacing="2" border="1">
            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>ACC NUMBER</td>
              <td style={{fontWeight:'bold'}}>{this.props.account.AccountNumber}</td>
            </tr>

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>ACC NAME</td>
              <td style={{fontWeight:'bold'}}>{this.props.account.FullName}</td>
            </tr>  

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>DEPOSITED AMOUNT</td>
              <td style={{fontWeight:'bold'}}><CurrencyFormat  value={this.props.totalamount} displayType={'text'} thousandSeparator={true} /></td>
            </tr> 

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>In Words</td>
              <td style={{fontWeight:'bold'}}>{ toWords.convert(this.props.totalamount)}</td>
            </tr> 

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>PAID IN BY</td>
              <td style={{fontWeight:'bold'}}>{this.props.paidinby}</td>
            </tr> 

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>SLIP NUMBER</td>
              <td style={{fontWeight:'bold'}}>{this.props.voucher_number}</td>
            </tr> 

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>CONTACT</td>
              <td style={{fontWeight:'bold'}}>{this.props.transactorcontact}</td>
            </tr> 

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>TELLER</td>
              <td style={{fontWeight:'bold'}}>{username}</td>
            </tr> 

           </table>

    </div>
    );
  }
}


class SuperSaveCreateInterest extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    selectedaccount:'',
    selectedaccountobject:{},
    members:[],
    datarequested:true,
    totalamount:0,
    voucher_number:'',
    paidinby:'',
    transactorcontact:'',
    previoustransactions:[],
    datasubmittedsuccessfully:false,
    companyprofile:{},
    accountchange:false,
    userprofile:{},
    transaction_details:'',
    interestrate:0,
    supersave_profile:{},
    receivers_list:[],
    dateone:'',
    datetwo:'',
    supersaveaccounts: [],
    past_days:0,
    maximum_interest:0,
    minimum_interets:0


  }

  callback(key) {
    console.log(key);
  }


    //check empty objecty
    isEmptyObject=(obj)=>{
      for ( var name in obj ) {
        return false;
      }
      return true;
    }
  

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    let form_data1 = new FormData();
    form_data1.append('userid', bizuserid);
    
    //get the user profile here
    axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data1,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          userprofile:res.data
        })
    })

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })


    axios.get(serverconfig.backendserverurl+`/api/supersaveprofile/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          supersaveaccounts:res.data
          
        })

        var firstobj=res.data[0]

        if (this.isEmptyObject(firstobj)===false){
          this.setState({maximum_interest:firstobj.interest_limit})
          this.setState({minimum_interets:firstobj.mandatory_interest})
        }

        this.setState({datarequested:false})
    })

  }

  
  ////////////////////////////////////
  // PRODUCT SEARCH SELECT METHODS
   onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }

  //handle client change
  handleselectedaccountChange= (e) =>{
    this.setState({accountchange:true})
    this.setState({ selectedaccount: e});
    //make query for last transactions
    axios.get(`${serverconfig.backendserverurl}/api/members/${e}`)
      .then(res => {  
          this.setState({selectedaccountobject: res.data})
          this.setState({accountchange:false})
      })

      //get teebagoolo and supersave profiles
      axios.get(`${serverconfig.backendserverurl}/api/supersaveprofile/?member_account=${e}`)
      .then(res => {  
          this.setState({supersave_profile: res.data[0]})
      })
  }

  handlepaidinbyChange= (e) => this.setState({ paidinby: e.target.value});
  handletransactorcontactChange= (e) => this.setState({ transactorcontact: e});


 //check empty objecty
 isEmptyObject=(obj)=>{
  for ( var name in obj ) {
    return false;
  }
  return true;
}


  //submit the deposit now
  handleFormSubmit=(event) =>{

      if (this.state.receivers_list.length===0){
        message.error("receivers list cant be empty")
      }else if(Number(this.state.interestrate)<Number(this.state.minimum_interets)){
        message.error("interest cant be less than: "+String(this.state.minimum_interets))

      }else if(Number(this.state.interestrate)>Number(this.state.maximum_interest)){
        message.error("interest cant be greater than: "+String(this.state.maximum_interest))

      }
      
      else{

        this.setState({datarequested:true})
        let form_data = new FormData();
        form_data.append('date', this.state.date);
        form_data.append('interestrate', this.state.interestrate);
        form_data.append('sacco', sacco);
        form_data.append('account', this.state.selectedaccountobject.id);
        form_data.append('username', username);
        form_data.append('userid', bizuserid);
        form_data.append('transaction_details', this.state.transaction_details);
        form_data.append('voucher_number', this.state.voucher_number);
        form_data.append('receivers_list',JSON.stringify(this.state.receivers_list) );
  
        axios.post(serverconfig.backendserverurl+'/customqueries/createSuperSaveInterest', form_data, {
          headers: {
            'content-type': 'multipart/form-data'
          }
          })
        .then(res => {
          this.setState({datarequested:false})
          message.info(res.data.message)
          if (res.data.message==="success"){
            this.setState({datasubmittedsuccessfully:true})
          }
        })
        .catch(error => console.log(error))

      }
    
  }

 //calculate interest here
  calculate_interest=(rate)=>{
    //var rate=this.state.interestrate
    console.log("rate: "+this.state.interestrate)

    var dateMomentObjectone = moment(this.state.dateone, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
    var dateObjectone = dateMomentObjectone.toDate();

    var dateMomentObjecttwo = moment(this.state.datetwo, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
    var dateObjecttwo =dateMomentObjecttwo.toDate()

    var date1 = dateObjectone;
    var date2 = dateObjecttwo;

    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    console.log(Difference_In_Days)
    this.setState({past_days:Difference_In_Days})

    var datalist=[]

    //llop through accounts
    this.state.supersaveaccounts.map((item)=>{
      console.log(item.supersaver_balance)
      console.log(item.minimumbalance_forinterest)

      if (Number(item.supersaver_balance)>=Number(item.minimumbalance_forinterest)){
        console.log("pass")
        var interest_pay=0

        interest_pay=((rate/100)/365)*Number(item.supersaver_balance)*Number(Difference_In_Days)
      
        var new_item={
          "key":item.id,
          "member_account":item.member_account,
          "FullName":item.FullName,
          "AccountNumber":item.AccountNumber,
          "interest_pay":interest_pay.toFixed(2),
          "accountbalance":Number(item.supersaver_balance).toFixed(2)
        }

        datalist.push(new_item)
      }else{
        console.log("no pass")
      }

    })

    console.log(datalist)

    this.setState({
      receivers_list:datalist
    })

  }


  //calculatetotals
calculate_listToyal=()=>{
  var total=0;

  this.state.receivers_list.map((item)=>{
    total+=Number(item.accountbalance)
  })

  return total;
}

calculate_listpay=()=>{
  var total=0;
  this.state.receivers_list.map((item)=>{
    total+=Number(item.interest_pay)
  })

  return total;
}


  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>
            <div style={{display: "none"}}>
            &nbsp;&nbsp;
            <ReceiptToPrint
            account={this.state.selectedaccountobject} 
            totalamount={this.state.interestrate} 
            voucher_number={this.state.voucher_number} 
            paidinby={this.state.paidinby} 
            transactorcontact={this.state.transactorcontact}
            date={this.state.date}
            ref={el => (this.componentRef = el)} /> 
            </div>

            <Row >
                <Col xs="12" sm="6" lg="6">
                  <Card>
                  <Result
                  status="success"
                  title="Successfully Supersave interest awarding "
                  extra={[
                    <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
                  ]}
                  />
                </Card>
                </Col>
            </Row>
          </div>

        )

      }else{
        return(
          <div>
            <div>
  
              <Row >
                  <Col xs="12" sm="6" lg="6">
                    <Card>
                    
                    <h3 style={{fontWeight:'bold',alignSelf:'center',color:'#154360'}}>SUPERSAVE INTEREST AWARD FORM</h3>
                  <Form
                        onFinish={(event) => this.handleFormSubmit(event)}
                    >

                <FormItem label="Interest award period (From to)">
                      <RangePicker defaultValue={moment()} onChange={(date, dateString) =>{
                          this.setState({ dateone: dateString[0]});
                          this.setState({ datetwo: dateString[1]});
                        }} format={dateFormat} />
                  </FormItem>
                

                 <FormItem label={<h6 style={{fontWeight:'bold'}}>Interest Rate </h6>}
                  name="interestrate"
                  rules={[
                    {
                      required: true,
                      message: 'Please input interest rate',
                    },
                  ]}
                  >
                      <Input name="interestrate"   placeholder="0" value={this.state.interestrate}
                       onChange={(e) =>{
                          this.setState({ interestrate: e.target.value})
                          this.calculate_interest(e.target.value)

                        }
                      } />
                  </FormItem>

                  <FormItem label={<h4 style={{fontWeight:'bold'}}>Transaction details.</h4>} >
                
                  <TextArea
                      placeholder="Transaction Details"
                      autoSize={{ minRows: 2, maxRows: 8 }}
                      value={this.state.transaction_details}
                      onChange={(val)=>{this.setState({transaction_details:val.target.value})}}
                    />
                  </FormItem>

                  <h4 style={{fontWeight:'bold'}}>You are about to  award supersave interest at rate of {this.state.interestrate} .</h4>

                  <br></br>
                  <div style={{display:'flex',flexDirection:'row',margin:6}}>

                  <FormItem>
                    <Button  type="primary" htmlType="submit">Award interest now</Button>
                  </FormItem>



                  </div>

                  </Form>
  
                  </Card>
                  </Col>

                  <Col xs="12" sm="6" lg="6">
                    <Card>
                    <h3 style={{fontWeight:'bold',color:'#154360'}}>List of receivers and totals</h3>
                    <h4 style={{fontWeight:'bold',color:'#154360'}}>past days: {this.state.past_days}</h4>

                      {
                        this.state.receivers_list.length>0?
                          <reactstrp.Table bordered>
                          <thead>
                            <tr>
                              <th>Account name</th>
                              <th>Account number</th>
                              <th>Account balance</th>
                              <th>Interest pay</th>

                            </tr>
                          </thead>
                          <tbody>
                          {this.state.receivers_list.map(
                            (item)=>(
                              <tr key={item.key}>
                              <td>{item.FullName}</td>
                              <td>{item.AccountNumber}</td>
                              <td>{<CurrencyFormat value={item.accountbalance} displayType={'text'} thousandSeparator={true}/>}</td>
                              <td>{<CurrencyFormat value={item.interest_pay} displayType={'text'} thousandSeparator={true}/>}</td>
                              </tr>
                            ))}
                          
                            <tr>
                            <td style={{fontWeight:'bolder'}}>Total</td>
                            <td></td>
                            <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculate_listToyal().toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculate_listpay().toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                            </tr>
    
                        </tbody>
                        </reactstrp.Table> 
                        :
                        null
                      }

                    </Card>
                    
                  </Col>
               </Row>
            </div>
      
       </div>              
                      
       )
      }
    
    }
   
  }
}

export default SuperSaveCreateInterest; 
