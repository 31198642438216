import axios from 'axios'
import * as actionTypes from './actionTypes'
import * as serverconfig from '../../views/serverconn'
import  { encrypt , decrypt } from 'react-crypt-gsm';
var CryptoJS = require("crypto-js");

export const authStart=() =>{
    return {
        type: actionTypes.AUTH_START
    }
}

export const authSuccess = token =>{
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token
    }
}

export const authFail = error => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
}

export const logout = () => {
    var bizuserid=''
    if(localStorage.getItem("bizuserid")){
        bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
     }else{
        bizuserid= ''
     }

    let form_data = new FormData();
    form_data.append('userid',bizuserid);
    axios.post(serverconfig.backendserverurl+'/customqueries/systemlogout', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        }).then(res => {
        
        if (res.data.message==="success"){

            localStorage.removeItem('user')
            localStorage.removeItem('expirationDate')
            localStorage.removeItem('username')
            localStorage.removeItem('token')
            localStorage.removeItem('bizuserid')
            localStorage.removeItem('sacco')

            //;ogo out
    
        }    

    })


    return {
        type: actionTypes.AUTH_LOGOUT
    }

}

export const checkAuthTimeout = expirationTime => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000)
    }
}


export const authLogin = (username, password) => {
    return dispatch => {
        dispatch(authStart());
        axios.post(serverconfig.backendserverurl+'/rest-auth/',{
            username: username,
            password: password
        }).then(res => {

            if(res.data.saccodashboard===true){

                const token = res.data.token;
                const expirationDate=new Date(new Date().getTime()+ 3600 * 1000)

                localStorage.setItem('bizuserid', CryptoJS.AES.encrypt(String(res.data.id), 'my-secret-key@1234').toString());
                localStorage.setItem('token',token);
                localStorage.setItem('expirationDate',expirationDate);
                localStorage.setItem('username',CryptoJS.AES.encrypt(username, 'my-secret-key@123').toString());
                localStorage.setItem('sacco',CryptoJS.AES.encrypt(String(res.data.saccoid), 'my-secret-key@12345').toString());

                dispatch(authSuccess(token));
                //dispatch(checkAuthTimeout(86400));
              
            } else{
                console.log("you cant login with this account")
            }

        }).catch(err => {
            dispatch(authFail(err))
        })
    }
}


export const authSignUp = (username,email, password1,password2) => {
    return dispatch => {
        dispatch(authStart());
        axios.post(serverconfig.backendserverurl+'/rest-auth/registration/',{
            username: username,
            email: email,
            password1: password1,
            password2: password2
        }).then(res => {
            console.log("User created successfully")
        }).catch(err => {
            dispatch(authFail(err))
        })
    }
}


export const authCheckState=() =>{
    return dispatch => {
        const token =localStorage.getItem('token');
        if (token === undefined){
            dispatch(logout());
        }else{
            const expirationDate= new Date(localStorage.getItem('expirationDate'));
            if (expirationDate <= new Date()){
                dispatch(logout());
            }else{
                dispatch(authSuccess(token));
                dispatch(checkAuthTimeout(( expirationDate.getTime() - new Date().getTime())/1000));
            }
        }
    }
}