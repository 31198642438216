import React from 'react';
import axios from 'axios';
import { Card, Button,Avatar,Form, Input,Select,DatePicker,message,Spin} from 'antd';
import StaffForm from '../components/StaffForm'
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import { Drawer,Divider } from 'antd';
import StaffUserSignUpForm from '../components/StaffUserSignUp'
import * as serverconfig from '../serverconn'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import {
  Col,
  Row,
} from 'reactstrap';
var CryptoJS = require("crypto-js");
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';


var token= ''
var sacco= ''

class StaffDetail extends React.Component{

    state={
        staff: {},
        visible: false,
        childrenDrawer: false,
        //////form data here
        staffroles:[],
        idtypes:[],
        sur_name:'' ,  
        middle_name:'',
        given_name:'',
        nationality:'',
        ID_type:'',
        ID_number:'',
        date_Of_Birth:'',
        Email_Address:'',
        P_O_Box:'',
        Area_Of_Residence:'',
        District:'',
        RegisteredPhoneNumber1:'',
        RegisteredPhoneNumber2:'',
        next_of_kin:'',
        Next_Of_Kin_Physical_Address:'',
        relationship:'',
        Photo:null,
        Date_Of_Joining:'',
        staff_role:'',
        date_of_appointment:'',
        status:'',
        staff_number:'',
        reference_one:'',
        Referee1PhoneNumber:'',
        datarequested:true,
        gender:'',

    }

    showDrawer = () => {
        this.setState({
          visible: true,
        });
      };
    
      onClose = () => {
        this.setState({
          visible: false,
        });
      };
    
      showChildrenDrawer = () => {
        this.setState({
          childrenDrawer: true,
        });
      };
    
      onChildrenDrawerClose = () => {
        this.setState({
          childrenDrawer: false,
        });
      };



      handlesur_nameChange= (e) => this.setState({ sur_name: e.target.value});
      handlemiddle_nameChange= (e) => this.setState({ middle_name: e.target.value});
      handlegiven_nameChange= (e) => this.setState({ given_name: e.target.value});
      handlenationalityChange= (e) => this.setState({ nationality: e.target.value});
      handleID_typeChange= (e) => this.setState({ ID_type: e});
    
      handleID_numberChange= (e) => this.setState({ ID_number: e.target.value});
      handledate_Of_BirthChange= (date, dateString) => this.setState({ date_Of_Birth: dateString});
    
      handleEmail_AddressChange= (e) => this.setState({ Email_Address: e.target.value});
      handleP_O_BoxChange= (e) => this.setState({ P_O_Box: e.target.value});
      handleArea_Of_ResidenceChange= (e) => this.setState({ Area_Of_Residence: e.target.value});
      handleDistrictChange= (e) => this.setState({ District: e.target.value});
      handleRegisteredPhoneNumber1Change= (e) => this.setState({ RegisteredPhoneNumber1: e});
    
      handleRegisteredPhoneNumber2Change= (e) => this.setState({ RegisteredPhoneNumber2: e});
      handlenext_of_kinChange= (e) => this.setState({ next_of_kin: e.target.value});
      handleNext_Of_Kin_Physical_AddressChange= (e) => this.setState({ Next_Of_Kin_Physical_Address: e.target.value});
      handlerelationshipChange= (e) => this.setState({ relationship: e.target.value});
      handlePhotoChange= (e) => this.setState({ Photo: e.target.files[0]});
      handleDate_Of_JoiningChange= (date, dateString) => this.setState({ Date_Of_Joining: dateString});
      handlestaff_roleChange= (e) => this.setState({ staff_role: e});
      handledate_of_appointmentChange= (date, dateString) => this.setState({ date_of_appointment: dateString});
    
      handlestatusChange= (e) => this.setState({ status: e});
      handlestaff_numberChange= (e) => this.setState({ staff_number: e.target.value});
      handlereference_oneChange= (e) => this.setState({ reference_one: e.target.value});
      handleReferee1PhoneNumberChange= (e) => this.setState({ Referee1PhoneNumber: e});
      handlegenderChange= (e) => this.setState({ gender: e});

    //submit button pressed
    handleFormSubmit=() =>{
      this.setState({datarequested:true})
      const staffID=this.props.match.params.staffID;

      let form_data = new FormData();
      form_data.append('sur_name', this.state.sur_name);
      form_data.append('middle_name', this.state.middle_name);
      form_data.append('given_name', this.state.given_name);
      form_data.append('nationality', this.state.nationality);
      form_data.append('ID_type', this.state.ID_type);
      form_data.append('ID_number', this.state.ID_number);
      form_data.append('date_Of_Birth', this.state.date_Of_Birth);
      form_data.append('gender', this.state.gender);

      form_data.append('Email_Address', this.state.Email_Address);

      form_data.append('P_O_Box', this.state.P_O_Box);
      form_data.append('Area_Of_Residence', this.state.Area_Of_Residence);
      form_data.append('District', this.state.District);
      form_data.append('RegisteredPhoneNumber1', this.state.RegisteredPhoneNumber1);
      form_data.append('RegisteredPhoneNumber2', this.state.RegisteredPhoneNumber2);

      form_data.append('next_of_kin', this.state.next_of_kin);
      form_data.append('Next_Of_Kin_Physical_Address', this.state.Next_Of_Kin_Physical_Address);
      form_data.append('relationship', this.state.relationship);
      form_data.append('Date_Of_Joining', this.state.Date_Of_Joining);

      form_data.append('staff_role', this.state.staff_role);
      form_data.append('date_of_appointment', this.state.date_of_appointment);
      form_data.append('status', this.state.status);
      form_data.append('staff_number', this.state.staff_number);
      form_data.append('reference_one', this.state.reference_one);
      form_data.append('Referee1PhoneNumber', this.state.Referee1PhoneNumber);


      this.state.Photo==null?
      console.log("No profile file")
      :
      form_data.append('Photo', this.state.Photo, this.state.Photo?this.state.Photo.name:"");

        axios.put(`${serverconfig.backendserverurl}/api/staffs/${staffID}/`,form_data, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
      .then(res =>this.setState({datarequested:false}), window.location.reload(false),message.info('Successfully updated'))
      .catch(error => console.log(error))
      }



    componentDidMount(){
      if(localStorage.getItem("sacco")){
         sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      }else{
         sacco= ''
      }
      
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }

      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }


      axios.get(serverconfig.backendserverurl+`/api/staffroles/?sacco=${sacco}`)
      .then(res => {
          this.setState({
            staffroles:res.data
          })
      })
  
      axios.get(serverconfig.backendserverurl+`/api/idtypes/?sacco=${sacco}`)
      .then(res => {
          this.setState({
            idtypes:res.data
          })
      })


        const staffID=this.props.match.params.staffID;
        axios.get(`${serverconfig.backendserverurl}/api/staffs/${staffID}`)
        .then(res => {  
            this.setState({
                staff: res.data
            })
            this.setState({sur_name:res.data.sur_name});
            this.setState({given_name:res.data.given_name});
            this.setState({middle_name:res.data.middle_name});
            this.setState({nationality:res.data.nationality});
            this.setState({ID_type:res.data.ID_type})
            this.setState({ID_number:res.data.ID_number})
            this.setState({date_Of_Birth:res.data.date_Of_Birth})
            this.setState({Email_Address:res.data.Email_Address})
            this.setState({P_O_Box:res.data.P_O_Box})
            this.setState({Area_Of_Residence:res.data.Area_Of_Residence})
            this.setState({District:res.data.District})
            this.setState({RegisteredPhoneNumber1:res.data.RegisteredPhoneNumber1})
            this.setState({RegisteredPhoneNumber2:res.data.RegisteredPhoneNumber2})
            this.setState({next_of_kin:res.data.next_of_kin})
            this.setState({Next_Of_Kin_Physical_Address:res.data.Next_Of_Kin_Physical_Address})
            this.setState({relationship:res.data.relationship})
            this.setState({Date_Of_Joining:res.data.Date_Of_Joining})
            this.setState({staff_role:res.data.staff_role})
            this.setState({date_of_appointment:res.data.date_of_appointment})
            this.setState({status:res.data.status})
            this.setState({staff_number:res.data.staff_number})
            this.setState({reference_one:res.data.reference_one})
            this.setState({Referee1PhoneNumber:res.data.Referee1PhoneNumber})
            this.setState({gender:res.data.gender})

            this.setState({datarequested:false})

          })
    }

  

    render(){

      if(this.state.datarequested===true){
        return(
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
          <Spin indicator={antIcon} />
          </div>
        )
  
      }else{
        return(
          <div>
              <Row>
              <Col xs="12" sm="6" lg="4">
              <Card title={this.state.staff.full_Name}>
                  <p>Nationality: {this.state.staff.nationality}</p>
                  <p>Gender: {this.state.staff.gender}</p>
                  <p>ID Type: {this.state.staff.idtypename}</p>
                  <p>ID Number: {this.state.staff.ID_number}</p>
                  <p>Date Of Birth: {this.state.staff.date_Of_Birth}</p>
                  <p>Email: {this.state.staff.Email_Address}</p>
                  <p>Box Number: {this.state.staff.P_O_Box}</p>
                  <p>Residence: {this.state.staff.Area_Of_Residence}</p>
                  <p>District: {this.state.staff.District}</p>
                  <p>System User?: {this.state.staff.sytem_user}</p>
                  <p>Tel one: {this.state.staff.RegisteredPhoneNumber1}</p>
                  <p>Tel two: {this.state.staff.RegisteredPhoneNumber2}</p>
                  <p>Next of kin: {this.state.staff.next_of_kin}</p>
                  <p>Next of kin(Address): {this.state.staff.Next_Of_Kin_Physical_Address}</p>
                  <p>Next of kin(Relationship): {this.state.staff.relationship}</p>
                  <p>Date of Joining: {this.state.staff.Date_Of_Joining}</p>
                  <p>Staff Role: {this.state.staff.staffrolename}</p>
                  <p>Date of Appointment: {this.state.staff.date_of_appointment}</p>
                  <p>Staff status: {this.state.staff.status}</p>
                  <p>Staff Number: {this.state.staff.staff_number}</p>
                  <p>Reference Personel: {this.state.staff.reference_one}</p>
                  <p>Ref Personel Contact: {this.state.staff.Referee1PhoneNumber}</p>
              </Card>
              </Col>
         
              <Col xs="12" sm="6" lg="4">
              <Card 
                      style={{padding:10}}
                      hoverable>
              <h4>Profile Image</h4>
              <Avatar
                  size={150}
                  icon={<UserOutlined />}
                  alt="Staff Logo"
                  src={this.state.staff.Photo}
              />

              <br/>
              <Divider dashed />
              <h4>System User?</h4>
               {
                this.state.staff.sytem_user ?
                <h5>Yes</h5>
                :
                <Button type="primary" onClick={this.showDrawer}>Turn System User</Button>
               }

              </Card>


              </Col>
              <Col xs="12" sm="6" lg="4">
              <Card 
                      style={{padding:10}}
                      hoverable>

              <h4>Staff Update</h4>
                  <Form>
                  <FormItem label="Sur Name">
                    <Input  placeholder="Put a name here." value={this.state.sur_name} onChange={this.handlesur_nameChange} />
                  </FormItem>

                  <FormItem label="Middle Name">
                    <Input  placeholder="Put a name here." value={this.state.middle_name} onChange={this.handlemiddle_nameChange} />
                  </FormItem>

                  <FormItem label="Given Name">
                    <Input  placeholder="Put a name here." value={this.state.given_name} onChange={this.handlegiven_nameChange} />
                  </FormItem>

                  <FormItem label="Gender"
                  name="gender">
                  <Select 
                  placeholder="Gender" style={{ width: 120 }}
                   value={this.state.gender} 
                   onChange={this.handlegenderChange} 
                   
                   showSearch
                   optionFilterProp="children"
                   onFocus={this.onFocus}
                   onBlur={this.onBlur}
                   onSearch={this.onSearch} 
                   
                   >
                        <Option value="Male">Male</Option>
                        <Option value="Female">Female</Option>
                  </Select>
                 </FormItem>

                  <FormItem label="Nationality">
                    <Input  placeholder="Nationality." value={this.state.nationality} onChange={this.handlenationalityChange} />
                  </FormItem>


                  <FormItem label="ID Type">
                      <Select placeholder="Id Type" 
                      style={{ width: 120 }} 
                      value={this.state.ID_type}
                       onChange={this.handleID_typeChange} 
                       showSearch
                       optionFilterProp="children"
                       onFocus={this.onFocus}
                       onBlur={this.onBlur}
                       onSearch={this.onSearch} 
                       
                       >
                        {this.state.idtypes.map(
                          (type)=>(
                            <Option value={type.id}>{type.id_type_name}</Option>
                          ))}
                      </Select>
                  </FormItem>

                  <FormItem label="ID Number">
                    <Input  placeholder="ID Number." value={this.state.ID_number} onChange={this.handleID_numberChange} />
                  </FormItem>

                  <FormItem label="Date of Birth">
                        <DatePicker value={moment(this.state.date_Of_Birth,dateFormat)} onChange={this.handledate_Of_BirthChange} format={dateFormat} />
                  </FormItem>


                  <FormItem label="Email Address">
                    <Input placeholder="Email Address." value={this.state.Email_Address} onChange={this.handleEmail_AddressChange} />
                  </FormItem>

                  <FormItem label="Box Number">
                    <Input placeholder="box number." value={this.state.P_O_Box} onChange={this.handleP_O_BoxChange} />
                  </FormItem>

                  <FormItem label="Area of Residence">
                    <Input placeholder="Area of residence." value={this.state.Area_Of_Residence} onChange={this.handleArea_Of_ResidenceChange} />
                  </FormItem>

                  <FormItem label="District">
                    <Input placeholder="District." value={this.state.District} onChange={this.handleDistrictChange} />
                  </FormItem>

                  <FormItem label="Phone One">
                  <PhoneInput
                        countrySelectProps={{ unicodeFlags: true }}
                        defaultCountry="UG"
                        placeholder="Enter phone number"
                        value={this.state.RegisteredPhoneNumber1} onChange={this.handleRegisteredPhoneNumber1Change}/>
                  </FormItem> 

                  <FormItem label="Phone Two">
                  <PhoneInput
                        countrySelectProps={{ unicodeFlags: true }}
                        defaultCountry="UG"
                        placeholder="Enter phone number"
                        value={this.state.RegisteredPhoneNumber2} onChange={this.handleRegisteredPhoneNumber2Change}/>
                  </FormItem> 


                  <FormItem label="Next of kin">
                    <Input placeholder="Next of kin." value={this.state.next_of_kin} onChange={this.handlenext_of_kinChange} />
                  </FormItem>

                  <FormItem label="Next of kin Address">
                    <Input placeholder="Next of Kin Address." value={this.state.Next_Of_Kin_Physical_Address} onChange={this.handleNext_Of_Kin_Physical_AddressChange} />
                  </FormItem>

                  <FormItem label="Relationship">
                    <Input placeholder="Relationship." value={this.state.relationship} onChange={this.handlerelationshipChange} />
                  </FormItem>

                  <FormItem label="Staff Photo">
                        <Input type="file" accept="image/png, image/jpeg" placeholder="Staff Photo"  onChange={this.handlePhotoChange}/>
                  </FormItem> 

                  <FormItem label="Date of Joining">
                        <DatePicker value={moment(this.state.Date_Of_Joining,dateFormat)} onChange={this.handleDate_Of_JoiningChange} format={dateFormat}/>
                  </FormItem>


                  <FormItem label="Staff Role">
                      <Select 
                      placeholder="Staff Role" 
                      style={{ width: 120 }} 
                      value={this.state.staff_role} 
                      onChange={this.handlestaff_roleChange}
                      showSearch
                      optionFilterProp="children"
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
                      onSearch={this.onSearch} 
                      
                      >
                        {this.state.staffroles.map(
                          (stf)=>(
                            <Option value={stf.id}>{stf.role_name}</Option>
                          ))}
                      </Select>
                  </FormItem>
                  

                  <FormItem label="Date of Appointment">
                        <DatePicker value={moment(this.state.date_of_appointment,dateFormat)} onChange={this.handledate_of_appointmentChange} format={dateFormat}/>
                  </FormItem>
                  

                  <FormItem label="Staff Status">
                      <Select placeholder="Status"
                       style={{ width: 120 }} 
                       value={this.state.status} 
                       onChange={this.handlestatusChange}
                       showSearch
                       optionFilterProp="children"

                       
                       >
                            <Option value="Active">Active</Option>
                            <Option value="Inactive">Inactive</Option>
                      </Select>
                  </FormItem>


                  <FormItem label="Staff Number">
                    <Input placeholder="Staff Number." value={this.state.staff_number} onChange={this.handlestaff_numberChange} />
                  </FormItem>

                  <FormItem label="Job Reference Personel">
                    <Input placeholder="Reference Name." value={this.state.reference_one} onChange={this.handlereference_oneChange} />
                  </FormItem>

                  <FormItem label="Reference Phone No.">
                    <PhoneInput
                          countrySelectProps={{ unicodeFlags: true }}
                          defaultCountry="UG"
                          placeholder="Enter Refree Tel"
                          value={this.state.Referee1PhoneNumber} onChange={this.handleReferee1PhoneNumberChange}/>
                  </FormItem>


                  <FormItem>
                    <Button onClick={(event) => this.handleFormSubmit()} type="primary" htmlType="button">Update</Button>
                  </FormItem>
                </Form>

              </Card>
                
                  
              </Col>

              </Row>

              <br/>
              <Drawer
                  title="System User Form"
                  width={520}
                  closable={false}
                  onClose={this.onClose}
                  visible={this.state.visible}
                  >
                    
               <StaffUserSignUpForm staffID={this.state.staff.id} username={this.state.staff.given_name} email={this.state.staff.Email_Address}/>       

             </Drawer>


          </div>
      )


      }

      
    }
}

export default StaffDetail; 