import React from 'react';

import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Card,Tooltip,Descriptions,Modal,Spin,Tabs } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,PrinterOutlined,DeleteOutlined,FundViewOutlined,WalletFilled,DownloadOutlined,LoadingOutlined,FilePdfFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import  LoanImportForm from '../components/LoanImportForm'
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';
import ReactToPrint from 'react-to-print';
import ReactExport from "@ibrahimrahmani/react-export-excel";
import '../tablestyle.css'; // Create this CSS file for custom styles
import  LoanCertificateGenerationForm from '../components/LoanCertificateGenerationForm'

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

function callback(key) {
  console.log(key);
}

const { TabPane } = Tabs;


var sacco= ''
var bizuserid= ''
var token= ''

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ReceiptToPrint extends React.Component {

  state = {
    companyprofile:{}
  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

  

  }

//calculate principleTotal
calculateScheduleTotal=()=>{
  var principleTotal=0
  this.props.loanschedule.map(
    (item)=>{
      principleTotal+=Number(item.principle)
    })

  return principleTotal.toFixed(2);
}

  //calculate principleTotal
  calculateInterestTotal=()=>{
    var InterestTotal=0
    this.props.loanschedule.map(
      (item)=>{
        InterestTotal+=Number(item.interest)
      })

    return InterestTotal.toFixed(2);
  }

  //calculate principleTotal
  calculateTotalPay=()=>{
    var TotalPay=0
    this.props.loanschedule.map(
      (item)=>{
        TotalPay+=Number(item.total)
      })

    return TotalPay.toFixed(2);
  }



  render() {
    return (
      <div style={{padding:20}}>
        <style>
          {`@media print {
          @page { size: landscape; }
            }`}
        </style>
            
      <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>

                  </Col>

              </Row>
                  <h3 style={{fontWeight:'bolder',display: 'flex',justifyContent:'center', alignItems:'center'}}> LOAN SCHEDULE </h3>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NUMBER:  {this.props.loanissueobject.accountNumber} </h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NAME:  {this.props.loanissueobject.accountName} </h4>

                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> AMOUNT: <CurrencyFormat value={this.props.loanissueobject.approved_amount} displayType={'text'} thousandSeparator={true}/> </h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> PERIOD: {this.props.loanissueobject.loan_period} {this.props.loanissueobject.loan_primary_schedule==="daily"?"Days":this.props.loanissueobject.loan_primary_schedule==="weekly"?"Weeks":"Months"}</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> INTEREST RATE: {this.props.interest_rate}</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'end'}}> DATE OF ISSUANCE: {this.props.loanissueobject.date}</h4>
                  <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'end'}}> LOAN NUMBER: {this.props.loanissueobject.loan_number}</h4>

                  <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Month</th>
                            <th>Principle Bf</th>
                            <th>Installment</th>
                            <th>Interest </th>
                            <th>Principle </th>
                            <th>Principle Bal</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.props.loanschedule.map(
                          (item)=>(
                            <tr>
                            <td>{item.repayment_number}</td>
                            <td>{item.date}</td>
                            <td>{item.payment_month}</td>
                            <td className="align-right">{<CurrencyFormat value={item.principlebroughtforward} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.total} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.interest} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.principle} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            </tr>
                          ))}
                          <tr>
                          <th>Total</th>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalPay()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateInterestTotal()} displayType={'text'} thousandSeparator={true}/>}</td> 
                          <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateScheduleTotal()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td></td>
                          </tr>

                      </tbody>
                  </reactstrp.Table>

    </div>
    );
  }
}


class StatementToPrint extends React.Component {

  state = {
    companyprofile:{}
  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
   }else{
      sacco= ''
   }
   
   if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

  }

    //calculate principleTotal
    calculateTotalPay=()=>{
      var TotalPay=0
      this.state.loanschedule.map(
        (item)=>{
          TotalPay+=Number(item.total)
        })
  
      return TotalPay.toFixed(2);
    }


      //calculate principle paid
      calculateTotalInterestcharged=()=>{
      var TotalPay=0
      this.props.loanrepayments.map(
        (item)=>{
          TotalPay+=Number(item.interest_charged)
        })
  
      return TotalPay.toFixed(2);
     }
  
      //calculate Interest paid
      calculateTotalInterestPaid=()=>{
        var TotalPay=0
        this.props.loanrepayments.map(
          (item)=>{
            TotalPay+=Number(item.interest_paid)
          })
    
        return TotalPay.toFixed(2);
       }

             //calculate Total total paid
      calculateTotalprinciplepaid=()=>{
        var TotalPay=0
        this.props.loanrepayments.map(
          (item)=>{
            TotalPay+=Number(item.principle_paid)
          })
    
        return TotalPay.toFixed(2);
       }

       //calculate total fine paid
       calculateTotalFinePaid=()=>{
        var TotalPay=0
        this.props.loanrepayments.map(
          (item)=>{
            TotalPay+=Number(item.fine_paid)
          })
    
        return TotalPay.toFixed(2);
       }


  render() {
    return (
      <div style={{padding:20}}>      
      <style>
        {`@media print {
        @page { size: landscape; }
          }`}
      </style>

      <Row> 
            <Col xs="2" sm="2" lg="2">
            <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
          
              </Col>
              <Col xs="6" sm="6" lg="6">
              <h3>{this.state.companyprofile.sacco_name}</h3>
              <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
              {
              this.state.companyprofile.RegisteredPhoneNumber2==""?
              "":","+this.state.companyprofile.RegisteredPhoneNumber2
              }
              {
              this.state.companyprofile.RegisteredPhoneNumber3==""?
              "":","+this.state.companyprofile.RegisteredPhoneNumber3
              }
              </h6>               
              <h5>Email: {this.state.companyprofile.company_email}</h5>
              <h5>Wesite: {this.state.companyprofile.website}</h5>
              <h5>{this.state.companyprofile.box_number}</h5>

              </Col>

          </Row>
              <h3 style={{fontWeight:'bolder',display: 'flex',justifyContent:'center', alignItems:'center'}}> LOAN TRACKING STATEMENT </h3>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NUMBER:  {this.props.loanissueobject.accountNumber} </h4>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NAME:  {this.props.loanissueobject.accountName} </h4>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> AMOUNT: <CurrencyFormat value={this.props.loanissueobject.approved_amount} displayType={'text'} thousandSeparator={true}/> UGX</h4>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> PERIOD: {this.props.loanissueobject.loan_period} {this.props.loanissueobject.loan_primary_schedule==="daily"?"Days":this.props.loanissueobject.loan_primary_schedule==="weekly"?"Weeks":"Months"}</h4>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> INTEREST RATE: {this.props.interest_rate}</h4>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'end'}}> DATE OF ISSUANCE: {this.props.loanissueobject.date}</h4>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'end'}}> LOAN NUMBER: {this.props.loanissueobject.loan_number}</h4>

              <reactstrp.Table bordered>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>No Of days</th>
                        <th>Open R/Ln Bal.</th>
                        <th>Installment Paid</th>
                        <th>Interest charged</th>
                        <th>Int Paid</th>
                        <th>Principle1 (Paid)</th>
                        <th>Unpaid Int</th>
                        <th>R/Int</th>
                        <th>R/Princ or Loan Issued</th>
                        <th>Outstanding Bal</th>
                        <th>Payment Details</th>
                      </tr>
                    </thead>
                    <tbody>
                    {this.props.loanrepayments.map(
                      (item)=>(
                        <tr>
                        <td>{item.date}</td>
                        <td>{item.no_ofdays}</td>
                        <td className="align-right">{<CurrencyFormat value={item.open_runningbal.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right">{<CurrencyFormat value={item.installment_paid.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right">{<CurrencyFormat value={item.interest_charged.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right">{<CurrencyFormat value={item.interest_paid.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right">{<CurrencyFormat value={item.principle_paid.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>

                        <td className="align-right">{<CurrencyFormat value={item.interest_unpaid.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right">{<CurrencyFormat value={item.running_int.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        
                        <td className="align-right">{<CurrencyFormat value={item.running_principle.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right">{<CurrencyFormat value={item.outstanding_bal.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td>{item.description}</td>

                        </tr>
                      ))}
                      <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>

                      <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalInterestcharged()} displayType={'text'} thousandSeparator={true}/>}</td>
                      <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalInterestPaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                      <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalprinciplepaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      </tr>

                  </tbody>
              </reactstrp.Table>   
    </div>
    );
  }
}



class LoanSecuritiesPrint extends React.Component {

  state = {
    companyprofile:{}
  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
   }else{
      sacco= ''
   }
   
   if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

  }



       //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
       //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
       // method for securities
       calculate_principle_paid_sec=()=>{
        var total=0

        this.props.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.principle_paid)
          })

        return total.toFixed(2);
       }

       calculate_savings_paid_sec=()=>{
        var total=0
        this.props.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.savings_amount_paid)
          })
        return total.toFixed(2);

       }

       calculate_savings_freed_sec=()=>{
        var total=0
        this.props.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.savings_amount_freed)
          })
        return total.toFixed(2);
       }


       calculate_supersave_paid_sec=()=>{
        var total=0
        this.props.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.supersave_amount_paid)
          })
        return total.toFixed(2);
       }

       calculate_supersave_freed_sec=()=>{
        var total=0
        this.props.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.supersave_amount_freed)
          })
        return total.toFixed(2);
       }


       calculate_teebagoolo_paid_sec=()=>{
        var total=0
        this.props.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.teebagoolo_amount_paid)
          })
        return total.toFixed(2);
       }

       calculate_teebagoolo_freed_sec=()=>{
        var total=0
        this.props.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.teebagoolo_amount_freed)
          })
        return total.toFixed(2);
       }

       calculate_shares_paid_sec=()=>{
        var total=0
        this.props.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.shares_amount_paid)
          })
        return total.toFixed(2);
       }

       calculate_shares_freed_sec=()=>{
        var total=0
        this.props.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.shares_amount_freed)
          })
        return total.toFixed(2);
       }

       /////////////////////////
      ///others start here
      ///////////////
       calculate_project_interest_paid_sec=()=>{
        var total=0
        this.props.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.project_interest_paid)
          })
        return total.toFixed(2);
       }

       calculate_project_interest_freed_sec=()=>{
        var total=0
        this.props.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.project_interest_freed)
          })
        return total.toFixed(2);
       }

       calculate_own_title_paid_sec=()=>{
        var total=0
        this.props.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.own_title_paid)
          })
        return total.toFixed(2);
       }

       calculate_own_title_freed_sec=()=>{
        var total=0
        this.props.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.own_title_freed)
          })
        return total.toFixed(2);
       }


       calculate_reapers_title_paid_sec=()=>{
        var total=0
        this.props.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.reapers_title_paid)
          })
        return total.toFixed(2);
       }

       calculate_reapers_title_freed_sec=()=>{
        var total=0
        this.props.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.reapers_title_freed)
          })
        return total.toFixed(2);
       }


       calculate_other_collateral_paid_sec=()=>{
        var total=0
        this.props.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.other_collateral_paid)
          })
        return total.toFixed(2);
       }

       calculate_other_collateral_freed_sec=()=>{
        var total=0
        this.props.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.other_collateral_freed)
          })
        return total.toFixed(2);
       }




  render() {
    return (
      <div style={{padding:20}}>      
      <style>
        {`@media print {
        @page { size: landscape; }
          }`}
      </style>

      <Row> 
            <Col xs="2" sm="2" lg="2">
            <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
          
              </Col>
              <Col xs="6" sm="6" lg="6">
              <h3>{this.state.companyprofile.sacco_name}</h3>
              <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
              {
              this.state.companyprofile.RegisteredPhoneNumber2==""?
              "":","+this.state.companyprofile.RegisteredPhoneNumber2
              }
              {
              this.state.companyprofile.RegisteredPhoneNumber3==""?
              "":","+this.state.companyprofile.RegisteredPhoneNumber3
              }
              </h6>               
              <h5>Email: {this.state.companyprofile.company_email}</h5>
              <h5>Wesite: {this.state.companyprofile.website}</h5>
              <h5>{this.state.companyprofile.box_number}</h5>

              </Col>

          </Row>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NUMBER:  {this.props.loanissueobject.accountNumber} </h4>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> ACCOUNT NAME:  {this.props.loanissueobject.accountName} </h4>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> AMOUNT: <CurrencyFormat value={this.props.loanissueobject.approved_amount} displayType={'text'} thousandSeparator={true}/> UGX</h4>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> PERIOD: {this.props.loanissueobject.loan_period} {this.props.loanissueobject.loan_primary_schedule==="daily"?"Days":this.props.loanissueobject.loan_primary_schedule==="weekly"?"Weeks":"Months"}</h4>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'flex-end'}}> INTEREST RATE: {this.props.interest_rate}</h4>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'end'}}> DATE OF ISSUANCE: {this.props.loanissueobject.date}</h4>
              <h4 style={{fontWeight:'bolder',display: 'flex', alignItems:'end'}}> LOAN NUMBER: {this.props.loanissueobject.loan_number}</h4>
              <br></br>
              <h3>LOAN SECURITIES</h3>
                        <Table   
                        columns={this.props.sec_columns} 
                        scroll={{ x: 1000 }}
                        dataSource={this.props.loansecurites} 
                        pagination={{showQuickJumper:false,showSizeChanger:false }}
                        bordered
                        />
    
                        <h4>LOAN SECURITIES FREE TRACKING</h4>
                        <div style={{overflowX:'scroll'}}>

                            <reactstrp.Table bordered>
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Account </th>
                                <th>Type</th>
                                <th>Principle Paid</th>

                                <th>Savings paid</th>
                                <th>Savings freed</th>
                                <th>Savings running balance</th>

                                <th>Supersave paid</th>
                                <th>Supersave freed</th>
                                <th>Supersave running balance</th>

                                <th>Teebagoolo paid</th>
                                <th>Teebagoolo freed</th>
                                <th>Teebagoolo running balance</th>

                                <th>Shares paid</th>
                                <th>Shares freed</th>
                                <th>Shares running balance</th>

                                <th>Project interest paid</th>
                                <th>Project interest freed</th>
                                <th>Project interest running balance</th>


                                <th>Own title paid</th>
                                <th>Own title freed</th>
                                <th>Own title running balance</th>

                                <th>Reapers title paid</th>
                                <th>Reapers title freed</th>
                                <th>Reapers title running balance</th>

                                <th>Other collateral paid</th>
                                <th>Other collateral freed</th>
                                <th>Other collateral running balance</th>

                              </tr>
                            </thead>
                            <tbody>
                            {this.props.loansecurity_freeing.map(
                              (item)=>(
                                <tr>
                                <td>{item.date_payment}</td>
                                <td>{item.security_ref_account_name}</td>
                                <td>{item.security_ref_security_type}</td>
                                <td className="align-right">{<CurrencyFormat value={Number(item.principle_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right">{<CurrencyFormat value={Number(item.savings_amount_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right">{<CurrencyFormat value={Number(item.savings_amount_freed).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right">{<CurrencyFormat value={Number(item.savings_amount_runningbalance).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                            
                                <td className="align-right">{<CurrencyFormat value={Number(item.supersave_amount_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right">{<CurrencyFormat value={Number(item.supersave_amount_freed).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right">{<CurrencyFormat value={Number(item.supersave_amount_runningbalance).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                               
                                <td className="align-right">{<CurrencyFormat value={Number(item.teebagoolo_amount_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right">{<CurrencyFormat value={Number(item.teebagoolo_amount_freed).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right">{<CurrencyFormat value={Number(item.teebagoolo_amount_runningbalance).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>

                                <td className="align-right">{<CurrencyFormat value={Number(item.shares_amount_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right">{<CurrencyFormat value={Number(item.shares_amount_freed).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right">{<CurrencyFormat value={Number(item.shares_amount_runningbalance).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>

                                <td className="align-right">{<CurrencyFormat value={Number(item.project_interest_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right">{<CurrencyFormat value={Number(item.project_interest_freed).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right">{<CurrencyFormat value={Number(item.project_interest_runningbalance).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
    
                                <td className="align-right">{<CurrencyFormat value={Number(item.own_title_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right">{<CurrencyFormat value={Number(item.own_title_freed).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right">{<CurrencyFormat value={Number(item.own_title_runningbalance).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>

                                <td className="align-right">{<CurrencyFormat value={Number(item.reapers_title_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right">{<CurrencyFormat value={Number(item.reapers_title_freed).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right">{<CurrencyFormat value={Number(item.reapers_title_runningbalance).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>

                                <td className="align-right">{<CurrencyFormat value={Number(item.other_collateral_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right">{<CurrencyFormat value={Number(item.other_collateral_freed).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right">{<CurrencyFormat value={Number(item.other_collateral_runningbalance).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>

                                </tr>
                              ))}
                              <tr>
                              <td>Totals</td>
                              <td></td>
                              <td></td>
    
                              <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_principle_paid_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                              <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_savings_paid_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                              <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_savings_freed_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                              <td></td>
                              <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_supersave_paid_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                              <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_supersave_freed_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                              <td></td>
                              <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_teebagoolo_paid_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                              <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_teebagoolo_freed_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                              <td></td>
                              <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_shares_paid_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                              <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_shares_freed_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                              <td></td>

                              <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_project_interest_paid_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                              <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_project_interest_freed_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                              <td></td>


                              <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_own_title_paid_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                              <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_own_title_freed_sec()} displayType={'text'} thousandSeparator={true}/>}</td>

                              <td></td>
                              <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_reapers_title_paid_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                              <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_reapers_title_freed_sec()} displayType={'text'} thousandSeparator={true}/>}</td>

                              <td></td>
                              <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_other_collateral_paid_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                              <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_other_collateral_freed_sec()} displayType={'text'} thousandSeparator={true}/>}</td>

                              <td></td>

                              </tr>
                          </tbody>
                      </reactstrp.Table>
                      </div>
             
    </div>
    );
  }
}


class LoanIssuanceList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    loanissues: [],
    viewpaymentmodal:false,
    expensedetails:{},
    userprofile:{},
    datarequested:true,
    inactiveloanissues: [],

    loansecurites: [],
    securitesoverroleTotal: 0,
    securitesoverroleTotal_percentage: 0,

    loanschedule:[],
    loanissueobject:{},
    loanrepayments:[],
    loandailyschedules:[],

    deletelist:[],
    totaltobepaidobject:{},
    interestpay:0,

    newreschdate:'',
    newreschamt:0,

    loansecurity_freeing:[],
    current_selected_loan:0,
    loan_particular_data_loading:false,
    fullypaid_loans: [],


  };


  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       bizuserid= ''
    
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    let form_data = new FormData();
    form_data.append('userid', bizuserid);
    
    //get the user profile here
    axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          userprofile:res.data
        })
     
    })

    axios.get(serverconfig.backendserverurl+`/api/loanissues/?sacco=${sacco}&active_loan=${true}`)
    .then(res => {
        this.setState({
          loanissues:res.data
        })
    })


    axios.get(serverconfig.backendserverurl+`/customqueries/get_allloans/${sacco}`)
    .then(res => {
        this.setState({
          loanissues:JSON.parse(res.data.report)
        })

        this.setState({
          fullypaid_loans:JSON.parse(res.data.report_fullypaid)
        })

        this.setState({datarequested:false})
    })

}


    //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {

    let form_data1 = new FormData();
    form_data1.append('itemid', id);
    form_data1.append('sacco', sacco);
    form_data1.append('table_model', "LoanIssue");
  
    axios.post(`${serverconfig.backendserverurl}/customqueries/puttorecyclebin`, form_data1, {
       headers: {
         'content-type': 'multipart/form-data'
       }
     }).then(res =>{
       window.location.reload(false)
       message.info('Operation successful')
     }
     );

  };


getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};



  //calculate principleTotal
  calculateScheduleTotal=()=>{
    var principleTotal=0
    this.state.loanschedule.map(
      (item)=>{
        principleTotal+=Number(item.principle)
      })

    return principleTotal.toFixed(2);
  }

    //calculate principleTotal
    calculateInterestTotal=()=>{
      var InterestTotal=0
      this.state.loanschedule.map(
        (item)=>{
          InterestTotal+=Number(item.interest)
        })
  
      return InterestTotal.toFixed(2);
    }

    //calculate principleTotal
    calculateTotalPay=()=>{
      var TotalPay=0
      this.state.loanschedule.map(
        (item)=>{
          TotalPay+=Number(item.total)
        })
  
      return TotalPay.toFixed(2);
    }


      //calculate principle paid
      calculateTotalInterestcharged=()=>{
      var TotalPay=0
      this.state.loanrepayments.map(
        (item)=>{
          TotalPay+=Number(item.interest_charged)
        })
  
      return TotalPay.toFixed(2);
     }
  
      //calculate Interest paid
      calculateTotalInterestPaid=()=>{
        var TotalPay=0
        this.state.loanrepayments.map(
          (item)=>{
            TotalPay+=Number(item.interest_paid)
          })
    
        return TotalPay.toFixed(2);
       }

             //calculate Total total paid
      calculateTotalprinciplepaid=()=>{
        var TotalPay=0
        this.state.loanrepayments.map(
          (item)=>{
            TotalPay+=Number(item.principle_paid)
          })
    
        return TotalPay.toFixed(2);
       }

       //calculate total fine paid
       calculateTotalFinePaid=()=>{
        var TotalPay=0
        this.state.loanrepayments.map(
          (item)=>{
            TotalPay+=Number(item.fine_paid)
          })
    
        return TotalPay.toFixed(2);
       }


       //calculate total fine paid
       convert_calinterest=()=>{
        var Val_u=0

        if(this.state.loanissueobject.loan_primary_schedule==="monthly"){
          Val_u=Number(this.state.loanissueobject.loan_annual_interestrate)/12
        }else if(this.state.loanissueobject.loan_primary_schedule==="weekly"){
          Val_u=Number(this.state.loanissueobject.loan_annual_interestrate)/52
        }else if(this.state.loanissueobject.loan_primary_schedule==="daily"){
          Val_u=Number(this.state.loanissueobject.loan_annual_interestrate)/365
        }

        return Val_u.toFixed(2);
       }


       //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
       //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
       // method for securities
       calculate_principle_paid_sec=()=>{
        var total=0

        this.state.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.principle_paid)
          })

        return total.toFixed(2);
       }

       calculate_savings_paid_sec=()=>{
        var total=0
        this.state.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.savings_amount_paid)
          })
        return total.toFixed(2);

       }

       calculate_savings_freed_sec=()=>{
        var total=0
        this.state.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.savings_amount_freed)
          })
        return total.toFixed(2);
       }


       calculate_supersave_paid_sec=()=>{
        var total=0
        this.state.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.supersave_amount_paid)
          })
        return total.toFixed(2);
       }

       calculate_supersave_freed_sec=()=>{
        var total=0
        this.state.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.supersave_amount_freed)
          })
        return total.toFixed(2);
       }


       calculate_teebagoolo_paid_sec=()=>{
        var total=0
        this.state.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.teebagoolo_amount_paid)
          })
        return total.toFixed(2);
       }

       calculate_teebagoolo_freed_sec=()=>{
        var total=0
        this.state.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.teebagoolo_amount_freed)
          })
        return total.toFixed(2);
       }

       calculate_shares_paid_sec=()=>{
        var total=0
        this.state.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.shares_amount_paid)
          })
        return total.toFixed(2);
       }

       calculate_shares_freed_sec=()=>{
        var total=0
        this.state.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.shares_amount_freed)
          })
        return total.toFixed(2);
       }

       /////////////////////////
      ///others start here
      ///////////////
       calculate_project_interest_paid_sec=()=>{
        var total=0
        this.state.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.project_interest_paid)
          })
        return total.toFixed(2);
       }

       calculate_project_interest_freed_sec=()=>{
        var total=0
        this.state.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.project_interest_freed)
          })
        return total.toFixed(2);
       }

       calculate_own_title_paid_sec=()=>{
        var total=0
        this.state.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.own_title_paid)
          })
        return total.toFixed(2);
       }

       calculate_own_title_freed_sec=()=>{
        var total=0
        this.state.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.own_title_freed)
          })
        return total.toFixed(2);
       }


       calculate_reapers_title_paid_sec=()=>{
        var total=0
        this.state.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.reapers_title_paid)
          })
        return total.toFixed(2);
       }

       calculate_reapers_title_freed_sec=()=>{
        var total=0
        this.state.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.reapers_title_freed)
          })
        return total.toFixed(2);
       }


       calculate_other_collateral_paid_sec=()=>{
        var total=0
        this.state.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.other_collateral_paid)
          })
        return total.toFixed(2);
       }

       calculate_other_collateral_freed_sec=()=>{
        var total=0
        this.state.loansecurity_freeing.map(
          (item)=>{
            total+=Number(item.other_collateral_freed)
          })
        return total.toFixed(2);
       }



  render() {

    var sec_columns = [
      {
        title: 'Owner',
        dataIndex: 'security_type',
        key: 'id',
      },
      {
        title: 'Account name',
        dataIndex: 'accountname',
        key: 'id',
      },
      
      {
        title: 'Savings amount',
        dataIndex: 'savings_amount',
        key: 'id',
        render:(text,record)=><div>
          <p>Total: <CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  /></p>
          <p>85%: <CurrencyFormat value={0.85*Number(text)} displayType={'text'} thousandSeparator={true} /></p>
          
        </div> 
      },
      {
        title: 'Supersave amount',
        dataIndex: 'supersave_amount',
        key: 'id',
        render:(text)=>
        <div>
          <p>Total: <CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  /></p>
          <p>85%: <CurrencyFormat value={0.85*Number(text)} displayType={'text'} thousandSeparator={true}  /></p>

        </div>
      },
      {
        title: 'Teebagoolo amount',
        dataIndex: 'teebagoolo_amount',
        key: 'id',
        render:text=>
        <div>
            <p>Total: <CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  /></p>
            <p>85%: <CurrencyFormat value={0.85*Number(text)} displayType={'text'} thousandSeparator={true}  /></p>

        </div>
        },
      {
        title: 'Shares amount',
        dataIndex: 'shares_amount',
        key: 'id',
        render:text=>
        <div>
            <p>Total: <CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  /></p>
            <p>85%: <CurrencyFormat value={0.85*Number(text)} displayType={'text'} thousandSeparator={true}  /></p>

        </div>   
      },

      {
        title: 'Project interest',
        dataIndex: 'project_interest',
        key: 'id',
        render:text=>
        <div>
          <p>
          Total:<CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
          </p>
          <p>
          70%: <CurrencyFormat value={0.70*Number(text)} displayType={'text'} thousandSeparator={true}  />
          </p>
        </div>
      },
      {
        title: 'Own land',
        dataIndex: 'own_title',
        key: 'id',
        render:text=>
        <div>
        <p>
          Total:<CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        </p> 
        <p>
          70%: <CurrencyFormat value={0.70*Number(text)} displayType={'text'} thousandSeparator={true}  />
        </p> 
        </div>
     },
      {
        title: 'Reapers land',
        dataIndex: 'reapers_title',
        key: 'id',
        render:text=>
        <div>
          <p>
          Total:<CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        </p>
        <p>
          70%: <CurrencyFormat value={0.70*Number(text)} displayType={'text'} thousandSeparator={true}  />
        </p>
        </div>
      },
      {
        title: 'Other collateral',
        dataIndex: 'other_collateral',
        key: 'id',
        render:text=>
        <div>
        <p>
          Total:<CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
          </p> 
          <p>
            70%: <CurrencyFormat value={0.70*Number(text)} displayType={'text'} thousandSeparator={true}  />
          </p> 
        </div>
        },
      {
        title: 'Overall total',
        dataIndex: 'overall_total',
        key: 'id',
        render:text=>
        <p>
          <CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        </p>      
        },
        {
          title: 'Overall by 85% or 70%',
          dataIndex: 'overall_percentage',
          key: 'id',
          render:text=>
          <p>
            Total:<CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
          </p>      
          },
      ];


      var columns = [
        //Table.EXPAND_COLUMN,
        {
          title: 'Loan number',
          dataIndex: 'loan_number',
          key: 'id',
          ...this.getColumnSearchProps('loan_number'),
          render:(text,record)=>
          <div>
          <h5> {record.loan_number}</h5>

          {
            Number(record.paidtotalprinciple)>=Number(record.approved_amount)?
            <p style={{color:"green"}}>Cleared</p>
            :
            <p style={{color:record.status==="active"?"green":'red'}}>( {record.status} )</p>
          }
          </div>
        },
        {
          title: 'Loan top up?',
          dataIndex: 'loantopup',
          key: 'id',
          ...this.getColumnSearchProps('loantopup'),
          render:(text,record)=>
          <p>
            {text} | 
            {text==="Yes"?<Link to={`/loanissues/${record.loantopup_id}`}>Top up reference</Link>:null}
          </p>

        }
        ,
        {
          title: 'Loan type',
          dataIndex: 'loantypename',
          key: 'id',
          ...this.getColumnSearchProps('loantypename'),
        },

        {
          title: 'Issuance Date',
          dataIndex: 'date',
          key: 'id',
          ...this.getColumnSearchProps('date'),
        },
        {
          title: 'Maturity Date',
          dataIndex: 'maturity_date',
          key: 'id',
          ...this.getColumnSearchProps('maturity_date'),
        },
        {
          title: 'Loan Period',
          dataIndex: 'loan_period',
          key: 'id',
          render:(text,record)=><h5>{text} ({record.loan_primary_schedule})</h5>
        },
        {
          title: 'Account Number',
          dataIndex: 'accountNumber',
          key: 'id',
          ...this.getColumnSearchProps('accountNumber'),
        },
        {
          title: 'Account Name',
          dataIndex: 'accountName',
          key: 'id',
          ...this.getColumnSearchProps('accountName'),
        },
        {
          title: 'Loan Amount',
          dataIndex: 'approved_amount',
          key: 'id',
          render:text=><CurrencyFormat value={Number(text).toFixed(2)} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Cycles defaulted',
          dataIndex: 'cycles_due',
          key: 'id',
        },
        {
          title: 'Principle due',
          dataIndex: 'principle_due',
          key: 'id',
          render:text=><CurrencyFormat value={Number(text).toFixed(2)} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Interest due',
          dataIndex: 'intrest_due',
          key: 'id',
          render:text=><CurrencyFormat value={Number(text).toFixed(2)} displayType={'text'} thousandSeparator={true}  />
        },

        {

          title: 'Principle paid',
          dataIndex: 'paidtotalprinciple',
          key: 'id',
          render:text=><CurrencyFormat value={Number(text).toFixed(2)} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Interest paid',
          dataIndex: 'paidtotalinterest',
          key: 'id',
          render:text=><CurrencyFormat value={Number(text).toFixed(2)} displayType={'text'} thousandSeparator={true}  />
        },
       

        {
          title: 'Running principle balance',
          dataIndex: 'runningprinciple_balance',
          key: 'id',
          render:text=><CurrencyFormat value={Number(text).toFixed(2)} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Running interest balance',
          dataIndex: 'runningint_balance',
          key: 'id',
          render:text=><CurrencyFormat value={Number(text).toFixed(2)} displayType={'text'} thousandSeparator={true}  />
        },

        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: (text,record) =>
          <p>
          <Popover content={<p>Click here to view details(Schedule & Statement)</p>} title="View">
           <Link to={`/loanissues/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
          </Popover>         
           &nbsp;&nbsp;&nbsp;
          <Popover content={<p>Click here to view application details</p>} title="Edit,View">
           <Link to={`/loanapplications/${record.loan}`}><FundViewOutlined style={{color:'green'}}/></Link>
          </Popover>
          &nbsp;&nbsp;&nbsp;
{/*          <Popover content={<p>Click here to delete loan</p>} title="Delete">
          <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
            <DeleteOutlined style={{color:'red'}}/>
          </Popconfirm>
          </Popover> */}         
          </p>,
        }
  
      ];


    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
      
    }else{
      return(
        <div>

    <div style={{display: "none"}}>
      &nbsp;&nbsp;
      <ReceiptToPrint
      loanschedule={this.state.loanschedule} 
      date={this.state.datetoday} 
      loanissueobject={this.state.loanissueobject}
      interest_rate={this.convert_calinterest()}
      ref={el => (this.componentRef = el)} /> 
      </div>

      <div style={{display: "none"}}>
       &nbsp;&nbsp;
      <StatementToPrint
      loanrepayments={this.state.loanrepayments} 
      date={this.state.datetoday} 
      loanissueobject={this.state.loanissueobject}
      interest_rate={this.convert_calinterest()}
      ref={sel => (this.componentReftwo = sel)} /> 
      </div>

      <div style={{display: "none"}}>
      &nbsp;&nbsp;
      <LoanSecuritiesPrint
      loansecurites={this.state.loansecurites} 
      loansecurity_freeing={this.state.loansecurity_freeing} 
      sec_columns={sec_columns} 
      date={this.state.datetoday} 
      loanissueobject={this.state.loanissueobject}
      interest_rate={this.convert_calinterest()}
      ref={sel => (this.componentRefthree = sel)} /> 
      </div>


    <Tabs defaultActiveKey="1" onChange={callback}>
     <TabPane tab="Active Loans" key="1">
       <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header={<h4 style={{fontWeight:'bolder'}}>ALL ISSUED LOANS</h4>} key="1">
            <br></br>


            <ExcelFile filename={"Pisys - Active loans report"}  element={<Button type='primary' icon={<DownloadOutlined/>}>Download (Excel)</Button>}>
                <ExcelSheet data={this.state.loanissues} name="Active loans">
                    <ExcelColumn label="Loan number" value="loan_number"/>
                    <ExcelColumn label="Loan topup?" value="loantopup"/>
                    <ExcelColumn label="Loan type" value="loantypename"/>
                    <ExcelColumn label="Loan duration" value="loan_period"/>
                    <ExcelColumn label="Date" value="date"/>
                    <ExcelColumn label="Maturity date" value="maturity_date"/>
                    <ExcelColumn label="Account number" value="accountNumber"/>
                    <ExcelColumn label="Account name" value="accountName"/>
                    <ExcelColumn label="Loan amount" value="approved_amount"/>
                    <ExcelColumn label="Due cycles " value="cycles_due"/>
                    <ExcelColumn label="Principle due " value="principle_due"/>
                    <ExcelColumn label="Interest due " value="intrest_due"/>
                    <ExcelColumn label="Principle paid " value="paidtotalprinciple"/>
                    <ExcelColumn label="Interest paid " value="paidtotalinterest"/>

                    <ExcelColumn label="Running principle balance " value="runningprinciple_balance"/>
                    <ExcelColumn label="Running interest balance " value="runningint_balance"/>

                </ExcelSheet>
            </ExcelFile>

            <br></br>
            <br></br>

            
            
            <Table  
            size='small'
            rowkey={(record) => record.id}
            columns={columns} 
            expandable={{
              expandedRowRender: (record) =>{
                if (Number(record.id)===Number(this.state.current_selected_loan)){
                  return (
                    <div
                      style={{
                        margin: 0,
                      }}
                     >

                      {
                        this.state.loan_particular_data_loading===true?
                        <div style={{display:'flex',margin:20}}>
                        <Spin indicator={antIcon} />
                        </div>
                        :


                        <Tabs defaultActiveKey="1" onChange={callback}>
    
                        <TabPane tab="Loan amortization" key="02" >
                       <Card>
                          <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>LOAN SCHEDULE</h4>
      
                          <br></br>
                        <div style={{display:'flex',justifyContent:'flex-start',alignSelf:'flex-start',alignItems:'flex-start'}}>
      
                        <a>
                            <ReactToPrint
                              trigger={() =>                     
                                <div style={{}}>
                                  <Tooltip title="Click to print" placement="top">
                                  <p style={{alignSelf:'center'}}><PrinterOutlined style={{ fontSize: '40px', color: '#08c' }}/></p>
                                  <h6 style={{alignSelf:'center'}}>Print Schedule</h6>
                                  </Tooltip>
                                </div>                          
                                  }
                                  content={() => this.componentRef}
                                />  
                                </a>
                        </div>
                        <br></br>


                          <reactstrp.Table bordered>
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Date</th>
                                  <th>Month</th>
                                  <th>Principle Bf</th>
                                  <th>Installment</th>
                                  <th>Interest </th>
                                  <th>Principle </th>
                                  <th>Principle Bal</th>
                                </tr>
                              </thead>
                              <tbody>
                              {this.state.loanschedule.map(
                                (item)=>(
                                  <tr>
                                  <td>{item.repayment_number}</td>
                                  <td>{item.date}</td>
                                  <td>{item.payment_month}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.principlebroughtforward} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.total} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.interest} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.principle} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.balance} displayType={'text'} thousandSeparator={true}/>}</td>
                                  </tr>
                                ))}
                                <tr>
                                <th>Total</th>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalPay()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateInterestTotal()} displayType={'text'} thousandSeparator={true}/>}</td> 
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateScheduleTotal()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td></td>
                                </tr>
      
                            </tbody>
                        </reactstrp.Table>

       
                       </Card>
      
                        </TabPane>
      
                        <TabPane tab="Loan tracker" key="03" >
      
                        <Card>
                          <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>LOAN TRACKING STATEMENT</h4>
                                
                          <br></br>
                        <div style={{display:'flex',justifyContent:'flex-start',alignSelf:'flex-start',alignItems:'flex-start'}}>
                        <a>
                        <ReactToPrint
                          trigger={() =>                     
                            <div style={{}}>
                              <Tooltip title="Click to print" placement="top">
                              <p style={{alignSelf:'center'}}><PrinterOutlined style={{ fontSize: '40px', color: '#08c' }}/></p>
                              <h6 style={{alignSelf:'center'}}>Print Statement</h6>
                              </Tooltip>
                            </div>                          
                              }
                              content={() => this.componentReftwo}
                            />  
                       </a>
                          </div>
                          
                          <reactstrp.Table bordered>
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>No Of days</th>
                                  <th>Open R/Ln Bal.</th>
                                  <th>Installment Paid</th>
                                  <th>Interest charged</th>
                                  <th>Int Paid</th>
                                  <th>Principle1 (Paid)</th>
                                  <th>Unpaid Int</th>
                                  <th>R/Int</th>
                                  <th>R/Princ or Loan Issued</th>
                                  <th>Outstanding Bal</th>
                                  <th>Payment Details</th>
                                </tr>
                              </thead>
                              <tbody>
                              {this.state.loanrepayments.map(
                                (item)=>(
                                  <tr>
                                  <td>{item.date}</td>
                                  <td>{item.no_ofdays}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.open_runningbal.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.installment_paid.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.interest_charged.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.interest_paid.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.principle_paid.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
      
                                  <td className="align-right">{<CurrencyFormat value={item.interest_unpaid.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.running_int.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                 
                                  <td className="align-right">{<CurrencyFormat value={item.running_principle.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.outstanding_bal.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td>{item.description}</td>
      
                                  </tr>
                                ))}
                                <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
      
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalInterestcharged()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalInterestPaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalprinciplepaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                </tr>
      
                            </tbody>
                        </reactstrp.Table>
                        
      

      
                    </Card>
      
                        </TabPane>
      
                        <TabPane tab="Loan Securities and guarantees" key="01" >
                          <h4>LOAN SECURITIES</h4>
                          <Table   
                          columns={sec_columns} 
                          scroll={{ x: 1000 }}
                          dataSource={this.state.loansecurites} 
                          pagination={{showQuickJumper:false,showSizeChanger:false }}
                          bordered
                          />
      
                          <h4>LOAN SECURITIES FREE TRACKING</h4>
                          <div style={{overflowX:'scroll'}}>

                          <br></br>
                        <div style={{display:'flex',justifyContent:'flex-start',alignSelf:'flex-start',alignItems:'flex-start'}}>
                        <a>
                              <ReactToPrint
                                trigger={() =>                     
                                  <div style={{}}>
                                    <Tooltip title="Click to print" placement="top">
                                    <p style={{alignSelf:'center'}}><PrinterOutlined style={{ fontSize: '40px', color: '#08c' }}/></p>
                                    <h6 style={{alignSelf:'center'}}>Print securities tracker</h6>
                                    </Tooltip>
                                  </div>                          
                                    }
                                    content={() => this.componentRefthree}
                                  />  
                                  </a>
                        </div>
                        <br></br>

  
                              <reactstrp.Table bordered>
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Account </th>
                                  <th>Type</th>
                                  <th>Principle Paid</th>
  
                                  <th>Savings paid</th>
                                  <th>Savings freed</th>
                                  <th>Savings running balance</th>
  
                                  <th>Supersave paid</th>
                                  <th>Supersave freed</th>
                                  <th>Supersave running balance</th>
  
                                  <th>Teebagoolo paid</th>
                                  <th>Teebagoolo freed</th>
                                  <th>Teebagoolo running balance</th>
  
                                  <th>Shares paid</th>
                                  <th>Shares freed</th>
                                  <th>Shares running balance</th>
  
                                  <th>Project interest paid</th>
                                  <th>Project interest freed</th>
                                  <th>Project interest running balance</th>
  
  
                                  <th>Own title paid</th>
                                  <th>Own title freed</th>
                                  <th>Own title running balance</th>
  
                                  <th>Reapers title paid</th>
                                  <th>Reapers title freed</th>
                                  <th>Reapers title running balance</th>
  
                                  <th>Other collateral paid</th>
                                  <th>Other collateral freed</th>
                                  <th>Other collateral running balance</th>
  
                                </tr>
                              </thead>
                              <tbody>
                              {this.state.loansecurity_freeing.map(
                                (item)=>(
                                  <tr>
                                  <td>{item.date_payment}</td>
                                  <td>{item.security_ref_account_name}</td>
                                  <td>{item.security_ref_security_type}</td>
                                  <td className="align-right">{<CurrencyFormat value={Number(item.principle_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={Number(item.savings_amount_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={Number(item.savings_amount_freed).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={Number(item.savings_amount_runningbalance).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                              
                                  <td className="align-right">{<CurrencyFormat value={Number(item.supersave_amount_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={Number(item.supersave_amount_freed).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={Number(item.supersave_amount_runningbalance).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                 
                                  <td className="align-right">{<CurrencyFormat value={Number(item.teebagoolo_amount_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={Number(item.teebagoolo_amount_freed).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={Number(item.teebagoolo_amount_runningbalance).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
  
                                  <td className="align-right">{<CurrencyFormat value={Number(item.shares_amount_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={Number(item.shares_amount_freed).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right"> {<CurrencyFormat value={Number(item.shares_amount_runningbalance).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
  
                                  <td className="align-right">{<CurrencyFormat value={Number(item.project_interest_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={Number(item.project_interest_freed).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={Number(item.project_interest_runningbalance).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
      
                                  <td className="align-right">{<CurrencyFormat value={Number(item.own_title_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={Number(item.own_title_freed).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={Number(item.own_title_runningbalance).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
  
                                  <td className="align-right">{<CurrencyFormat value={Number(item.reapers_title_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={Number(item.reapers_title_freed).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={Number(item.reapers_title_runningbalance).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
  
                                  <td className="align-right">{<CurrencyFormat value={Number(item.other_collateral_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={Number(item.other_collateral_freed).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={Number(item.other_collateral_runningbalance).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
  
                                  </tr>
                                ))}
                                <tr>
                                <td>Totals</td>
                                <td></td>
                                <td></td>
                                 <td></td>
{ /*                               <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_principle_paid_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
*/}                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_savings_paid_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_savings_freed_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td></td>
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_supersave_paid_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_supersave_freed_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td></td>
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_teebagoolo_paid_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_teebagoolo_freed_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td></td>
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_shares_paid_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_shares_freed_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td></td>
  
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_project_interest_paid_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_project_interest_freed_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td></td>
  
  
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_own_title_paid_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_own_title_freed_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
  
                                <td></td>
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_reapers_title_paid_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_reapers_title_freed_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
  
                                <td></td>
  
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_other_collateral_paid_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_other_collateral_freed_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
  
                                <td></td>
  
                                </tr>
                            </tbody>
                        </reactstrp.Table>
  
                        <br></br>
                       
                          </div>
                         
                        </TabPane>

                        <TabPane tab="Certificate generation" key="0001" >
                          <LoanCertificateGenerationForm loan_issuance={record}/>

                        </TabPane>


                        </Tabs>


                      }


                    </div>
                    );
                }else{
                  return null;
                }
              } ,
              rowExpandable: (record) => record.id !== this.state.current_selected_loan.id,
              onExpand:(condition,record)=>{
                this.setState({loan_particular_data_loading:true})

                //set curent id
                this.setState({current_selected_loan:record.id})

                var loanissueID=record.id
                //on expans
                let form_data = new FormData();
                form_data.append('loan_id', record.id);

                //Now submit sale data to database
                axios.post(serverconfig.backendserverurl+'/customqueries/getloansecurities', form_data,{
                  headers: {
                    'content-type': 'multipart/form-data'
                  }})
                .then(res =>{
                  this.setState({loansecurites:JSON.parse(res.data.report)})
                  this.setState({securitesoverroleTotal:res.data.overroletotal})
                  this.setState({securitesoverroleTotal_percentage:res.data.percentagetotal})
                  
                }).catch(error => console.log(error)) 

                //get other data
                axios.get(`${serverconfig.backendserverurl}/api/loanissues/${loanissueID}`)
                .then(res => {  
                    this.setState({loanissueobject: res.data})
            
                    this.setState({newreschdate:res.data.date})
                    this.setState({newreschamt:res.data.approved_amount})
            
                })
            
                axios.get(`${serverconfig.backendserverurl}/api/loanschedules/?loanissue=${loanissueID}`)
                .then(res => {  
                    this.setState({loanschedule: res.data})
                })
            
                axios.get(`${serverconfig.backendserverurl}/api/loanrepayments/?loanissue=${loanissueID}`)
                .then(res => {  
                    this.setState({loanrepayments: res.data})
                })
            
                axios.get(`${serverconfig.backendserverurl}/api/loandailyscheduledate/?loanissue=${loanissueID}`)
                .then(res => {  
                    this.setState({loandailyschedules: res.data})
                })

                axios.get(`${serverconfig.backendserverurl}/api/loansecurity_freeing/?loan_issue=${loanissueID}`)
                .then(res => {  
                    this.setState({loansecurity_freeing: res.data})

                    this.setState({loan_particular_data_loading:false})
                })

              },
            }}
            scroll={{ x: 2000 }}
            dataSource={this.state.loanissues} 
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            bordered
            />
            <h3>Total Loans: {this.state.loanissues.length}</h3>

            </Panel>
            </Collapse>
    </TabPane>

        <TabPane tab="Inactive Fully Paid" key="2">
         <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header={<h4 style={{fontWeight:'bolder'}}>ALL ISSUED LOANS</h4>} key="1">
            
            <br></br>

            <ExcelFile filename={"Pisys - Inactive/Fully paid loans report"}  element={<Button type='primary' icon={<DownloadOutlined/>}>Download (Excel)</Button>}>
                <ExcelSheet data={this.state.fullypaid_loans} name="Cleared loans">
                    <ExcelColumn label="Loan number" value="loan_number"/>
                    <ExcelColumn label="Loan topup?" value="loantopup"/>
                    <ExcelColumn label="Loan type" value="loantypename"/>
                    <ExcelColumn label="Loan duration" value="loan_period"/>
                    <ExcelColumn label="Date" value="date"/>
                    <ExcelColumn label="Maturity date" value="maturity_date"/>
                    <ExcelColumn label="Account number" value="accountNumber"/>
                    <ExcelColumn label="Account name" value="accountName"/>
                    <ExcelColumn label="Loan amount" value="approved_amount"/>
                    <ExcelColumn label="Due cycles " value="cycles_due"/>
                    <ExcelColumn label="Principle due " value="principle_due"/>
                    <ExcelColumn label="Interest due " value="intrest_due"/>
                    <ExcelColumn label="Principle paid " value="paidtotalprinciple"/>
                    <ExcelColumn label="Interest paid " value="paidtotalinterest"/>

                    <ExcelColumn label="Running principle balance " value="runningprinciple_balance"/>
                    <ExcelColumn label="Running interest balance " value="runningint_balance"/>

                </ExcelSheet>
            </ExcelFile>

            <br></br>
            <br></br>



            <Table  
            size='small'
            rowkey={(record) => record.id}
            columns={columns} 
            expandable={{
              expandedRowRender: (record) =>{
                if (Number(record.id)===Number(this.state.current_selected_loan)){
                  return (
                    <div
                      style={{
                        margin: 0,
                      }}
                     >

                      {
                        this.state.loan_particular_data_loading===true?
                        <div style={{display:'flex',margin:20}}>
                        <Spin indicator={antIcon} />
                        </div>
                        :

                        <Tabs defaultActiveKey="1" onChange={callback}>
    
                        <TabPane tab="Loan amortization" key="02" >
                       <Card>
                          <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>LOAN SCHEDULE</h4>
      
                          <reactstrp.Table bordered>
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Date</th>
                                  <th>Month</th>
                                  <th>Principle Bf</th>
                                  <th>Installment</th>
                                  <th>Interest </th>
                                  <th>Principle </th>
                                  <th>Principle Bal</th>
                                </tr>
                              </thead>
                              <tbody>
                              {this.state.loanschedule.map(
                                (item)=>(
                                  <tr>
                                  <td>{item.repayment_number}</td>
                                  <td>{item.date}</td>
                                  <td>{item.payment_month}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.principlebroughtforward} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.total} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.interest} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.principle} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.balance} displayType={'text'} thousandSeparator={true}/>}</td>
                                  </tr>
                                ))}
                                <tr>
                                <th>Total</th>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalPay()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateInterestTotal()} displayType={'text'} thousandSeparator={true}/>}</td> 
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateScheduleTotal()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td></td>
                                </tr>
      
                            </tbody>
                        </reactstrp.Table>
                        <br></br>
                        <div style={{display:'flex',justifyContent:'flex-end',alignSelf:'flex-end',alignItems:'flex-end'}}>
      
                        <a>
                            <ReactToPrint
                              trigger={() =>                     
                                <div style={{}}>
                                  <Tooltip title="Click to print" placement="top">
                                  <p style={{alignSelf:'center'}}><PrinterOutlined style={{ fontSize: '40px', color: '#08c' }}/></p>
                                  <h6 style={{alignSelf:'center'}}>Print Schedule</h6>
                                  </Tooltip>
                                </div>                          
                                  }
                                  content={() => this.componentRef}
                                />  
                                </a>
                        </div>
       
                       </Card>
      
      
                        </TabPane>
      
      
                        <TabPane tab="Loan tracker" key="03" >
      
                        <Card>
                          <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>LOAN TRACKING STATEMENT</h4>
                        <reactstrp.Table bordered>
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>No Of days</th>
                                  <th>Open R/Ln Bal.</th>
                                  <th>Installment Paid</th>
                                  <th>Interest charged</th>
                                  <th>Int Paid</th>
                                  <th>Principle1 (Paid)</th>
                                  <th>Unpaid Int</th>
                                  <th>R/Int</th>
                                  <th>R/Princ or Loan Issued</th>
                                  <th>Outstanding Bal</th>
                                  <th>Payment Details</th>
                                </tr>
                              </thead>
                              <tbody>
                              {this.state.loanrepayments.map(
                                (item)=>(
                                  <tr>
                                  <td>{item.date}</td>
                                  <td>{item.no_ofdays}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.open_runningbal.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.installment_paid.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.interest_charged.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.interest_paid.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.principle_paid.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
      
                                  <td className="align-right">{<CurrencyFormat value={item.interest_unpaid.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.running_int.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                 
                                  <td className="align-right">{<CurrencyFormat value={item.running_principle.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={item.outstanding_bal.toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td>{item.description}</td>
      
                                  </tr>
                                ))}
                                <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
      
                                <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalInterestcharged()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalInterestPaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalprinciplepaid()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                </tr>
      
                            </tbody>
                        </reactstrp.Table>
      
                        <a>
                        <ReactToPrint
                          trigger={() =>                     
                            <div style={{}}>
                              <Tooltip title="Click to print" placement="top">
                              <p style={{alignSelf:'center'}}><PrinterOutlined style={{ fontSize: '40px', color: '#08c' }}/></p>
                              <h6 style={{alignSelf:'center'}}>Print Statement</h6>
                              </Tooltip>
                            </div>                          
                              }
                              content={() => this.componentReftwo}
                            />  
                       </a>
      
                    </Card>
      
                        </TabPane>
      
                        <TabPane tab="Loan Securities and guarantees" key="01" >
                          <h4>LOAN SECURITIES</h4>
                          <Table   
                          columns={sec_columns} 
                          scroll={{ x: 1000 }}
                          dataSource={this.state.loansecurites} 
                          pagination={{showQuickJumper:false,showSizeChanger:false }}
                          bordered
                          />
      
                          <h4>LOAN SECURITIES FREE TRACKING</h4>
                          <div style={{overflowX:'scroll'}}>
  
                              <reactstrp.Table bordered>
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Account </th>
                                  <th>Type</th>
                                  <th>Principle Paid</th>
  
                                  <th>Savings paid</th>
                                  <th>Savings freed</th>
                                  <th>Savings running balance</th>
  
                                  <th>Supersave paid</th>
                                  <th>Supersave freed</th>
                                  <th>Supersave running balance</th>
  
                                  <th>Teebagoolo paid</th>
                                  <th>Teebagoolo freed</th>
                                  <th>Teebagoolo running balance</th>
  
                                  <th>Shares paid</th>
                                  <th>Shares freed</th>
                                  <th>Shares running balance</th>
  
                                  <th>Project interest paid</th>
                                  <th>Project interest freed</th>
                                  <th>Project interest running balance</th>
  
  
                                  <th>Own title paid</th>
                                  <th>Own title freed</th>
                                  <th>Own title running balance</th>
  
                                  <th>Reapers title paid</th>
                                  <th>Reapers title freed</th>
                                  <th>Reapers title running balance</th>
  
                                  <th>Other collateral paid</th>
                                  <th>Other collateral freed</th>
                                  <th>Other collateral running balance</th>
  
                                </tr>
                              </thead>
                              <tbody>
                              {this.state.loansecurity_freeing.map(
                                (item)=>(
                                  <tr>
                                  <td>{item.date_payment}</td>
                                  <td>{item.security_ref_account_name}</td>
                                  <td>{item.security_ref_security_type}</td>
                                  <td className="align-right">{<CurrencyFormat value={Number(item.principle_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={Number(item.savings_amount_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={Number(item.savings_amount_freed).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={Number(item.savings_amount_runningbalance).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                              
                                  <td className="align-right">{<CurrencyFormat value={Number(item.supersave_amount_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={Number(item.supersave_amount_freed).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={Number(item.supersave_amount_runningbalance).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                 
                                  <td className="align-right">{<CurrencyFormat value={Number(item.teebagoolo_amount_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={Number(item.teebagoolo_amount_freed).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={Number(item.teebagoolo_amount_runningbalance).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
  
                                  <td className="align-right">{<CurrencyFormat value={Number(item.shares_amount_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={Number(item.shares_amount_freed).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={Number(item.shares_amount_runningbalance).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
  
                                  <td className="align-right">{<CurrencyFormat value={Number(item.project_interest_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={Number(item.project_interest_freed).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={Number(item.project_interest_runningbalance).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
      
                                  <td className="align-right">{<CurrencyFormat value={Number(item.own_title_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={Number(item.own_title_freed).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={Number(item.own_title_runningbalance).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
  
                                  <td className="align-right">{<CurrencyFormat value={Number(item.reapers_title_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={Number(item.reapers_title_freed).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={Number(item.reapers_title_runningbalance).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
  
                                  <td className="align-right">{<CurrencyFormat value={Number(item.other_collateral_paid).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={Number(item.other_collateral_freed).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
                                  <td className="align-right">{<CurrencyFormat value={Number(item.other_collateral_runningbalance).toFixed(2)} displayType={'text'} thousandSeparator={true}/>}</td>
  
                                  </tr>
                                ))}
                                <tr>
                                <td>Totals</td>
                                <td></td>
                                <td></td>
                                 <td></td>
{ /*                               <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_principle_paid_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
*/}                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_savings_paid_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_savings_freed_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td></td>
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_supersave_paid_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_supersave_freed_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td></td>
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_teebagoolo_paid_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_teebagoolo_freed_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td></td>
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_shares_paid_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_shares_freed_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td></td>
  
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_project_interest_paid_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_project_interest_freed_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td></td>
  
  
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_own_title_paid_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_own_title_freed_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
  
                                <td></td>
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_reapers_title_paid_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_reapers_title_freed_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
  
                                <td></td>
  
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_other_collateral_paid_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
                                <td className="align-right"style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculate_other_collateral_freed_sec()} displayType={'text'} thousandSeparator={true}/>}</td>
  
                                <td></td>
  
                                </tr>
                            </tbody>
                        </reactstrp.Table>
  
                        <br></br>
                        <div style={{display:'flex',justifyContent:'flex-end',alignSelf:'flex-end',alignItems:'flex-end'}}> 
                          <a>
                              <ReactToPrint
                                trigger={() =>                     
                                  <div style={{}}>
                                    <Tooltip title="Click to print" placement="top">
                                    <p style={{alignSelf:'center'}}><PrinterOutlined style={{ fontSize: '40px', color: '#08c' }}/></p>
                                    <h6 style={{alignSelf:'center'}}>Print securities tracker</h6>
                                    </Tooltip>
                                  </div>                          
                                    }
                                    content={() => this.componentRefthree}
                                  />  
                                  </a>
                          </div>
                          </div>
                         
                        </TabPane>

                        <TabPane tab="Certificate generation" key="0001" >
                        <LoanCertificateGenerationForm loan_issuance={record}/>

                        </TabPane>

                        </Tabs>

                      }

                    </div>
                    );
                }else{
                  return null;
                }
              } ,
              rowExpandable: (record) => record.id !== this.state.current_selected_loan.id,
              onExpand:(condition,record)=>{
                this.setState({loan_particular_data_loading:true})

                //set curent id
                this.setState({current_selected_loan:record.id})

                var loanissueID=record.id
                //on expans
                let form_data = new FormData();
                form_data.append('loan_id', record.id);

                //Now submit sale data to database
                axios.post(serverconfig.backendserverurl+'/customqueries/getloansecurities', form_data,{
                  headers: {
                    'content-type': 'multipart/form-data'
                  }})
                .then(res =>{
                  this.setState({loansecurites:JSON.parse(res.data.report)})
                  this.setState({securitesoverroleTotal:res.data.overroletotal})
                  this.setState({securitesoverroleTotal_percentage:res.data.percentagetotal})
                  
                }).catch(error => console.log(error)) 

                //get other data
                axios.get(`${serverconfig.backendserverurl}/api/loanissues/${loanissueID}`)
                .then(res => {  
                    this.setState({loanissueobject: res.data})
            
                    this.setState({newreschdate:res.data.date})
                    this.setState({newreschamt:res.data.approved_amount})
            
                })
            
                axios.get(`${serverconfig.backendserverurl}/api/loanschedules/?loanissue=${loanissueID}`)
                .then(res => {  
                    this.setState({loanschedule: res.data})
                })
            
                axios.get(`${serverconfig.backendserverurl}/api/loanrepayments/?loanissue=${loanissueID}`)
                .then(res => {  
                    this.setState({loanrepayments: res.data})
                })
            
                axios.get(`${serverconfig.backendserverurl}/api/loandailyscheduledate/?loanissue=${loanissueID}`)
                .then(res => {  
                    this.setState({loandailyschedules: res.data})
                })

                axios.get(`${serverconfig.backendserverurl}/api/loansecurity_freeing/?loan_issue=${loanissueID}`)
                .then(res => {  
                    this.setState({loansecurity_freeing: res.data})

                    this.setState({loan_particular_data_loading:false})

                })

              },
            }}
            scroll={{ x: 2000 }}
            dataSource={this.state.fullypaid_loans} 
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            bordered
            />
            <h3>Total Loans: {this.state.fullypaid_loans.length}</h3>

          </Panel>
        </Collapse>

    </TabPane>
   </Tabs>

    </div>

    )}

  }
}

export default LoanIssuanceList; 
