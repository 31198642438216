import React from 'react';
import LoanApplicationForm from '../components/LoanApplicationForm'
import GuarantorConsentForm from '../components/GuarantorConsentForm'
import MdiGuarantorForm from '../components/MdiGuarantorForm'

import axios from 'axios'
import { Table, Input,Dropdown,Menu,Button,Collapse,Popover,Popconfirm,message,Descriptions,Modal,Spin,Tooltip } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined,WalletFilled,LoadingOutlined,PlusCircleFilled,DownloadOutlined } from '@ant-design/icons';
import { Link, NavLink } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

function callback(key) {
  console.log(key);
}

var sacco= ''
var bizuserid= ''
var token= ''

class LoanApplicationsList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    loanapplications: [],
    viewpaymentmodal:false,
    expensedetails:{},
    userprofile:{},
    datarequested:true,
    guarantorconsentformvisible:false,
    selectedloanid:'',
    nextaction:'',
    mdiguarantorformvisible:false,
    userrights:{},

  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
       bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }
    

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/loanapplications/?sacco=${sacco}&assessed=${false}&passed=${false}&issued=${false}&approval_yet=${false}`)
    .then(res => {
        this.setState({
          loanapplications:res.data
        })


    })

    axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
    .then(res => {
        this.setState({
          userrights:res.data[0]
        })
    })

    let form_data = new FormData();
    form_data.append('userid', bizuserid);
    
    //get the user profile here
    axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          userprofile:res.data
        })
  
        this.setState({datarequested:false})
     
    })

}

guarantorsmenu =(val)=>{
  return(
    <Menu >
    <Menu.Item key="1" onClick={()=>{this.openmdiguarantorform(val)}}>
      <PlusCircleFilled style={{color:'blue'}}/> Sacco Guarantor
    </Menu.Item>
    <Menu.Item key="2">
      <NavLink to={`/loanguarantors/${val}`} >
      <FundViewOutlined style={{color:'blue'}}/> View Guarantors
      </NavLink>
    </Menu.Item>
    <Menu.Item key="4">
      <NavLink to={`/loanconsents/${val}`} >
      <FundViewOutlined style={{color:'blue'}}/> View Consents
      </NavLink>
    </Menu.Item>
  </Menu>
  );
} 


    //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
     let form_data1 = new FormData();
     form_data1.append('itemid', id);
     form_data1.append('sacco', sacco);
     form_data1.append('table_model', "LoanApplication");
 
     axios.post(`${serverconfig.backendserverurl}/customqueries/puttorecyclebin`, form_data1, {
       headers: {
         'content-type': 'multipart/form-data'
       }
       }).then(res =>{
         message.info("successfully deleted") 
         window.location.reload(false)

      }
      );
      
  };

//open guarantor form
  openguarantorform=id=>{
    this.setState({selectedloanid:id})
    this.setState({guarantorconsentformvisible:true})
    this.setState({nextaction:'addguarantor'})
  }

  //open consent form
  openconsentform=id=>{
    this.setState({selectedloanid:id})
    this.setState({guarantorconsentformvisible:true})
    this.setState({nextaction:'addconsent'})
  }  

  closeguatantorconsentform=()=>{
    this.setState({guarantorconsentformvisible:false})
  }

  //open mdi guarantor form
  openmdiguarantorform=(val)=>{
    this.setState({selectedloanid:val})
    this.setState({mdiguarantorformvisible:true})
  }

  closemdiguarantorform=()=>{
    this.setState({mdiguarantorformvisible:false})
  }



getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};

  render() {

      var columns = [

        {
          title: 'Date',
          dataIndex: 'date',
          key: 'id',
          ...this.getColumnSearchProps('date'),
          render:(text,record)=><h3 style={{color:record.assessment_application_reversalreason===null?'black':'orange'}}> {record.assessment_application_reversalreason!=""?<Tooltip title={record.assessment_application_reversalreason}> {text}</Tooltip>:text}  </h3>

        },

        {
          title: 'Account Number',
          dataIndex: 'accountNumber',
          key: 'id',
          ...this.getColumnSearchProps('accountNumber'),
        },
        {
          title: 'Account Name',
          dataIndex: 'accountName',
          key: 'id',
          ...this.getColumnSearchProps('accountName'),
        },
        {
          title: 'Loan top app',
          dataIndex: 'loan_topup',
          key: 'id',
          ...this.getColumnSearchProps('accountNumber'),
          render:(text,record)=><h3>{record.loan_topup?"Yes":"No"}</h3>
        },
        {
          title: 'Topup amount',
          dataIndex: 'loan_topupamount',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Topup loan balance',
          dataIndex: 'topup_loanbalance',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Total Request Amount',
          dataIndex: 'loan_amount',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },


        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: (text,record) =>
          <p>
          <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
           <Link to={`/loanapplications/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
          </Popover>

          &nbsp;&nbsp;&nbsp;
          <Popover content={<p>Click here to Assess Loan</p>} title="Assess">
            <Link to={`/makeassessment/${text}`}><FundViewOutlined style={{color:'green'}}/></Link>
          </Popover>

          &nbsp;&nbsp;

          {
          record.physical_form?
          <Popover content={<p>Click here to download physical form</p>} title="Download">
          <a href={record.physical_form}><DownloadOutlined style={{color:'green',fontSize: '20px'}}/></a> 
          </Popover>
          :
          null
          }


          &nbsp;&nbsp;&nbsp;
          {
            this.state.userprofile.branch_manager===true?
            <Popover content={<p>Click here to delete</p>} title="Delete">
            <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
              <DeleteOutlined style={{color:'red'}}/>
             </Popconfirm>
            </Popover>
            :
            null
          }




          </p>
          ,
        }
  
      ];
    
    
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header={<h4 style={{fontWeight:'bolder'}}>AVAILABLE LOAN APPLICATIONS</h4>} key="1">
            <Table   
            columns={columns} 
            scroll={{ x: 1000 }}
            dataSource={this.state.loanapplications} 
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            bordered/>
            <br />
            </Panel>
            </Collapse>
            <br></br>
            
            {
              this.state.userrights.can_register_loans?
              <Collapse defaultActiveKey={['0']} onChange={callback}>
              <Panel header="Collapse Panel to open Form" key="1">
                <h2>Create Application here</h2>
                <LoanApplicationForm 
                requestType="post"
                expenseID={null}
                btnText="Create"
                /> 
              </Panel>
              </Collapse>
              :
              null
            }

        </div>
    )

    }

  }
}

export default LoanApplicationsList; 
