import React,{ useRef } from 'react';
import StaffForm from '../components/StaffForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { SearchOutlined,CameraOutlined,PlusOutlined,LoadingOutlined,DownloadOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import * as serverconfig from '../serverconn'
import CurrencyFormat from 'react-currency-format';
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';
import ReactExport from "@ibrahimrahmani/react-export-excel";
import '../tablestyle.css'; // Create this CSS file for custom styles


var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const { Search } = Input;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


var token= ''
var sacco= ''
var bizuserid= ''

class ReportToPrint extends React.Component {

    state = {
      companyprofile:{},
    };
  
    componentDidMount(){
      if(localStorage.getItem("sacco")){
         sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
         bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
      }else{
         sacco= ''
         bizuserid= ''
      }
      
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }

      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

      axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
      .then(res => {  
          this.setState({
            companyprofile: res.data
          })
  
      })

  
    }


   
    render() {
      return (
        <div style={{padding:20}}>
           <style>
            {`@media print {
            @page { size: landscape; }
             }`}
          </style>
       <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>
                
                  </Col>
       </Row>

             <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>LOAN REPAYMENT STATUS REPORT AS OF {this.props.date} </h3>
            
             <reactstrp.Table bordered>
                        <thead>
                          <tr>
                           <th>Account Name</th>
                            <th>Account Number</th>
                            <th>Issuance Date</th>
                            <th>Loan Amount</th>
                            <th>Principle Due</th>
                            <th>Interest Due</th>
                            <th>Total Due</th>
                            <th>Principle paid</th>
                            <th>Interest Paid</th>
                            <th>Total Paid</th>

                            <th>Principle Balance</th>
                            <th>Interest Balance</th>
                            <th>Total Balance</th>
                            <th>OutStanding Balance</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.props.loanissues.map(
                          (item)=>(
                            <tr>
                            <td>{item.account_number}</td>
                            <td>{item.account_name}</td>
                            <td>{item.loan_date}</td>
                            <td className="align-right">{<CurrencyFormat value={item.total_loan_amount} displayType={'text'} thousandSeparator={true}/>}</td>
                           
                            <td className="align-right">{<CurrencyFormat value={item.principl_amount_due} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.interest_amount_due} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.total_amount_due} displayType={'text'} thousandSeparator={true}/>}</td>
                            
                            <td className="align-right">{<CurrencyFormat value={item.principle_amount_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.interest_amount_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.total_amount_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            
                            <td className="align-right">{<CurrencyFormat value={item.principle_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.interest_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.total_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.outstanding_balance} displayType={'text'} thousandSeparator={true}/>}</td>

                            </tr>
                          ))}
                          <tr>
                          <td style={{fontWeight:'bolder'}}>Totals</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalsaggregate.tot_principl_amount_due} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalsaggregate.tot_interest_amount_due} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalsaggregate.tot_total_amount_due} displayType={'text'} thousandSeparator={true}/>}</td>
                         
                          <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalsaggregate.tot_principle_amount_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalsaggregate.tot_interest_amount_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalsaggregate.tot_total_amount_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                         
                         
                          <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalsaggregate.tot_principle_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalsaggregate.tot_interest_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalsaggregate.tot_total_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalsaggregate.tot_outstanding_balance} displayType={'text'} thousandSeparator={true}/>}</td>

                          </tr>
                          
                      </tbody>
                  </reactstrp.Table> 
           
                  <br></br>
                <h3>Generated by: {CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)}</h3>
                <h3>Powered by: www.pitech.co.ug</h3>   

        </div>
      );
    }
  }


class LoanRepaymentStatusReport extends React.Component {

  state = {
    loanissues:[],
    date:moment().format(dateFormat).toString(),
    datarequested:true,
    totalsaggregate:{},
    loanissues_placeholder:[],

   };

   componentDidMount(){
    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   
   }else{
      sacco= ''
      bizuserid= ''
   }
   
   if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    let form_data = new FormData();
    form_data.append('sacco', sacco);
    //Now submit sale data to database
    axios.post(serverconfig.backendserverurl+'/customqueries/getLoanRepaymentStatusReport', form_data,{
    headers: {
      'content-type': 'multipart/form-data'
    }
    })
    .then(res =>{
      this.setState({datarequested:false})
      this.setState({loanissues:JSON.parse(res.data.loanissues)})
      this.setState({totalsaggregate:JSON.parse(res.data.loanissuesaggregate)})

      this.setState({loanissues_placeholder:JSON.parse(res.data.loanissues)})

    } 
    )
    .catch(error => console.log(error)) 

  }
 

   ////////////////////////////////////
  // USER SEARCH SELECT METHODS
  onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }
 

   ////////////////////////////////////////////
   // RENDERING METHOD HERE
   render() {
    const pageStyle = `
      @media print {
        @page { size: landscape; }
      }
    `;

       
       if(this.state.datarequested===true){
        return(
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin>             </div>
        )
  
      }else{

        return(
          <div>
            <div style={{display: "none",}}>
               &nbsp;&nbsp;
              <ReportToPrint
              date={this.state.date} 
              loanissues={this.state.loanissues} 
              totalsaggregate={this.state.totalsaggregate} 
              ref={el => (this.componentRef = el)} /> 
            </div>
  
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
              <Panel header="LOAN REPAYMENT STATUS REPORT" key="1">

              <div style={{display:'flex',justifyContent:'flex-start',alignSelf:'flex-start',alignItems:'flex-start'}}> 
              <ReactToPrint
                  trigger={() => 
                  <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
                  Print
                  </Button>
                  }
                  content={() => this.componentRef}
                  />

          &nbsp;&nbsp;&nbsp;
              <ExcelFile filename={"Pisys -Loan repayment status report by "+this.state.date}  element={<Button type='primary' icon={<DownloadOutlined/>}>Download loan repayment status report (Excel)</Button>}>
                <ExcelSheet data={this.state.loanissues} name="Loan repayment status report">
                    <ExcelColumn label="Account Name" value="account_name"/>
                    <ExcelColumn label="Account Number" value="account_number"/>
                    <ExcelColumn label="Issuance date" value="loan_date"/>
                    <ExcelColumn label="Loan Amount" value="total_loan_amount"/>
                    
                    <ExcelColumn label="Principle Due" value="principl_amount_due"/>
                    <ExcelColumn label="Interest Due" value="interest_amount_due"/>
                    <ExcelColumn label="Total Due" value="total_amount_due"/>

                    <ExcelColumn label="Principle Paid " value="principle_amount_paid"/>
                    <ExcelColumn label="Interest Paid " value="interest_amount_paid"/>
                    <ExcelColumn label="Total paid " value="total_amount_paid"/>

                    <ExcelColumn label="Principle Balance " value="principle_balance"/>
                    <ExcelColumn label="Interest Balance " value="interest_balance"/>
                    <ExcelColumn label="Total Balance " value="total_balance"/>

                    <ExcelColumn label="OutStanding Balance " value="outstanding_balance"/>

                </ExcelSheet>
               </ExcelFile>


               &nbsp;&nbsp;&nbsp;

              <Form layout="inline" >

              <FormItem label={"Status by ( "+this.state.date+" )"}>
                  <DatePicker onChange={(date, dateString) =>{
                  this.setState({ date: dateString});
                }} format={dateFormat} />
              </FormItem>

              <FormItem>
              <Button onClick={()=>{


              if(this.state.date===''){
              alert("Date one or two missing")
              }else{
              this.setState({datarequested:true})

              let form_data1 = new FormData();
              form_data1.append('sacco', sacco);
              form_data1.append('date', this.state.date);

              axios.post(serverconfig.backendserverurl+`/customqueries/getLoanRepaymentStatusReport_byperiod`, form_data1)
              .then(res => {
                this.setState({loanissues:JSON.parse(res.data.loanissues)})
                this.setState({totalsaggregate:JSON.parse(res.data.loanissuesaggregate)})

                this.setState({loanissues_placeholder:JSON.parse(res.data.loanissues)})

                this.setState({datarequested:false})

              })

              }

              }} type="primary" htmlType="button">Search</Button>
              </FormItem> 
              </Form>



              </div>
              <br></br>

           
              <div style={{overflowX:'auto'}}>

            <Search
            placeholder="Input Account number or Account name"
            onChange={(value) => {
              var val=value.target.value
              console.log(val)
              if (val !== '') {
                const results = this.state.loanissues.filter((request) => {
                  return request.account_name.toLowerCase().startsWith(val.toLowerCase());
                  // Use the toLowerCase() method to make it case-insensitive
                });
          
                if (results.length === 0) {
                  // this.setState({loanissues:this.state.loanissues_placeholder});

                  const results_2 = this.state.loanissues.filter((request) => {
                    return request.account_number.toLowerCase().startsWith(val.toLowerCase());
                    // Use the toLowerCase() method to make it case-insensitive
                  });

                  if (results_2.length === 0) {
                    this.setState({loanissues:this.state.loanissues_placeholder});

                  }else{
                    this.setState({loanissues:results_2});

                  }

                } else {
                  this.setState({loanissues:results});
                  console.log(results)
                }
          
              } else {
                this.setState({loanissues:this.state.loanissues_placeholder});
          
              }
          
            }}
            style={{
              width: 400,
            }}
            />
            <br></br>
            <br></br>


              <reactstrp.Table bordered>
                        <thead>
                          <tr>
                           <th>Account Name</th>
                            <th>Account Number</th>
                            <th>Issuance Date</th>
                            <th>Loan Amount</th>
                            <th>Principle Due</th>
                            <th>Interest Due</th>
                            <th>Total Due</th>
                            <th>Principle paid</th>
                            <th>Interest Paid</th>
                            <th>Total Paid</th>

                            <th>Principle Balance</th>
                            <th>Interest Balance</th>
                            <th>Total Balance</th>
                            <th>OutStanding Balance</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.loanissues.map(
                          (item)=>(
                            <tr>
                            <td>{item.account_number}</td>
                            <td>{item.account_name}</td>
                            <td>{item.loan_date}</td>
                            <td className="align-right">{<CurrencyFormat value={item.total_loan_amount} displayType={'text'} thousandSeparator={true}/>}</td>
                           
                            <td className="align-right">{<CurrencyFormat value={item.principl_amount_due} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.interest_amount_due} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.total_amount_due} displayType={'text'} thousandSeparator={true}/>}</td>
                            
                            <td className="align-right">{<CurrencyFormat value={item.principle_amount_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.interest_amount_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.total_amount_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            
                            <td className="align-right">{<CurrencyFormat value={item.principle_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.interest_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.total_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td className="align-right">{<CurrencyFormat value={item.outstanding_balance} displayType={'text'} thousandSeparator={true}/>}</td>

                            </tr>
                          ))}
                          <tr>
                          <td style={{fontWeight:'bolder'}}>Totals</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.totalsaggregate.tot_principl_amount_due} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.totalsaggregate.tot_interest_amount_due} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.totalsaggregate.tot_total_amount_due} displayType={'text'} thousandSeparator={true}/>}</td>
                         
                          <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.totalsaggregate.tot_principle_amount_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.totalsaggregate.tot_interest_amount_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.totalsaggregate.tot_total_amount_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                         
                         
                          <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.totalsaggregate.tot_principle_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.totalsaggregate.tot_interest_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.totalsaggregate.tot_total_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.totalsaggregate.tot_outstanding_balance} displayType={'text'} thousandSeparator={true}/>}</td>

                          </tr>
                          
                      </tbody>
                  </reactstrp.Table>      
              </div>
                   
              </Panel>
              </Collapse>
  
          </div>
      )        

      }
   }
}

export default LoanRepaymentStatusReport; 
