export const backendserverurl='https://4-be-reap-h4rd.pitech.co.ug'

//https://1-be-pifs01-mta-app1.pitech.co.ug
//127.0.0.1:8000
//138.197.8.179
//http://138.197.8.179:10226/
//172.17.220.11:10225

//https://4-be-reap-h4rd.pitech.co.ug/

//7-be-r3ap-4-r3al.pitech.co.ug


//4-be-reap-h4rd.pitech.co.ug  --cloud