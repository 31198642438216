import React from 'react';
import LoanApplicationForm from '../components/LoanApplicationForm'
import LoanAssessmentForm from '../components/LoanAssessmentForm'

import axios from 'axios'
import { Table, Input,Form, Button,Collapse,Popover,Tooltip,Popconfirm,message,Descriptions,Modal,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,CheckCircleFilled,RollbackOutlined,DownloadOutlined,FundViewOutlined,WalletFilled,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { TextArea } = Input;
const FormItem=Form.Item;

function callback(key) {
  console.log(key);
}


var token= ''
var sacco= ''
var bizuserid= ''

class LoanAssessmentList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    loanapplications: [],
    viewpaymentmodal:false,
    expensedetails:{},
    userprofile:{},
    datarequested:true,

    userrights:{},
    reversal_modalvisible:false,
    selected_aplication:{},
    reversal_reason:'',

    approval_remarks:'',
    approval_modalvisible:false,


  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/loanapplications/?sacco=${sacco}&assessed=${true}&passed=${false}&issued=${false}&approval_yet=${false}`)
    .then(res => {
        this.setState({
          loanapplications:res.data
        })

    })


    axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
    .then(res => {
        this.setState({
          userrights:res.data[0]
        })
        this.setState({datarequested:false})
    })
  

}


    //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    axios.delete(`${serverconfig.backendserverurl}/api/loanapplications/${id}`).then(res =>{
      window.location.reload(false)
      message.info("successfully deleted") 
     }
     );
   
  };


getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};

  render() {

      var columns = [
        {
          title: 'Application date',
          dataIndex: 'date',
          key: 'id',
          ...this.getColumnSearchProps('date'),
        },
        {
          title: 'Assessment date',
          dataIndex: 'date_of_assessment',
          key: 'id',
          ...this.getColumnSearchProps('date_of_assessment'),
        },
        {
          title: 'Account Number',
          dataIndex: 'accountNumber',
          key: 'id',
          ...this.getColumnSearchProps('accountNumber'),
          render:(text,record)=><h3 style={{color:record.approval_assessment_reversalreason===""?'black':'orange'}}> {record.approval_assessment_reversalreason!=""?<Tooltip title={record.approval_assessment_reversalreason}> {text}</Tooltip>:text} </h3>
        },
        {
          title: 'Account Name',
          dataIndex: 'accountName',
          key: 'id',
          ...this.getColumnSearchProps('accountName'),
        },
        {
          title: 'Loan top app',
          dataIndex: 'loan_topup',
          key: 'id',
          ...this.getColumnSearchProps('accountNumber'),
          render:(text,record)=><h3>{record.loan_topup?"Yes":"No"}</h3>
        },
        {
          title: 'Topup amount',
          dataIndex: 'loan_topupamount',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Topup loan balance',
          dataIndex: 'topup_loanbalance',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Total Request Amount',
          dataIndex: 'loan_amount',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Approvals',
          dataIndex: 'id',
          key: 'id',
          render: (text,record) =>
          <div>
            {
              record.loanapprovals===null?
              null
              :
              JSON.parse(record.loanapprovals).map((item)=>{
                return (<p>* {item.user} </p>)
              })
            }
          </div>
        },
       
        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: (text,record) =>
          <p>
          <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
           <Link to={`/loanapplications/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
          </Popover>
          &nbsp;&nbsp;

          {
          record.physical_form?
          <Popover content={<p>Click here to download physical form</p>} title="Download">
          <a href={record.physical_form}><DownloadOutlined style={{color:'green',fontSize: '20px'}}/></a> 
          </Popover>
          :
          null
          }



          &nbsp;&nbsp;&nbsp;

          {
              this.state.userrights.can_reverse_loan_process===true?
              <Popover content={<p>Click here to rollback to application </p>} title="Rollback">
                
              <Popconfirm title="Sure to rollback?" onConfirm={() => {
                  this.setState({selected_aplication:record})
                  this.setState({reversal_modalvisible:true})
                }}>

              <RollbackOutlined style={{color:'blue'}} />
              </Popconfirm>
            </Popover>
            :
            null
          }
           &nbsp;&nbsp;&nbsp;

           {
              this.state.userrights.can_approve_loans_partially===true?
              <Popover content={<p>Click here to partially approve loan </p>} title="Approve">
              <Popconfirm title="Sure to approve?" onConfirm={() => {
                var useralreay_aproved=false

                if (record.loanapprovals===null){
                  useralreay_aproved=false
                }else{
                  JSON.parse(record.loanapprovals).map((item)=>{
                    if(Number(item.user_id)===Number(bizuserid)){
                      useralreay_aproved=true

                    }else{
                      useralreay_aproved=false

                    }
                  })

                }

              if(useralreay_aproved===true ){
                message.error("You have already approved this loan")
              }else{
                this.setState({selected_aplication:record})
                this.setState({approval_modalvisible:true})
              }
                }}>

              <CheckCircleFilled style={{color:'green'}} />
              </Popconfirm>
            </Popover>
            :
            null
          }

          &nbsp;&nbsp;&nbsp;
          <Popover content={<p>Click here to conclude loan approval</p>} title="Approve">
            <Link to={`/approveloan/${text}`}><FundViewOutlined style={{color:'green'}}/></Link>
          </Popover>
          </p>,
        }
      ];
    
    
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header={<h4 style={{fontWeight:'bolder'}}>ASSESSED LOAN APPLICATIONS</h4>} key="1">
            <Table   
            columns={columns} 
            scroll={{ x: 1000 }}
            dataSource={this.state.loanapplications} 
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            bordered/>
            <br />
            </Panel>
            </Collapse>

            <Modal              
              visible={this.state.reversal_modalvisible}
              title={"Reversal reason Form"}
              onCancel={()=>{this.setState({reversal_modalvisible:false})}}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={()=>{this.setState({reversal_modalvisible:false})}}>
                  Cancel
                </Button>,
                <Button key="ok" type='primary' onClick={()=>{

                  let form_data = new FormData();
                  form_data.append('applicationid', this.state.selected_aplication.id);
                  form_data.append('reversal_reason', this.state.reversal_reason);

                  //Now submit sale data to database
                  axios.post(serverconfig.backendserverurl+'/customqueries/loan_rollback_toapplication', form_data,{
                    headers: {
                      'content-type': 'multipart/form-data'
                    }})
                  .then(res =>{
                    this.setState({datarequested:false})
                    window.location.reload(false)
                  }).catch(error => console.log(error)) 

                  this.setState({reversal_modalvisible:false})

                  }}>
                Submit
              </Button>,
                ]}
                >
                  <FormItem 
                  label={<h4 style={{fontWeight:'bold'}}>Reason for reversal to application stage.</h4>}>
                  <TextArea
                  placeholder="Reason for reversal"
                  autoSize={{ minRows: 2, maxRows: 8 }}
                  value={this.state.reversal_reason}
                  onChange={(val)=>{this.setState({reversal_reason:val.target.value})}}
                  />

                  </FormItem>

                
            </Modal> 


            <Modal              
              visible={this.state.approval_modalvisible}
              title={"Partial approval form"}
              onCancel={()=>{this.setState({approval_modalvisible:false})}}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={()=>{this.setState({approval_modalvisible:false})}}>
                  Cancel
                </Button>,
                <Button key="ok" type='primary' onClick={()=>{

                  let form_data = new FormData();
                  form_data.append('applicationid', this.state.selected_aplication.id);
                  form_data.append('approval_remarks', this.state.approval_remarks);
                  form_data.append('bizuserid', bizuserid);

                  //Now submit sale data to database
                  axios.post(serverconfig.backendserverurl+'/customqueries/loan_approval_byuser', form_data,{
                    headers: {
                      'content-type': 'multipart/form-data'
                    }})
                  .then(res =>{
                    this.setState({datarequested:false})
                    window.location.reload(false)
                  }).catch(error => console.log(error)) 

                  this.setState({approval_modalvisible:false})

                  }}>
                Submit
              </Button>,
                ]}
                >
                  <FormItem 
                  label={<h4 style={{fontWeight:'bold'}}>Approval remarks.</h4>}>
                  <TextArea
                  placeholder="Approval remarks"
                  autoSize={{ minRows: 2, maxRows: 8 }}
                  value={this.state.approval_remarks}
                  onChange={(val)=>{this.setState({approval_remarks:val.target.value})}}
                  />

                  </FormItem>
                
            </Modal> 

        </div>
    )


    }


    
  }
}

export default LoanAssessmentList; 
