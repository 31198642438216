import React from 'react';
import { Form, Input, Button,Select,DatePicker,Spin } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import moment from 'moment';
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined,MailOutlined } from '@ant-design/icons';
import Resizer from "react-image-file-resizer";

var CryptoJS = require("crypto-js");


const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var sacco= ''

class StaffForm extends React.Component {

  state = {
    staffroles:[],
    idtypes:[],
    sur_name:'' ,  
    middle_name:'',
    given_name:'',
    nationality:'',
    ID_type:'',
    ID_number:'',
    date_Of_Birth:'',
    Email_Address:'',
    P_O_Box:'',
    Area_Of_Residence:'',
    District:'',
    RegisteredPhoneNumber1:'',
    RegisteredPhoneNumber2:'',
    next_of_kin:'',
    Next_Of_Kin_Physical_Address:'',
    relationship:'',
    Photo:null,
    Date_Of_Joining:'',
    staff_role:'',
    date_of_appointment:'',
    status:'',
    staff_number:'',
    reference_one:'',
    Referee1PhoneNumber:'',
    datarequested:true,
    gender:'',
    ugdistricts:[],

  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/staffroles/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          staffroles:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/idtypes/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          idtypes:res.data
        })
    })

    //get the sacco package here
    axios.get(serverconfig.backendserverurl+`/api/ugdistricts/?sacco=${sacco}`)
    .then(res => {
      this.setState({
        ugdistricts:res.data
      })
        this.setState({datarequested:false})
    })
}


onBlur=()=> {
  console.log('blur');
}

 onFocus=()=>{
  console.log('focus');
}

onSearch=(val)=> {
  console.log('search:', val);
}


//imgae resizer
resizeFile = (file) =>
new Promise((resolve) => {
  Resizer.imageFileResizer(
    file,
    300,
    400,
    "JPEG",
    50,
    0,
    (uri) => {
      resolve(uri);
    },
    "base64"
  );
});

//convert back to file
 dataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
  return new Blob([ia], { type: mimeString });
};



  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})


    let form_data = new FormData();
    form_data.append('sur_name', this.state.sur_name);
    form_data.append('middle_name', this.state.middle_name);
    form_data.append('given_name', this.state.given_name);
    form_data.append('nationality', this.state.nationality);
    form_data.append('ID_type', this.state.ID_type);
    form_data.append('ID_number', this.state.ID_number);
    form_data.append('date_Of_Birth', this.state.date_Of_Birth);

    form_data.append('Email_Address', this.state.Email_Address);
    form_data.append('gender', this.state.gender);

    form_data.append('P_O_Box', this.state.P_O_Box);
    form_data.append('Area_Of_Residence', this.state.Area_Of_Residence);
    form_data.append('District', this.state.District);
    form_data.append('RegisteredPhoneNumber1', this.state.RegisteredPhoneNumber1);
    form_data.append('RegisteredPhoneNumber2', this.state.RegisteredPhoneNumber2);

    form_data.append('next_of_kin', this.state.next_of_kin);
    form_data.append('Next_Of_Kin_Physical_Address', this.state.Next_Of_Kin_Physical_Address);
    form_data.append('relationship', this.state.relationship);
    form_data.append('Date_Of_Joining', this.state.Date_Of_Joining);

    form_data.append('staff_role', this.state.staff_role);
    form_data.append('date_of_appointment', this.state.date_of_appointment);
    form_data.append('status', this.state.status);
    form_data.append('staff_number', this.state.staff_number);
    form_data.append('reference_one', this.state.reference_one);
    form_data.append('Referee1PhoneNumber', this.state.Referee1PhoneNumber);
    form_data.append('sacco', sacco);
    
    this.state.Photo==null?
    console.log("No profile file")
    :
    form_data.append('Photo', this.state.Photo, this.state.Photo?this.state.Photo.name:"");

      axios.post(serverconfig.backendserverurl+'/api/staffs/', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>    this.setState({datarequested:false})
,    window.location.reload(false)
    )
    .catch(error => console.log(error))

 

  }

  handlesur_nameChange= (e) => this.setState({ sur_name: e.target.value});
  handlemiddle_nameChange= (e) => this.setState({ middle_name: e.target.value});
  handlegiven_nameChange= (e) => this.setState({ given_name: e.target.value});
  handlenationalityChange= (e) => this.setState({ nationality: e.target.value});
  handleID_typeChange= (e) => this.setState({ ID_type: e});

  handleID_numberChange= (e) => this.setState({ ID_number: e.target.value});
  handledate_Of_BirthChange= (date, dateString) => this.setState({ date_Of_Birth: dateString});

  handleEmail_AddressChange= (e) => this.setState({ Email_Address: e.target.value});
  handleP_O_BoxChange= (e) => this.setState({ P_O_Box: e.target.value});
  handleArea_Of_ResidenceChange= (e) => this.setState({ Area_Of_Residence: e.target.value});
  handleDistrictChange= (e) => this.setState({ District: e.target.value});
  handleRegisteredPhoneNumber1Change= (e) => this.setState({ RegisteredPhoneNumber1: e});

  handleRegisteredPhoneNumber2Change= (e) => this.setState({ RegisteredPhoneNumber2: e});
  handlenext_of_kinChange= (e) => this.setState({ next_of_kin: e.target.value});
  handleNext_Of_Kin_Physical_AddressChange= (e) => this.setState({ Next_Of_Kin_Physical_Address: e.target.value});
  handlerelationshipChange= (e) => this.setState({ relationship: e.target.value});
  handlePhotoChange= async(e) =>{

    const file = e.target.files[0];
    //send to resizer
    const image = await this.resizeFile(file);
    const newFile = this.dataURIToBlob(image);
    this.setState({ Photo: newFile});
  } 
  

  handleDate_Of_JoiningChange= (date, dateString) => this.setState({ Date_Of_Joining: dateString});
  handlestaff_roleChange= (e) => this.setState({ staff_role: e});
  handledate_of_appointmentChange= (date, dateString) => this.setState({ date_of_appointment: dateString});

  handlestatusChange= (e) => this.setState({ status: e});
  handlestaff_numberChange= (e) => this.setState({ staff_number: e.target.value});
  handlereference_oneChange= (e) => this.setState({ reference_one: e.target.value});
  handleReferee1PhoneNumberChange= (e) => this.setState({ Referee1PhoneNumber: e});
  handlegenderChange= (e) => this.setState({ gender: e});



  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
          <Form
              onFinish={(event) => this.handleFormSubmit(event)}
          >
            <FormItem label="Sur Name"
                name="surname"
                rules={[
                  {
                    required: true,
                    message: 'Please input sur name',
                  },
                ]}          
            >
              <Input  placeholder="Put a name here." value={this.state.sur_name} onChange={this.handlesur_nameChange} />
            </FormItem>
  
            <FormItem label="Middle Name">
              <Input  placeholder="Put a name here." value={this.state.middle_name} onChange={this.handlemiddle_nameChange} />
            </FormItem>
  
            <FormItem label="Given Name"
                name="givenname"
                rules={[
                  {
                    required: true,
                    message: 'Please input given name',
                  },
  
                ]}           
            >
              <Input  placeholder="Put a name here." value={this.state.given_name} onChange={this.handlegiven_nameChange} />
            </FormItem>

            <FormItem label="Gender"
                  name="gender"
                  rules={[
                    {
                      required: true,
                      message: 'Please select',
                    },
                  ]}           
              
              >
                  <Select placeholder="Gender" style={{ width: 120 }}
                   value={this.state.gender} onChange={this.handlegenderChange}
                   showSearch
                   optionFilterProp="children"
                   onFocus={this.onFocus}
                   onBlur={this.onBlur}
                   onSearch={this.onSearch}     
                   
                   >
                        <Option value="Male">Male</Option>
                        <Option value="Female">Female</Option>
                  </Select>
              </FormItem>
  
            <FormItem label="Nationality"
                name="nationality"
                rules={[
                  {
                    required: true,
                    message: 'Please input nationality',
                  },
                ]}            
            >
              <Input  placeholder="Nationality." value={this.state.nationality} onChange={this.handlenationalityChange} />
            </FormItem>
  
  
            <FormItem label="ID Type"
                name="idtype"
                rules={[
                  {
                    required: true,
                    message: 'Please select id type',
                  },
                ]}           
            >
                <Select placeholder="Id Type" style={{  }} 
                value={this.state.ID_type} onChange={this.handleID_typeChange} 
                showSearch
                optionFilterProp="children"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}     
                
                
                >
                  {this.state.idtypes.map(
                    (type)=>(
                      <Option value={type.id}>{type.id_type_name}</Option>
                    ))}
                </Select>
            </FormItem>
  
            <FormItem label="ID Number">
              <Input  placeholder="ID Number." value={this.state.ID_number} onChange={this.handleID_numberChange} />
            </FormItem>
  
            <FormItem label="Date of Birth"
                name="dateofbirth"
                rules={[
                  {
                    required: true,
                    message: 'Please select date of birth',
                  },
                ]}           
            >
                  <DatePicker onChange={this.handledate_Of_BirthChange} format={dateFormat} />
            </FormItem>
  
  
            <FormItem label="Email Address"
                name="emailaddress"
                rules={[
                  {
                    required: false,
                    message: 'Please input mail',
                  },
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                ]}            
            
            >
            <Input  type="mail" 
                    prefix={<MailOutlined className="site-form-item-icon" />} 
                    placeholder="Email"
                    value={this.state.Email_Address} 
                    onChange={this.handleEmail_AddressChange} />
            </FormItem>
  
            <FormItem label="Box Number">
              <Input placeholder="box number." value={this.state.P_O_Box} onChange={this.handleP_O_BoxChange} />
            </FormItem>
  
            <FormItem label="Area of Residence"
                name="areaofresidence"
                rules={[
                  {
                    required: true,
                    message: 'Please enter residence',
                  },
                ]}           
            >
              <Input placeholder="Area of residence." value={this.state.Area_Of_Residence} onChange={this.handleArea_Of_ResidenceChange} />
            </FormItem>
  
            <FormItem label="District"
               name="district"
               rules={[
                 {
                   required: true,
                   message: 'Please enter district of residence',
                 },
               ]}           
            >
                    <Select
                     placeholder="Ug District"
                      style={{  }} 
                      value={this.state.District}
                       onChange={(val)=>{this.setState({District:val})}} 
                       
                       showSearch
                       optionFilterProp="children"
                       onFocus={this.onFocus}
                       onBlur={this.onBlur}
                       onSearch={this.onSearch}
                       >
                      {this.state.ugdistricts.map(
                        (dist)=>(
                          <Option value={dist.district_name}>{dist.district_name} | {dist.region}</Option>
                        ))}
                    </Select>            
                    </FormItem>
  
            <FormItem label="Phone One"                    
            >
            <PhoneInput
                  countrySelectProps={{ unicodeFlags: true }}
                  defaultCountry="UG"
                  placeholder="Enter phone number"
                  value={this.state.RegisteredPhoneNumber1} onChange={this.handleRegisteredPhoneNumber1Change}/>
             </FormItem> 
  
             <FormItem label="Phone Two">
             <PhoneInput
                  countrySelectProps={{ unicodeFlags: true }}
                  defaultCountry="UG"
                  placeholder="Enter phone number"
                  value={this.state.RegisteredPhoneNumber2} onChange={this.handleRegisteredPhoneNumber2Change}/>
             </FormItem> 
  
  
            <FormItem label="Next of kin">
              <Input placeholder="Next of kin." value={this.state.next_of_kin} onChange={this.handlenext_of_kinChange} />
            </FormItem>
  
            <FormItem label="Next of kin Address">
              <Input placeholder="Next of Kin Address." value={this.state.Next_Of_Kin_Physical_Address} onChange={this.handleNext_Of_Kin_Physical_AddressChange} />
            </FormItem>
  
            <FormItem label="Relationship">
              <Input placeholder="Relationship." value={this.state.relationship} onChange={this.handlerelationshipChange} />
            </FormItem>
  
            <FormItem label="Staff Photo">
                  <Input type="file" accept="image/png, image/jpeg" placeholder="Staff Photo"  onChange={this.handlePhotoChange}/>
            </FormItem> 
  
            <FormItem label="Date of Joining">
                  <DatePicker onChange={this.handleDate_Of_JoiningChange} format={dateFormat}/>
            </FormItem>
  
  
            <FormItem label="Staff Role"
               name="staffrole"
               rules={[
                 {
                   required: true,
                   message: 'Please enter staff role',
                 },
               ]}           
            >
                <Select placeholder="Staff Role"
                 style={{  }} value={this.state.staff_role} 
                 onChange={this.handlestaff_roleChange}
                 showSearch
                 optionFilterProp="children"
                 onFocus={this.onFocus}
                 onBlur={this.onBlur}
                 onSearch={this.onSearch}     
                 
                 >
                  {this.state.staffroles.map(
                    (stf)=>(
                      <Option value={stf.id}>{stf.role_name}</Option>
                    ))}
                </Select>
            </FormItem>
            
  
            <FormItem label="Date of Appointment">
                  <DatePicker onChange={this.handledate_of_appointmentChange} format={dateFormat}/>
            </FormItem>
            
  
            <FormItem label="Staff Status"
               name="staffstatus"
               rules={[
                 {
                   required: true,
                   message: 'Please select staff status',
                 },
               ]}           
            
            >
                <Select placeholder="Status" style={{ width: 120 }}
                 value={this.state.status} onChange={this.handlestatusChange}
                 showSearch
                 optionFilterProp="children"
                 onFocus={this.onFocus}
                 onBlur={this.onBlur}
                 onSearch={this.onSearch}     
                 
                 >
                      <Option value="Active">Active</Option>
                      <Option value="Inactive">Inactive</Option>
                </Select>
            </FormItem>
  
  
            <FormItem label="Staff Number">
              <Input placeholder="Staff Number." value={this.state.staff_number} onChange={this.handlestaff_numberChange} />
            </FormItem>
  
            <FormItem label="Job Reference Personel">
              <Input placeholder="Reference Name." value={this.state.reference_one} onChange={this.handlereference_oneChange} />
            </FormItem>
  
            <FormItem label="Reference Phone No.">
              <PhoneInput
                    countrySelectProps={{ unicodeFlags: true }}
                    defaultCountry="UG"
                    placeholder="Enter Refree Tel"
                    value={this.state.Referee1PhoneNumber} onChange={this.handleReferee1PhoneNumberChange}/>
            </FormItem>
  
  
            <FormItem>
              <Button  type="primary" htmlType="submit">{this.props.btnText}</Button>
            </FormItem>
          </Form>
        </div>
      );

    }
    
  }

}

export default StaffForm;



