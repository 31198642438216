import React from 'react';
import { Form, Input, Button,Select,DatePicker,Spin,Image,Tooltip, Card,Divider,Result, message } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import moment from 'moment';
import * as serverconfig from '../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';
import { UserOutlined, LockOutlined, LoadingOutlined,MailOutlined,PrinterOutlined } from '@ant-design/icons';
import ReactToPrint from 'react-to-print';
import * as reactstrp from 'reactstrap';
import CurrencyFormat from 'react-currency-format';

var CryptoJS = require("crypto-js");


const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;


var token= ''
var sacco= ''



class ReceiptToPrint extends React.Component {

  state = {
    companyprofile:{}
  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

  

  }


  render() {
    return (
      <div style={{padding:20}}>
   
      <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>

                  </Col>

              </Row>
                  <h3 style={{fontWeight:'bolder',display: 'flex',justifyContent:'center', alignItems:'center'}}> LOAN DISBURSEMENT CERTIFICATE </h3>
                
                  <reactstrp.Table bordered>
            <thead>

            <tr>
                <th>1. Member details</th>
                <th>Member names {this.props.application_obj.accountName} , Account number {this.props.application_obj.accountNumber} </th>
              </tr>

              <tr>
                <th>2. Application</th>
                <th>Application entry by {this.props.application_obj.user} on {this.props.application_obj.date} for amount {<CurrencyFormat value={this.props.application_obj.loan_amount} displayType={'text'} thousandSeparator={true}/>}</th>
              </tr>
              <tr>
                <th>3. Assessment</th>
                <th>Assessment made by {this.props.assessmentobj.user} on {this.props.assessmentobj.date}</th>
              </tr>

              <tr>
                <th>4. Committee Approval </th>
                <th>
                  Approvals made by: 
                  {
                    this.props.application_obj.loanapprovals!=undefined?
                    JSON.parse(this.props.application_obj.loanapprovals).map((item)=>{
                      return (item.date!=undefined?item.user+" on "+item.date+",":item.user+",");
                    })
                    :
                    null
                  }
                  
                  </th>
              </tr>

              <tr>
                <th>5. Final system approval</th>
                <th>Final system approval  by {this.props.approvalobj.user} on {this.props.approvalobj.date} for amount approved {<CurrencyFormat value={this.props.approvalobj.approved_amount} displayType={'text'} thousandSeparator={true}/>}</th>
              </tr>

              <tr>
                <th>6. Disbursement </th>
                <th>
                  Disbursement made by: 
                  {this.props.loan_issuance.user} on {this.props.loan_issuance.date} for amount disbursed {<CurrencyFormat value={this.props.loan_issuance.approved_amount} displayType={'text'} thousandSeparator={true}/>}
                  
                  </th>
              </tr>

              <tr>
                <th>7. Loan number/ certificate no </th>
                <th>
                  Loan number: 
                  {this.props.loan_issuance.user} on {this.props.loan_issuance.loan_number} 
                  
                  </th>
              </tr>


            </thead>
                      
          </reactstrp.Table>

    </div>
    );
  }
}



class LoanCertificateGenerationForm extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    saccoboject:{},
    application_obj:{},
    assessmentobj:{},
    approvalobj:{},
  }

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    //get all the data

    axios.get(serverconfig.backendserverurl+`/api/loanapplications/${this.props.loan_issuance.loan}`)
    .then(res => {
      this.setState({application_obj:res.data})

    })

      //get assessent and appoval dates
      axios.get(`${serverconfig.backendserverurl}/api/loanassessments/?loan_assessed=${this.props.loan_issuance.loan}`)
      .then(res => {  
          this.setState({assessmentobj: res.data[0]})
      })


      //get assessent and appoval dates
      axios.get(`${serverconfig.backendserverurl}/api/loanapprovals/?loan_id=${this.props.loan_issuance.loan}`)
      .then(res => {  
          this.setState({approvalobj: res.data[0]})
      })
  

    axios.get(serverconfig.backendserverurl+`/api/saccos/${sacco}`)
    .then(res => {
        this.setState({
          saccoboject:res.data
        })
        this.setState({datarequested:false})

    })

}



  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      return (
        <div>
    <div style={{display: "none"}}>
      &nbsp;&nbsp;
      <ReceiptToPrint
      application_obj={this.state.application_obj} 
      assessmentobj={this.state.assessmentobj}
      approvalobj={this.state.approvalobj}
      loan_issuance={this.props.loan_issuance}

      ref={el => (this.componentRef = el)} /> 
      </div>


          <h3>Loan disbursement certificate</h3>
          <reactstrp.Table bordered>
            <thead>

            <tr>
                <th>1. Member details</th>
                <th>Member names {this.state.application_obj.accountName} , Account number {this.state.application_obj.accountNumber} </th>
              </tr>

              <tr>
                <th>2. Application</th>
                <th>Application entry by {this.state.application_obj.user} on {this.state.application_obj.date} for amount {<CurrencyFormat value={this.state.application_obj.loan_amount} displayType={'text'} thousandSeparator={true}/>}</th>
              </tr>
              <tr>
                <th>3. Assessment</th>
                <th>Assessment made by {this.state.assessmentobj.user} on {this.state.assessmentobj.date}</th>
              </tr>

              <tr>
                <th>4. Committee Approval </th>
                <th>
                  Approvals made by: 
                  {
                    this.state.application_obj.loanapprovals!=undefined?
                    JSON.parse(this.state.application_obj.loanapprovals).map((item)=>{
                      return (item.date!=undefined?item.user+" on "+item.date+",":item.user+",");
                    })
                    :
                    null
                  }
                  
                  </th>
              </tr>

              <tr>
                <th>5. Final system approval</th>
                <th>Final system approval  by {this.state.approvalobj.user} on {this.state.approvalobj.date} for amount approved {<CurrencyFormat value={this.state.approvalobj.approved_amount} displayType={'text'} thousandSeparator={true}/>}</th>
              </tr>

              <tr>
                <th>6. Disbursement </th>
                <th>
                  Disbursement made by: 
                  {this.props.loan_issuance.user} on {this.props.loan_issuance.date} for amount disbursed {<CurrencyFormat value={this.props.loan_issuance.approved_amount} displayType={'text'} thousandSeparator={true}/>}
                  
                  </th>
              </tr>

              <tr>
                <th>7. Loan number/ certificate no </th>
                <th>
                  Loan number: 
                  {this.props.loan_issuance.user} on {this.props.loan_issuance.loan_number} 
                  
                  </th>
              </tr>


            </thead>
                      
          </reactstrp.Table>
          <br></br>
          
          <div style={{display:'flex',justifyContent:'flex-start',alignSelf:'flex-start',alignItems:'flex-start'}}>
      
           <a>
          <ReactToPrint
            trigger={() =>                     
              <div style={{}}>
                <Tooltip title="Click to print" placement="top">
                <p style={{alignSelf:'center'}}><PrinterOutlined style={{ fontSize: '40px', color: '#08c' }}/></p>
                <h6 style={{alignSelf:'center'}}>Print Certificate</h6>
                </Tooltip>
              </div>                          
                }
                content={() => this.componentRef}
              />  
              </a>
          </div>



        </div>
      );

    }
    
  }

}

export default LoanCertificateGenerationForm;



