import React,{ useRef } from 'react';
import StaffForm from '../components/StaffForm'
import axios from 'axios'
import { Tooltip,Popover,Result,Table,Popconfirm,Input,Tabs, Button,Collapse,Card,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin,Switch } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined,SettingFilled, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { DeleteOutlined,SearchOutlined,CameraOutlined,PlusOutlined,EditOutlined,PlusSquareFilled,SaveOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';
import { ToWords } from 'to-words';
import JournalEntryView from './JournalEntryView'
var converter = require('number-to-words');


var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const toWords = new ToWords();
const { TabPane } = Tabs;

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});


var token= ''
var sacco= ''
var username=''
var bizuserid= ''


class ReceiptToPrint extends React.Component {

  state = {
    companyprofile:{}
  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
    })
  }


  render() {
    return (
      <div style={{padding:20}}>
        {
          this.props.thermalprint===true?
          <div>
          <p>*********************************************************</p>

          <h1 style={{fontWeight:'bolder',display:'flex',alignSelf:'flex-start'}}>  {this.state.companyprofile.sacco_name}</h1>
          <h2 style={{color:'black'}}>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
          {
          this.state.companyprofile.RegisteredPhoneNumber2==""?
          "":","+this.state.companyprofile.RegisteredPhoneNumber2
          }

          </h2>               
          <h2 style={{color:'black'}}>  Email: {this.state.companyprofile.company_email}</h2>
          <h2 style={{color:'black'}}>  Wesite: {this.state.companyprofile.website}</h2>
          <h2 style={{color:'black'}}>  {this.state.companyprofile.box_number}</h2>
          <h2 style={{color:'black'}}>  SLIP/RECEIPT NO: {this.props.voucher_number}</h2>
          <h2 style={{color:'black'}}>  DATE: {this.props.date}</h2>

          <p>*********************************************************</p>

          <h1 style={{fontWeight:'bolder',display:'flex',alignSelf:'flex-start'}}>  DEPOSIT RECEIPT</h1>

        </div>

          :

            <div>
            <Row> 
            <Col xs="2" sm="2" lg="2">
            <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />

              </Col>
              <Col xs="6" sm="6" lg="6">
              <h3>{this.state.companyprofile.sacco_name}</h3>
              <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
              {
              this.state.companyprofile.RegisteredPhoneNumber2==""?
              "":","+this.state.companyprofile.RegisteredPhoneNumber2
              }
        
              </h6>               
              <h5>Email: {this.state.companyprofile.company_email}</h5>
              <h5>Wesite: {this.state.companyprofile.website}</h5>
              <h5>{this.state.companyprofile.box_number}</h5>

              </Col>
            </Row>
            <h3 style={{fontWeight:'bolder',display:'flex',alignSelf:'center',alignContent:'center',justifyContent:'center'}}>DEPOSIT RECEIPT</h3>

            </div>
        }


        {
          this.props.thermalprint===true?
          <div>
               <p>********************************</p>

            <h3>
                ACC NUMBER: 
              {this.props.account.AccountNumber}
            </h3>

            <h3>
                ACC NAME :
              {this.props.account.FullName}
            </h3>

            <h3>
                DEPOSITED AMOUNT:
              <CurrencyFormat  value={this.props.totalamount} displayType={'text'} thousandSeparator={','} />
            </h3>

            <h3>
                In Words: 
              ({ this.props.amount_inwords})
            </h3>


            <h3 >
                PAID IN BY: 
              {this.props.paidinby}
            </h3> 

            <h3 >
                CONTACT:
              {this.props.transactorcontact}
            </h3> 

            <h3 >
                TELLER:  
              {username}
            </h3>                 

            <h3 >
                PAYMENT OF: 
              {this.props.reason}
            </h3> 

            <p>********************************</p>

            <h4>  Supported by PitechUgLtd ( www.pitech.co.ug )</h4>
          </div>
          :


        <reactstrp.Table bordered>
          <thead>
            <tr>
            <th>  ACC NUMBER</th>
              <th>{this.props.account.AccountNumber}</th>
            </tr>

            <tr>
              <th>ACC NAME</th>
              <th>{this.props.account.FullName}</th>
            </tr>

            <tr>
              <th>DEPOSITED AMOUNT</th>
              <th><CurrencyFormat  value={this.props.totalamount} displayType={'text'} thousandSeparator={true} /></th>
            </tr>

            <tr>
              <th>In Words   </th>
              <th>({ this.props.amount_inwords})</th>
            </tr>


            <tr >
              <th >PAID IN BY</th>
              <th >{this.props.paidinby}</th>
            </tr> 

            <tr >
              <th >SLIP NUMBER</th>
              <th >{this.props.voucher_number}</th>
            </tr> 

            <tr >
              <th >CONTACT</th>
              <th >{this.props.transactorcontact}</th>
            </tr> 

            <tr >
              <th >TELLER</th>
              <th >{username}</th>
            </tr>                 

            <tr >
              <th >PAYMENT OF</th>
              <th >{this.props.reason}</th>
            </tr> 

          </thead>
        </reactstrp.Table> 
      }
          
    </div>
    );
  }
}


class AccountDepositView extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    selectedaccount:'',
    selectedaccountobject:{},
    members:[],
    datarequested:true,
    totalamount:0,
    paidinby:'',
    transactorcontact:'',
    previoustransactions:[],
    datasubmittedsuccessfully:false,
    companyprofile:{},
    accountchange:false,
    userprofile:{},
    transaction_details:'',
    voucher_number:'',

    savingsamount:0,
    defsavings:0,
    supersavings:0,
    teebagooloamount:0,
    shares:0,
    companyaccounts: [],
    debitaccount:'',

    loan_amount:0,
    defsettings:{},
    defsett_modalvisible:false,
    noofinstallments:0,
    amountperinstallment:0,
    startingdate:'',

    availableloans:[],
    selectedloan:'',
    thermalprint:false,

    supersave_profile:{},
    teebagoolo_profile:{},
    journal_details:'',
    journal_list:[],
    itemnumber:0,
    transaction_type:'',
    interest_tobepaid:0,

    current_loanproperties:{}

  }
  

  callback(key) {
    console.log(key);
  }





  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    let form_data1 = new FormData();
    form_data1.append('userid', bizuserid);
    
    //get the user profile here
    axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data1,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          userprofile:res.data
        })
    })


    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/members_withaccounts/?sacco=${sacco}`)
    .then(res => {

      //console.log(res.data)
      this.setState({
        members:res.data
      })

    })

    axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
            companyaccounts:res.data
        })
        this.setState({datarequested:false})
    })

  }



  //calculate total debit and credit
  calculateTotalDebit=()=>{
    var total =0

    this.state.journal_list.map((item)=>{

      if (item.transaction_type==="Debit"){
        total+=Number(item.transaction_amount)
      }

    })

    return total;
  }

  calculateTotalCredit=()=>{
    var total =0

    this.state.journal_list.map((item)=>{

      if (item.transaction_type==="Credit"){
        total+=Number(item.transaction_amount)
      }

    })

    return total;
  }


  
  ////////////////////////////////////
  // PRODUCT SEARCH SELECT METHODS
   onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }

  //handle client change
  handleselectedaccountChange= (e) =>{
    this.setState({accountchange:true})
    this.setState({ selectedaccount: e});
    //make query for last transactions
    axios.get(`${serverconfig.backendserverurl}/api/members/${e}`)
      .then(res => {  
          this.setState({selectedaccountobject: res.data})
          this.setState({accountchange:false})
      })

      /*
      axios.get(`${serverconfig.backendserverurl}/api/loanissues/?account=${e}`)
      .then(res => {  
          this.setState({availableloans: res.data})
      })*/

      axios.get(serverconfig.backendserverurl+`/customqueries/get_memberactive_loans/${e}`)
      .then(res => {
          this.setState({
            availableloans:JSON.parse(res.data.report)
          })
      })

      //get teebagoolo and supersave profiles
      axios.get(`${serverconfig.backendserverurl}/api/supersaveprofile/?member_account=${e}`)
      .then(res => {  
          this.setState({supersave_profile: res.data})
      })

      axios.get(`${serverconfig.backendserverurl}/api/teebagooloprofile/?member_account=${e}`)
      .then(res => {  
          this.setState({teebagoolo_profile: res.data})
      })

  }



  handlepaidinbyChange= (e) => this.setState({ paidinby: e.target.value});
  handletransactorcontactChange= (e) => this.setState({ transactorcontact: e});

  calculatesharesamount=()=>{
    var tot=0
    tot=Number(this.state.shares)*Number(this.state.companyprofile.sharevalue)
    return tot;
  }

  calculatetotalamount=()=>{
    var tot=0
    tot=this.calculatesharesamount()+Number(this.state.savingsamount)+Number(this.state.defsavings)+Number(this.state.teebagooloamount)+Number(this.state.supersavings)+Number(this.state.loan_amount)
    return tot;
  }

  calculatesharespercentage=()=>{
    var shre=0
    shre=(Number(this.state.selectedaccountobject.shareamount_balance)/Number(this.state.companyprofile.sharecapital))*100
    return shre;
  }

  //check date of availability
  checkMembervalidity=(dateofentry)=>{

    var agelimit_months_cap=180

     var dateMomentObjectone = moment(dateofentry, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
     var dobobject = dateMomentObjectone.toDate();

     var datetoday=moment(this.state.date, "DD/MM/YYYY")

     var date1 = datetoday.toDate();
     var date2 = dobobject;

     var Difference_In_Time =  date1.getTime()-date2.getTime();
     var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

     if(Number(Difference_In_Days)>=Number(agelimit_months_cap)){
       return true;
     }else{
       return false;
     }
 }
 

  //check empty objecty
  isEmptyObject=(obj)=>{
    for ( var name in obj ) {
      return false;
    }
    return true;
  }


  //submit the deposit now
  handleFormSubmit=(event) =>{
    
    if(Number(this.state.savingsamount)<Number(this.state.selectedaccountobject.minimum_account_deposit) && Number(this.state.savingsamount)>0){
      message.info("Minimum account deposit is: "+this.state.selectedaccountobject.minimum_account_deposit)
    }else if(Number(this.state.loan_amount)>0 && this.state.selectedloan===""){
      message.info("Please select loan for repayment")
    }else if(Number(this.state.defsavings)>0 && Object.keys(this.state.defsettings).length<=0){
      message.info("Please create def settings")
    }else if(this.state.selectedaccount===""){
      message.info("Please select member account")
    }else if (this.state.debitaccount===""){
      message.error("Please select debit account")
    }
    
    else{
      this.setState({datarequested:true})
      console.log(username)

      let form_data = new FormData();
      form_data.append('date',this.state.date);
      form_data.append('paidinby', this.state.paidinby);
      form_data.append('savingsamount', this.state.savingsamount);
      form_data.append('defsavings', this.state.defsavings);
      form_data.append('supersavings', this.state.supersavings);
      form_data.append('teebagooloamount', this.state.teebagooloamount);
      form_data.append('shares', this.state.shares);
      form_data.append('loan_amount', this.state.loan_amount);

      form_data.append('transactorcontact', this.state.transactorcontact);
      form_data.append('sacco', sacco);
      form_data.append('account', this.state.selectedaccountobject.id);
      form_data.append('username', username);
      form_data.append('userid', bizuserid);
      form_data.append('transaction_details', this.state.transaction_details);
      form_data.append('voucher_number', this.state.voucher_number);
      form_data.append('debitaccount', this.state.debitaccount);
      form_data.append('journaltotal', this.calculatetotalamount());

      form_data.append('defsettings', JSON.stringify(this.state.defsettings));
      form_data.append('selectedloan', this.state.selectedloan);
      form_data.append('journal_list', JSON.stringify(this.state.journal_list));

      axios.post(serverconfig.backendserverurl+'/customqueries/createaccountdeposit', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
      .then(res => {
        this.setState({voucher_number:res.data.journalno})
        this.setState({datarequested:false})
        message.info(res.data.message)
        if (res.data.message==="success"){
          this.setState({datasubmittedsuccessfully:true})
        }
      })
      .catch(error => console.log(error))
    }
    
  }
  
  //generate paymnt reason
  generate_reason=()=>{
    var reason=''

    if (this.state.savingsamount>0){
      reason+="savings "
    }

    if (this.state.supersavings>0){
      reason+=", supersave "
    }

    if (this.state.teebagooloamount>0){
      reason+=", teebagoolo "
    }

    if (this.state.shares>0){
      reason+=", shares "
    }

    if (this.state.loan_amount>0){
      reason+=", loan "
    }

    if (this.state.defsavings>0){
      reason+=", defered . "
    }

    return reason;

  }


  //convert to wored
  convert_towords=(number)=> {
    const units = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
    const teens = ["", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
    const tens = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
    const thousands = ["", "Thousand", "Million", "Billion", "Trillion"]; // Add more as needed
  
    function convertLessThanThousand(num) {
      if (num === 0) return "";
      if (num < 10) return units[num];
      if (num < 20) return teens[num - 10];
      const ten = Math.floor(num / 10);
      const one = num % 10;
      return `${tens[ten]} ${units[one]}`.trim();
    }
  
    function convert(num, index) {
      if (num === 0) return "";
      if (num < 1000) return `${convertLessThanThousand(num)} ${thousands[index]}`.trim();
      const hundred = Math.floor(num / 100);
      const remainder = num % 100;
      return `${units[hundred]} Hundred ${convertLessThanThousand(remainder)} ${thousands[index]}`.trim();
    }
  
    if (number === 0) return "Zero";
  
    let result = "";
    let index = 0;
    while (number > 0) {
      const chunk = number % 1000;
      if (chunk !== 0) {
        result = `${convert(chunk, index)} ${result}`;
      }
      number = Math.floor(number / 1000);
      index++;
    }
  
    return result.trim();
  }


  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>
            <div style={{display: "none"}}>
            &nbsp;&nbsp;
            <ReceiptToPrint
            account={this.state.selectedaccountobject} 
            totalamount={this.calculatetotalamount()} 
            amount_inwords={converter.toWords(this.calculatetotalamount())}
            voucher_number={this.state.voucher_number} 
            paidinby={this.state.paidinby} 
            transactorcontact={this.state.transactorcontact}
            date={this.state.date}
            reason={this.generate_reason()}
            thermalprint={this.state.thermalprint}

            ref={el => (this.componentRef = el)} /> 
            </div>

            <Row >
                  <Col xs="12" sm="6" lg="6">
                    <Card>
                    <Result
                    status="success"
                    title="Successfully Deposited "
                    subTitle="Please print receipt or finish off transaction"
                    extra={[
                      <a>
                      <ReactToPrint
                        trigger={() =>                     
                          <div style={{}}>
                            <Tooltip title="Click to print" placement="top">
                            <p ><PrinterOutlined style={{ fontSize: '40px', color: '#08c' }}/></p>
                            <h6 >Print Receipt</h6>
                            </Tooltip>
                          </div>                          
                            }
                            content={() => this.componentRef}
                          />  
                          </a>,
                      <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
                    ]}
                    />

                  </Card>
                  </Col>
            </Row>
          </div>

        )

      }else{
        return(
          <div>
            <div>

            <Tabs defaultActiveKey="1" onChange={()=>{}}>

        <TabPane tab="Member deposit form" key="1">
        <Row >
                          <Col xs="12" sm="6" lg="7">
                            <Card>


                            <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>Account To Deposit to</h4>
                            <FormItem label="Account">
                                <Select 
                                placeholder="Account No | Name" 
                                style={{  }} 
                                value={this.state.selectedaccount}
                                onChange={this.handleselectedaccountChange} 
                                showSearch
                                optionFilterProp="children"
                                onFocus={this.onFocus}
                                onBlur={this.onBlur}
                                onSearch={this.onSearch}                    
                                >
                                  {this.state.members.map(
                                    (accnt)=>(
                                      <Option value={accnt.id}>{accnt.AccountNumber} | {accnt.FullName}</Option>
                                    ))}
                                </Select>
                            </FormItem>
                            {
                              this.state.accountchange?
                              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
                              <Spin indicator={antIcon} />
                              </div>
                              :
                              null
                            }

                        <h3 style={{fontWeight:'bold',alignSelf:'center',color:'#154360'}}> DEPOSIT FORM</h3>
                          <Form
                              onFinish={(event) => this.handleFormSubmit(event)}
                          >

                          {
                            this.state.companyprofile.automated_transaction_date===false?
                            <FormItem label="Date"
                            name='date'
                            rules={[
                              {
                                required: true,
                                message: 'Please Select date',
                              },
                              ]}
                            >
                                <DatePicker onChange={(date, dateString) => this.setState({ date: dateString})} format={dateFormat} />
                            </FormItem>                 
                            :
                            null
                          }



                          <FormItem label={<h6 style={{fontWeight:'bold'}}>Savings Amount </h6>} >
                              <Input name="savingsamount"   placeholder="0.0" value={this.state.savingsamount} onChange={(e) => this.setState({ savingsamount: e.target.value})}/>
                          </FormItem>


                          {
                            this.calculatesharespercentage()>=Number(this.state.selectedaccountobject.maximumshare_percentage)?
                            <h6 style={{display:'flex',color:'red'}}>  Reached Shares limit of  ({this.state.selectedaccountobject.maximumshare_percentage} %)</h6>
                            :
                            <div style={{display:'flex',flexDirection:'row'}}>
                            <FormItem label={<h6 style={{fontWeight:'bold'}}>Shares </h6>}

                            >
                                <Input name="shares"   placeholder="0" value={this.state.shares} onChange={(e) => this.setState({ shares: e.target.value})}/>
                            </FormItem>
          
                            <h3>&nbsp;&nbsp;<CurrencyFormat  value={this.calculatesharesamount()} displayType={'text'} thousandSeparator={true} /></h3>
                            </div>
                          }

                        {
                          this.checkMembervalidity(this.state.selectedaccountobject.DateOfJoining)===true && this.isEmptyObject(this.state.supersave_profile)===false ?
                          <FormItem label={<h6 style={{fontWeight:'bold'}}>Super Save </h6>}
                          >
                              <Input name="supersavings"   placeholder="0.0" value={this.state.supersavings} onChange={(e) => this.setState({ supersavings: e.target.value})}/>
                          </FormItem>

                          :
                          null
                        }


                        {
                          this.checkMembervalidity(this.state.selectedaccountobject.DateOfJoining)===true && this.isEmptyObject(this.state.teebagoolo_profile)===false?

                          <FormItem label={<h6 style={{fontWeight:'bold'}}>Teeba Goolo Amount </h6>}
                          >
                              <Input name="teebagooloamount"   placeholder="0.0" value={this.state.teebagooloamount} onChange={(e) => this.setState({ teebagooloamount: e.target.value})}/>
                          </FormItem>
                          :
                          null
                        }


                          <div style={{display:'flex',flexDirection:'row'}}>
                            <FormItem label={<h6 style={{fontWeight:'bold',margin:3}}>Def Amount </h6>}
                            >
                                <Input name="defsavings"   placeholder="0.0" value={this.state.defsavings} onChange={(e) => this.setState({ defsavings: e.target.value})}/>
                            </FormItem>

                            <SettingFilled style={{ fontSize: '30px',margin:3 }} onClick={()=>{ this.setState({defsett_modalvisible:true})}}/>

                          </div>

                          {
                            this.state.defsavings>0?
                            <h5>Defered Settings: ({this.state.defsettings.noofinstallments} installments,{this.state.defsettings.amountperinstallment} per installment, starting {this.state.defsettings.startingdate} )</h5>
                            :
                            null
                          }



                          {
                            this.state.availableloans.length>0?
                            <FormItem label={<h6 style={{fontWeight:'bold'}}>Loan Amount </h6>}
                            >
                                <Input name="loan_amount"   placeholder="0.0" value={this.state.loan_amount} onChange={(e) => this.setState({ loan_amount: e.target.value})}/>
                            </FormItem>
                            :
                            null

                          }


                          {
                            this.state.loan_amount>0?
                            <div>
 <FormItem label="Select Loan to Repay"
                                name="selectedloan"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please select loan',
                                  },
                                ]}                  
                            >
                                <Select 
                                placeholder="Select Loan to Repay" 
                                style={{  }} 
                                value={this.state.selectedloan}
                                onChange={(val)=>{
                                  this.setState({selectedloan:val})

                                  //query loan properties here
                                  let form_data = new FormData();
                                  form_data.append('loan_id', val);
                                  form_data.append('date', this.state.date);
                                 
                                  axios.post(serverconfig.backendserverurl+'/customqueries/getloanbalances_atparticulardate', form_data, {
                                    headers: {
                                      'content-type': 'multipart/form-data'
                                    }
                                    })
                                  .then(res => {
                                    this.setState({current_loanproperties:res.data.info})
                                    
                                  })
                                  .catch(error => console.log(error))


                                }} 
                                showSearch
                                optionFilterProp="children"
                                onFocus={this.onFocus}
                                onBlur={this.onBlur}
                                onSearch={this.onSearch}
                                                      
                                >
                                  {this.state.availableloans.map(
                                    (loan)=>(
                                      <Option value={loan.id}>Amount {loan.approved_amount} | Date {loan.date} | Months {loan.loan_period} | NO {loan.loan_number}</Option>
                                    ))}
                                </Select>
                              </FormItem>

                              <br></br>
                              {
                                this.isEmptyObject(this.state.current_loanproperties)===false?
                                <div >
                                <h4>Running interest balance as at {this.state.date} : <CurrencyFormat  value={this.state.current_loanproperties.runningint_balance} displayType={'text'} thousandSeparator={true} /> </h4>
                                <h4>Running principle balance as at {this.state.date} : <CurrencyFormat  value={this.state.current_loanproperties.runningprinciple_balance} displayType={'text'} thousandSeparator={true} /> </h4>
                                <h4>Total running balance as at {this.state.date} : <CurrencyFormat  value={this.state.current_loanproperties.runningint_total_bal} displayType={'text'} thousandSeparator={true} /> </h4>

                                </div>
                                :
                                null
                              }
                              <br></br>


                            </div>

                            :
                            null
                          }


                            <FormItem label={<h4 style={{fontWeight:'bold',color:'#154360'}}>Total Amount </h4>}
                            >
                              <h3 style={{fontWeight:'bolder'}}><CurrencyFormat  value={this.calculatetotalamount()} displayType={'text'} thousandSeparator={true} /></h3>
                          
                            </FormItem>
          
                            <FormItem label={<h4 style={{fontWeight:'bold'}}>Paid In By:</h4>}
                            name="paidinby"
                            rules={[
                              {
                                required: true,
                                message: 'Please input name',
                              },
                            ]}
                            >
                              <Input name="paidinby"  placeholder="Name" value={this.state.paidinby} onChange={this.handlepaidinbyChange}/>
            
                            </FormItem>
          
                            <FormItem 
                              label={<h4 style={{fontWeight:'bold'}}>Transactor Contact:</h4>}

                              >
                              <PhoneInput
                              countrySelectProps={{ unicodeFlags: true }}
                              defaultCountry="UG"
                              placeholder="Enter phone"
                              value={this.state.transactorcontact}
                              onChange={this.handletransactorcontactChange}
                              />
                          </FormItem> 


                          <FormItem 
                          label={<h4 style={{fontWeight:'bold'}}>Transaction details.</h4>}
                          name="Transaction"
                          rules={[
                            {
                              required: true,
                              message: 'Please input details',
                            },
                          ]}
                          >
                        
                          <TextArea
                              placeholder="Transaction Details"
                              autoSize={{ minRows: 2, maxRows: 8 }}
                              value={this.state.transaction_details}
                              onChange={(val)=>{this.setState({transaction_details:val.target.value})}}
                            />
                        
                          </FormItem>
                          <br></br>

                          <FormItem label={<h4 style={{fontWeight:'bold'}}>Ledger account.</h4>} 
                            name="debitaccount"
                            rules={[
                              {
                                required: true,
                                message: 'Please select account',
                              },
                            ]}
                            >
                                <Select 
                                placeholder="Account" 
                                style={{  }} 
                                value={this.state.debitaccount}
                                onChange={(val)=>{this.setState({debitaccount:val})}} 
                                showSearch
                                optionFilterProp="children"
                                onFocus={this.onFocus}
                                onBlur={this.onBlur}
                                onSearch={this.onSearch}                    
                                >
                                  {this.state.companyaccounts.map(
                                    (accnt)=>(
                                      <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                                    ))}
                                </Select>
                            </FormItem>


                          <br></br>

                            <FormItem label="Thermal Print?">
                            <Switch
                              checked={this.state.thermalprint}
                              onChange={val=>{this.setState({thermalprint:val})}}
                              checkedChildren="True"
                              unCheckedChildren="False"
                              />
                            </FormItem>

                          <FormItem>
                            <Button  type="primary" htmlType="submit">Submit</Button>
                          </FormItem>
                          </Form>
                          </Card>
                          
                          </Col>

                          <Col xs="12" sm="6" lg="4">
                            <Card>
                            <h3 style={{fontWeight:'bold',color:'#154360'}}>Account Details</h3>
                            <h4>Account Number:{this.state.selectedaccountobject.AccountNumber}</h4>
                            <h4>Account Contact:{this.state.selectedaccountobject.RegisteredPhoneNumber1}</h4>
                            <h4>Account Name:{this.state.selectedaccountobject.FullName}</h4>
                            <h4>Savings Balance:<CurrencyFormat value={this.state.selectedaccountobject.savings_balance} displayType={'text'} thousandSeparator={true}  /> </h4>
                            <h4>Savings Minimum Account Deposit:<CurrencyFormat value={this.state.selectedaccountobject.minimum_account_deposit} displayType={'text'} thousandSeparator={true}  /> </h4>
                          
                            <p style={{fontWeight:'bolder',fontSize:15}}>Saving Balance:<CurrencyFormat  value={Number(this.state.selectedaccountobject.savings_balance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>
                              <Divider />
                                <p style={{fontWeight:'bolder',fontSize:15}}>Super Save Balance:<CurrencyFormat  value={Number(this.state.selectedaccountobject.supersaver_balance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>
                                <Divider />

                                <p style={{fontWeight:'bolder',fontSize:15}}>Shares Balance:<CurrencyFormat  value={Number(this.state.selectedaccountobject.shareamount_balance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>
                                <Divider />

                                <p style={{fontWeight:'bolder',fontSize:15}}>Shares No:{this.state.selectedaccountobject.share_numberbalance}  </p>
                                <Divider />

                                <p style={{fontWeight:'bolder',fontSize:15}}>Teeba Goolo:<CurrencyFormat  value={Number(this.state.selectedaccountobject.teebagoolo_balance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>
                                <Divider />

                                <p style={{fontWeight:'bolder',fontSize:15}}>Loan:<CurrencyFormat  value={Number(this.state.selectedaccountobject.loan_balance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>
                                <Divider />

                                <p style={{fontWeight:'bolder',fontSize:15}}>Savings loan security:<CurrencyFormat  value={Number(this.state.selectedaccountobject.savingsloan_security_balance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>
                                <Divider />

                                <p style={{fontWeight:'bolder',fontSize:15}}>Supersave loan security:<CurrencyFormat  value={Number(this.state.selectedaccountobject.supersaveloan_security_balance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>
                                <Divider />

                                <p style={{fontWeight:'bolder',fontSize:15}}>Teebagoolo loan security:<CurrencyFormat  value={Number(this.state.selectedaccountobject.teebagoololoan_security_balance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>
                                <Divider />

                                <p style={{fontWeight:'bolder',fontSize:15}}>Shares loan security:<CurrencyFormat  value={Number(this.state.selectedaccountobject.shares_security_balance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>
                                <Divider />

                                <p style={{fontWeight:'bolder',fontSize:15}}>Saving Interest:<CurrencyFormat  value={Number(this.state.selectedaccountobject.saving_interestbalance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>
                                <Divider />

                                <p style={{fontWeight:'bolder',fontSize:15}}>Teebagoolo Interest:<CurrencyFormat  value={Number(this.state.selectedaccountobject.teebagoolo_interestbalance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>
                                <Divider />

                                <p style={{fontWeight:'bolder',fontSize:15}}>Supersave Interest:<CurrencyFormat  value={Number(this.state.selectedaccountobject.supersave_interestbalance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>
                                <Divider />

                                <p style={{fontWeight:'bolder',fontSize:15}}>Loan interest:<CurrencyFormat  value={Number(this.state.selectedaccountobject.loan_interestbalance).toFixed(2)} displayType={'text'} thousandSeparator={true} />  </p>
                                <Divider />

                            <h4>Status:{this.state.selectedaccountobject.account_status}</h4>
                            <h4>Date of joining:{this.state.selectedaccountobject.DateOfJoining}</h4>

                            <br></br>
                          </Card>
                          
                          </Col>
                      </Row>

          </TabPane>

          <TabPane tab="Multi journal entries form" key="2">
            <JournalEntryView />

        </TabPane>

          </Tabs>

               <Modal              
              visible={this.state.defsett_modalvisible}
              title={"Defered payments Settings Form"}
              onCancel={()=>{this.setState({defsett_modalvisible:false})}}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={()=>{this.setState({defsett_modalvisible:false})}}>
                  Cancel
                </Button>,
                  <Button key="ok" type='primary' 
                    onClick={()=>{
                      var intobj={
                        'noofinstallments':this.state.noofinstallments,
                        'amountperinstallment':this.state.amountperinstallment,
                        'startingdate':this.state.startingdate,
                      }

                      this.setState({defsettings:intobj})
                      this.setState({defsett_modalvisible:false})
                    }}>
                  Add Settings
                </Button>
                ]}
                >
                  
                <FormItem label="Number Of Installments" >
                    <Input name="noofinstallments"  placeholder="Number Of Installments" value={this.state.noofinstallments} onChange={(val)=>{this.setState({noofinstallments:val.target.value})}} />
                </FormItem>                  

                <FormItem label="Amount Per Installment" >
                    <Input name="amountperinstallment"  placeholder="Amount Per Installment" value={this.state.amountperinstallment} onChange={(val)=>{this.setState({amountperinstallment:val.target.value})}} />
                </FormItem> 

                <FormItem label="Starting date">
                    <DatePicker  onChange={(date, dateString)=>{this.setState({ startingdate: dateString})}} format={dateFormat} />
                </FormItem>

               </Modal> 

            </div>
       </div>              
                      
       )
      }
     
    }
   
  }
}

export default AccountDepositView; 
