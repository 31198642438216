import React,{useState,useEffect,lazy} from 'react';
import axios from 'axios'
import {  Slider,Statistic,Tabs,Modal } from 'antd';
import { ArrowUpOutlined,MinusCircleFilled,ToolOutlined, ArrowDownOutlined,FileFilled,WalletFilled,SearchOutlined,FundViewOutlined,
   UserOutlined, AccountBookOutlined,CalculatorFilled,SwapOutlined,GroupOutlined,AccountBookFilled, DollarCircleOutlined,EditFilled,MessageFilled,DollarOutlined,CarFilled,LoadingOutlined,PlusCircleFilled,CloudSyncOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Line,Pie,Bar } from 'react-chartjs-2';
import {withGetScreen} from 'react-getscreen'
import { Tooltip,Popover,Table,Popconfirm,Input, Button,Collapse,Card,Avatar,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import {
  Col,
  Row,
} from 'reactstrap';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities'
import * as serverconfig from '../serverconn'
import * as serversocketconfig from '../serversocketconfig'
import { MdShoppingCart } from 'react-icons/md';

import CurrencyFormat from 'react-currency-format';
import Highlighter from 'react-highlight-words';
import Websocket from 'react-websocket';
import { Link } from 'react-router-dom';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { NavLink } from 'react-router-dom'
import  MemberForm from '../components/MemberForm'
import  LoanCalculator from '../components/LoanCalculator'
import * as primarycolor from '../primarycolor'

var CryptoJS = require("crypto-js");

const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;


const style = { background: '#0092ff', padding: '8px 0',borderRadius: '7px',justifyContent:'center', alignItems:'center',display: 'flex', };
const dateFormat = 'DD/MM/YYYY';
const yeardateFormat = 'YYYY';
const monthdateFormat = 'MM/YYYY';

const { Meta } = Card;
const { Panel } = Collapse;
const { TabPane } = Tabs;
const FormItem=Form.Item;
const { Option } = Select;

var bizuserid= ''
var sacco= ''
var token= ''


class QuickMenu extends React.Component {

  state = {
    currentmonth:moment().format(monthdateFormat).toString(),
    companyprofile:{},
    datarequested:true,
    totalmemberaccounts:0,
    totalfemaleaccounts:0,
    totalmaleaccounts:0,
    totalloansthismonth:0,
    totalmembersavings:0,
    memberformvisible:false,
    transactformvisible:false,
    calculatorformvisible:false,
    members:[],
    loansformvisible:false,
    monthlyloanissuances:0,
    monthlyloanapplications:0,
    monthlyloanassessments:0,
    monthlyloanapprovals:0,
    dashboardlocked:false,
    utilitiesmodalvisible:false,
    userprofile:{},
    userrights:{},
    nonworkingday:{},
    reservetranfermodalvisible:false
  };



  callback =(key) =>{
    console.log(key);
    console.log(this.state.yearlymonthlysales)
  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }
    
    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }



   let form_data = new FormData();
   form_data.append('userid', bizuserid);
   
   //get the user profile here
   axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data,{
     headers: {
       'content-type': 'multipart/form-data'
     }
     })
   .then(res => {
       this.setState({
         userprofile:res.data
       })
    
   })


    axios.get(serverconfig.backendserverurl+`/api/saccos/${sacco}`)
    .then(res => {
        this.setState({
          companyprofile:res.data
        })

        if (res.data.activated===false){
          this.setState({dashboardlocked:true})
        }

    })

    axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          totalmemberaccounts:res.data.length
        })

        this.setState({members:res.data})

    })

    axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}&gender=${'Female'}`)
    .then(res => {
        this.setState({
          totalfemaleaccounts:res.data.length
        })

    })

    axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}&gender=${'Male'}`)
    .then(res => {
        this.setState({
          totalmaleaccounts:res.data.length
        })

    })


    axios.get(serverconfig.backendserverurl+`/api/loanapplications/?sacco=${sacco}&applicationMonth=${this.state.currentmonth}`)
    .then(res => {
        this.setState({
          monthlyloanapplications:res.data.length
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/loanassessments/?sacco=${sacco}&assessmentMonth=${this.state.currentmonth}`)
    .then(res => {
        this.setState({
          monthlyloanassessments:res.data.length
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/loanapprovals/?sacco=${sacco}&approvalMonth=${this.state.currentmonth}`)
    .then(res => {
        this.setState({
          monthlyloanapprovals:res.data.length
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/loanissues/?sacco=${sacco}&issuanceMonth=${this.state.currentmonth}`)
    .then(res => {
        this.setState({
          monthlyloanissuances:res.data.length
        })


    })

    axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
    .then(res => {
        this.setState({
          userrights:res.data[0]
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/nonworkingdays/?date=${moment().format(dateFormat).toString()}`)
    .then(res => {
       if(res.data){
        this.setState({
          nonworkingday:res.data[0]
        })
       }

        this.setState({datarequested:false})
    })

  }



getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ?(
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};

//close member form
closeMemberForm=()=>{
  this.setState({memberformvisible:false})
}

openMemberForm=()=>{
  this.setState({memberformvisible:true})
}

// close/open transact form
closeTransactForm=()=>{
  this.setState({transactformvisible:false})
}

openTransactForm=()=>{
  this.setState({transactformvisible:true})
}

// close/open transact form
closeCalculatorForm=()=>{
  this.setState({calculatorformvisible:false})
}

openCalculatorForm=()=>{
  this.setState({calculatorformvisible:true})
}

//close open loan options modal
closeloanoptionsForm=()=>{
  this.setState({loansformvisible:false})
}

openloanoptionsForm=()=>{
  this.setState({loansformvisible:true})
}

//open close utilities modal
openutilitiesmodal=()=>{
  this.setState({utilitiesmodalvisible:true})
}

closeutilitiesmodal=()=>{
  this.setState({utilitiesmodalvisible:false})
}





//calculate Total member savings
calculateTotalMemberSavings=()=>{
  var Totalsavings=0
  this.state.members.map(
    (item)=>{
      Totalsavings+=Number(item.UserBalance)
    })

  return Totalsavings;

}


  render() {

    const  memberchartData={
      labels:['Male','Female'],
      datasets:[{
        label:'Total Members Per Genda',
        data:[this.state.totalmaleaccounts,this.state.totalfemaleaccounts],
        backgroundColor:[
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)'
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 1
      }]
    }


    const  loanstatisticsData={
      labels:['MonthlyApplications','MonthlyAssessments','MonthlyApprovals','MonthlyIssuances'],
      datasets:[{
        label:'Numbers Per Month',
        data:[this.state.monthlyloanapplications,this.state.monthlyloanassessments,this.state.monthlyloanapprovals,this.state.monthlyloanissuances],
        backgroundColor:[
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)'
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 1
      }]
    }


if(this.state.dashboardlocked===true){
  return(
    <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
    <h3 style={{fontWeight:'bolder',color:'red'}}>NOTE: Your dashboard is currently locked. Contact system adminstrators.Your Licence may be expired or some thing else. Thanks</h3>
    </div>
  )
}else{

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
  
    }else{

      if(this.state.nonworkingday){
        return(
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
          <p style={{fontWeight:'bolder',color:'grey'}}>It's a non working day</p>
          </div>
        )
      }else{
        return(
          <div className="animated fadeIn">  
          <h3 style={{fontStyle:'oblique',fontWeight:'bolder',color:primarycolor.primarycolor}}>{this.state.companyprofile.sacco_name} QUICK MENU</h3>
    
           <Row>
            <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
            <Tooltip title="Click here to transact" placement="top">
            <Card
                  hoverable
                  style={{ 
                    background:"white",
                    display: 'flex',
                    justifyContent:'center',
                    padding:4,
                    alignItems:'center' }}
                >
                    <div style={{width:'100%',
                      padding:10,
                      alignItems:'center',
                      alignSelf:'center',
                      display:'flex',
                      justifyContent:'center',
                      flexDirection:'column'
                      }}>
                    <NavLink to='/alltrans_actions/'>
                    <div                  
                    style={{ 
                        padding:3,
                        alignItems:'center',
                        alignSelf:'center',
                        display:'flex',
                        justifyContent:'center',
                        flexDirection:'column' }}>
                        <DollarOutlined style={{color:primarycolor.primarycolor,fontSize: '50px',alignSelf:'center'}}/>
                        <h4 style={{fontWeight:'bolder'}}>TRANSACT</h4>
                    </div>
                    </NavLink>

                  </div>
                </Card>
              </Tooltip>
             
            </Col>
            
            <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
            <Tooltip title="Click to Add New Member" placement="top">
              <Card
                  onClick={()=>{this.openMemberForm()}}
                  hoverable
                  style={{ 
                    background:"white",
                    display: 'flex',
                    justifyContent:'center',
                    padding:4,
                    alignSelf:'center',
                    alignItems:'center' }}
                >
    
                    <div style={{width:'100%',
                      padding:10,
                      alignItems:'center',
                      alignSelf:'center',
                      display:'flex',
                      justifyContent:'center',
                      flexDirection:'column'
                      }}>
                      <PlusCircleFilled style={{color:primarycolor.primarycolor,fontSize: '50px'}}/>
                      <h4 style={{fontWeight:'bold'}}>NEW MEMBER</h4>
                     </div>   
    
                </Card>
                </Tooltip>
              </Col>
    
              <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
              <Tooltip title="Click to Add Loan Application or Make Loan Repayment" placement="top">
              <Card
                  onClick={()=>{this.openloanoptionsForm()}}
                  hoverable
                  style={{ 
                    background:"white",
                    display: 'flex',
                    justifyContent:'center',
                    padding:4,
                    alignSelf:'center',
                    alignItems:'center' }}
                >
                  <div style={{width:'100%',
                      padding:10,
                      alignItems:'center',
                      alignSelf:'center',
                      display:'flex',
                      justifyContent:'center',
                      flexDirection:'column'
                      }}>
                        <PlusCircleFilled style={{color:primarycolor.primarycolor,fontSize: '50px'}}/>
                        <h4 style={{fontWeight:'bold'}}>LOAN ACTIONS</h4>
                      </div>
                    
                </Card>
              </Tooltip>
    
             
              </Col>
    
              <Col xs="12" sm="6" lg="3" style={{padding: '5px'}}>
              <Tooltip title="Click to open utilities" placement="top">
              <Card
                  onClick={()=>{this.openutilitiesmodal()}}
                  hoverable
                  style={{ 
                    background:"white",
                    display: 'flex',
                    justifyContent:'center',
                    padding:4,
                    alignItems:'center' }}
                >
                  <div style={{width:'100%',
                      padding:10,
                      alignItems:'center',
                      alignSelf:'center',
                      display:'flex',
                      justifyContent:'center',
                      flexDirection:'column'
                      }}>
                        <ToolOutlined style={{color:primarycolor.primarycolor,fontSize: '50px'}}/>
                        <h4 style={{fontWeight:'bold'}}>UTILITIES</h4>
                      </div>               
    
                </Card>
              </Tooltip>
            </Col>

            </Row>
    
    
            {
              this.state.userprofile.branch_manager?
              <Card>
              <Pie
                  data={memberchartData}
                  options={{
                    maintainAspectRatio:true,
                    title:{
                      display:true,
                      text:'Member Accounts By Gender',
                      fontSize:20
                    }
                  }}
                  />
                
              </Card>
    
              
              :
              null
    
    
            }
    

    
          
            {
            this.state.userrights.can_register_members?
    
            <Modal              
            visible={this.state.memberformvisible}
            title="Member Creation"
            onCancel={this.closeMemberForm}
    
            footer={[
              <Button key="back" onClick={this.closeMemberForm}>
                Cancel
              </Button>
              ]}
              >
              <MemberForm />
                
            </Modal>  
            :
            null
            }
    
    
    
    
            {/* TRANSACT MODAL*/}
    
            <Modal    
                       
              visible={this.state.transactformvisible}
              title="Account Transaction"
              onCancel={this.closeTransactForm}
          
              footer={[
                <Button key="back" onClick={this.closeTransactForm}>
                  Cancel
                </Button>
                ]}
                >
    
              <div style={{display:'flex',alignContent:'center',justifyContent:'center',flexDirection:'row'}} >
    
              {
            this.state.userrights.can_deposit?
            <Card
            hoverable
            style={{ 
              padding:3,
              alignItems:'center',
              alignSelf:'center',
              display:'flex',
              justifyContent:'center',
              marginRight:6,
              flexDirection:'column'}}
          > 
          <NavLink to='/deposit/'>
          <div                  
           style={{ 
              padding:3,
              alignItems:'center',
              alignSelf:'center',
              display:'flex',
              justifyContent:'center',
              flexDirection:'column' }}>
              <PlusCircleFilled style={{color:'#154030',fontSize: '50px',alignSelf:'center'}}/>
              <h6 style={{fontWeight:'bolder',color:'#154360'}}>DEPOSIT </h6>
          </div>
          </NavLink>
    
          </Card> 
     
            :
            null
            }
    
    
           {
            this.state.userrights.can_withdraw?
            <Card
              hoverable
              style={{ 
                padding:3,
                alignItems:'center',
                alignSelf:'center',
                display:'flex',
                justifyContent:'center',
                flexDirection:'column' }}
            > 
            <NavLink to='/withdraw/'>
            <div                  
            style={{ 
                padding:3,
                alignItems:'center',
                alignSelf:'center',
                display:'flex',
                justifyContent:'center',
                flexDirection:'column' }}>
            <MinusCircleFilled style={{color:'#154360',fontSize: '50px',alignSelf:'center'}}/>
            <h6 style={{fontWeight:'bolder',color:primarycolor.primarycolor}}>WITHDRAW </h6>
    
            </div>
    
            </NavLink>
    
    
    
          </Card> 
     
            :
            null
            }
    
    
              
              </div>
    
              <br></br>
              <div style={{display:'flex',alignContent:'center',justifyContent:'center',flexDirection:'row'}} >
                {
                  this.state.userprofile.branch_manager?
                  this.state.companyprofile.sharesfeature?
                  <Card
                  hoverable
                  style={{ 
                    padding:3,
                    alignItems:'center',
                    alignSelf:'center',
                    display:'flex',
                    justifyContent:'center',
                    marginRight:6,
                    flexDirection:'column'}}
                > 
                <NavLink to='/sharetransfer/'>
                <div                  
                 style={{ 
                    padding:3,
                    alignItems:'center',
                    alignSelf:'center',
                    display:'flex',
                    justifyContent:'center',
                    flexDirection:'column' }}>
                    <SwapOutlined style={{color:'#154030',fontSize: '30px',alignSelf:'center'}}/>
                    <h6 style={{fontWeight:'bolder',color:'#154360'}}>SHARE TRANSFER </h6>
                </div>
                </NavLink>
    
                </Card> 
                  :
                  null
                  
                  :
                  null
                }
                
    
    
                  {
    
                    this.state.companyprofile.showsubaccounttransfer?
                     this.state.userrights.can_withdraw?
                        <Card
                        hoverable
                        style={{ 
                          padding:3,
                          alignItems:'center',
                          alignSelf:'center',
                          display:'flex',
                          justifyContent:'center',
                          flexDirection:'column' }}
                      > 
                      <NavLink to='/subaccounttransfer/'>
                      <div                  
                      style={{ 
                          padding:3,
                          alignItems:'center',
                          alignSelf:'center',
                          display:'flex',
                          justifyContent:'center',
                          flexDirection:'column' }}>
                      <SwapOutlined style={{color:'#154360',fontSize: '30px',alignSelf:'center'}}/>
                      <h6 style={{fontWeight:'bolder',color:'#15430'}}>SUB ACCOUNT TRANSFERS </h6>
      
                      </div>
      
                      </NavLink>
      
                    </Card> 
                     :
                     null
                     
                    :
                    null
                  }

                  {
                  this.state.userprofile.branch_manager?
                  this.state.companyprofile.sharesfeature?
                  <Card
                  hoverable
                  style={{ 
                    padding:3,
                    alignItems:'center',
                    alignSelf:'center',
                    display:'flex',
                    justifyContent:'center',
                    marginRight:6,
                    flexDirection:'column'}}
                > 
                <NavLink to='/depositshares/'>
                <div                  
                 style={{ 
                    padding:3,
                    alignItems:'center',
                    alignSelf:'center',
                    display:'flex',
                    justifyContent:'center',
                    flexDirection:'column' }}>
                    <PlusCircleFilled style={{color:'#154030',fontSize: '30px',alignSelf:'center'}}/>
                    <h6 style={{fontWeight:'bolder',color:'#154360'}}>DEPOSIT SHARES </h6>
                </div>
                </NavLink>
    
                </Card> 
                  :
                  null
                  
                  :
                  null
                }
                
              </div>
                   
            </Modal> 
    
    
            {/* lOAN CALCULATOR MODAL*/}
             <Modal              
              visible={this.state.calculatorformvisible}
              title="Loan Calculator"
              onCancel={this.closeCalculatorForm}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={this.closeCalculatorForm}>
                  Cancel
                </Button>
                ]}
                >
                <LoanCalculator />
                  
            </Modal>    
    
            {/* LOAN OPTIONS MODAL*/}
    
            <Modal    
                       
              visible={this.state.loansformvisible}
              title="Loan Actions"
              onCancel={this.closeloanoptionsForm}
          
              footer={[
                <Button key="back" onClick={this.closeloanoptionsForm}>
                  Cancel
                </Button>
                ]}
                >
              <div style={{display:'flex',alignContent:'center',justifyContent:'center',flexDirection:'row'}} >
    
    
                {
                  this.state.userrights.can_register_loans?
                  <Card
                  hoverable
                  style={{ 
                    padding:3,
                    alignItems:'center',
                    alignSelf:'center',
                    display:'flex',
                    justifyContent:'center',
                    marginRight:6,
                    flexDirection:'column'}}
                > 
                <NavLink to='/loanapplication/'>
                <div                  
                 style={{ 
                    padding:3,
                    alignItems:'center',
                    alignSelf:'center',
                    display:'flex',
                    justifyContent:'center',
                    flexDirection:'column' }}>
                    <PlusCircleFilled style={{color:'#154030',fontSize: '50px',alignSelf:'center'}}/>
                    <p style={{fontWeight:'bolder',color:'#154360'}}>LOAN APPLICATION </p>
                </div>
                </NavLink>
    
                </Card>
                  :
                  null
                }
    
    
                {/*
                  this.state.userrights.can_enter_loan_repayment?
                  <Card
                  hoverable
                  style={{ 
                    padding:3,
                    alignItems:'center',
                    alignSelf:'center',
                    display:'flex',
                    justifyContent:'center',
                    flexDirection:'column' }}
                > 
                <NavLink to='/loanrepaymentdirect/'>
                <div                  
                 style={{ 
                    padding:3,
                    alignItems:'center',
                    alignSelf:'center',
                    display:'flex',
                    justifyContent:'center',
                    flexDirection:'column' }}>
                <PlusCircleFilled style={{color:'#154360',fontSize: '50px',alignSelf:'center'}}/>
                <p style={{fontWeight:'bolder',color:'#154360'}}>LOAN REPAYMENT </p>
    
                </div>
    
                </NavLink>
    
              </Card> 
                  :
                  null */
                }

    
              </div>
              
            </Modal> 
    
    
            {/**Utilities modal */}
            <Modal            
              visible={this.state.utilitiesmodalvisible}
              title="Utilities"
              onCancel={this.closeutilitiesmodal}
          
              footer={[
                <Button key="back" onClick={this.closeutilitiesmodal}>
                  Cancel
                </Button>
                ]}
                >
              <div style={{display:'flex',alignContent:'center',justifyContent:'center',flexDirection:'row'}} >
                  {
                    this.state.userrights.sendmessages?
                      <Card
                      hoverable
                      style={{ 
                        padding:3,
                        alignItems:'center',
                        alignSelf:'center',
                        display:'flex',
                        justifyContent:'center',
                        marginRight:6,
                        flexDirection:'column'}}
                    > 
                    <NavLink to='/messaging/'>
                    <div                  
                    style={{ 
                        padding:3,
                        alignItems:'center',
                        alignSelf:'center',
                        display:'flex',
                        justifyContent:'center',
                        flexDirection:'column' }}>
                        <MessageFilled style={{color:'#154030',fontSize: '50px',alignSelf:'center'}}/>
                        <p style={{fontWeight:'bolder',color:'#154360'}}>SMS MESSAGING </p>
                    </div>
                    </NavLink>
    
                    </Card> 
                    :
                    null
                  }
    
    
                 <Card
                  hoverable
                  style={{ 
                    padding:3,
                    alignItems:'center',
                    alignSelf:'center',
                    display:'flex',
                    justifyContent:'center',
                    flexDirection:'column' }}
                > 
                <NavLink to='/loancalculator/'>
                     <div style={{width:'100%',
                      padding:10,
                      alignItems:'center',
                      alignSelf:'center',
                      display:'flex',
                      justifyContent:'center',
                      flexDirection:'column'
                      }}>
                  <CalculatorFilled style={{color:'#154030',fontSize: '50px',alignSelf:'center'}}/>
                  <p style={{alignSelf:'center',fontWeight:'bold',color:'#154360'}}>LOAN CALCULATOR</p>
    
                  </div>
                </NavLink>
    
              </Card> 

              </div>

              <br></br>

              <div style={{display:'flex',alignContent:'center',justifyContent:'center',flexDirection:'row'}} >
              {
                this.state.userprofile.branch_manager===true?
                <Card
                hoverable
                style={{ 
                  padding:3,
                  alignItems:'center',
                  alignSelf:'center',
                  display:'flex',
                  justifyContent:'center',
                  flexDirection:'column' }}
              > 
              <NavLink to='/datasync/'>
                   <div style={{width:'100%',
                    padding:10,
                    alignItems:'center',
                    alignSelf:'center',
                    display:'flex',
                    justifyContent:'center',
                    flexDirection:'column'
                    }}>
                <CloudSyncOutlined style={{color:'#154030',fontSize: '50px',alignSelf:'center'}}/>
                <p style={{alignSelf:'center',fontWeight:'bold',color:'#154360'}}>CLOUD DATA SYNC</p>
  
                </div>
              </NavLink>
  
             </Card> 
                :
                null
              }


              {
                this.state.userprofile.branch_manager===true?
                <Card
                hoverable
                style={{ 
                  padding:3,
                  alignItems:'center',
                  alignSelf:'center',
                  display:'flex',
                  justifyContent:'center',
                  flexDirection:'column' }}
              > 
              <NavLink to='/batchemail_statementsending/'>
                   <div style={{width:'100%',
                    padding:10,
                    alignItems:'center',
                    alignSelf:'center',
                    display:'flex',
                    justifyContent:'center',
                    flexDirection:'column'
                    }}>
                <CloudSyncOutlined style={{color:'#154030',fontSize: '50px',alignSelf:'center'}}/>
                <p style={{alignSelf:'center',fontWeight:'bold',color:'#154360'}}>BATCH EMAIL SENDING</p>
  
                </div>
              </NavLink>
  
             </Card> 
                :
                null
              }

            </div>
    
            </Modal> 

          </div>
        )

      }

    }
  
  }

  }
}

export default QuickMenu; 
