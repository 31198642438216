import React,{ useRef } from 'react';
import axios from 'axios'
import { Table, Input, Button,Collapse,Avatar,Modal,Form,DatePicker,Select,Space,Popconfirm,Typography,Divider,Switch,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { SearchOutlined,CameraOutlined,PlusOutlined,DownloadOutlined,LoadingOutlined,DeleteOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import * as serverconfig from '../serverconn'
import CurrencyFormat from 'react-currency-format';
import * as reactstrp from 'reactstrap';

import {
  Col,
  Row,
} from 'reactstrap';
import ReactExport from "@ibrahimrahmani/react-export-excel";
import '../tablestyle.css'; // Create this CSS file for custom styles

var CryptoJS = require("crypto-js");

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Search } = Input;

var sacco= ''
var bizuserid= ''
var token= ''
var username=''

class ReportToPrint extends React.Component {

    state = {
      companyprofile:{},
    };
  
    componentDidMount(){

      if(localStorage.getItem("sacco")){
         sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
         bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
         username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
 
      }else{
         sacco= ''
         bizuserid= ''
         username=''

      }
      
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }

      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

      axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
      .then(res => {  
          this.setState({
            companyprofile: res.data
          })
  
      })

    }

    calculateTotalDebit=()=>{
      var total=0
  
      this.props.sheetitems.map((item)=>{
        total+=Number(item.Debit)
  
      })
      return total;
  
    }
  
    calculateTotalCredit=()=>{
      var total=0
  
      this.props.sheetitems.map((item)=>{
        total+=Number(item.Credit)
  
      })
      return total;
  
    }
  
    render() {
      return (
        <div style={{padding:20}}>
 <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>
                
                  </Col>

       </Row>

             <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>JOURNAL REPORT FOR {this.props.entrantname} FROM: {this.props.dateone}  TO: {this.props.datetwo}</h3>

             <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Date</th>
                          <th>Account</th>
                          <th>Desc</th>
                          <th>Journal No</th>
                          <th>User</th>
                          <th>Debit</th>
                          <th>Credit</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.props.sheetitems.map(
                        (item)=>(
                          <tr>
                                                      <td>{item.item_no}</td>

                          <td>{item.date}</td>
                          <td>{item.account}</td>
                          <td>{item.description}</td>
                          <td>{item.journal_no}</td>
                          <td>{item.user}</td>
                          <td className="align-right">{<CurrencyFormat value={item.Debit} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.Credit} displayType={'text'} thousandSeparator={true}/>}</td>
                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                        <td></td>
                        <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalDebit()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalCredit()} displayType={'text'} thousandSeparator={true}/>}</td>
                        </tr>
                    </tbody>
                </reactstrp.Table> 
        </div>
      );
    }
  }


class TillSheetReport extends React.Component {

  state = {
    users:[],
    sheetitems:[],
    sheetitems_placeholders:[],

    dateone:'',
    datetwo:'',
    entrant:'',
    entrantname:'',
    totaldebit:0,
    totalcredit:0,
    datarequested:false,
    searchbyjno:false,
    journalno:0,

    selected_jounal:'',
    selected_jounal_obj:{},
    jounalform_visible:false,
    journalcredit_amount:0,
    journaldebit_amount:0,
    journal_description:'',
    journal_date:'',
    companyprofile:{},
    searchbymemeber:false,
    members:[],
    selectedaccount:'',
    companyaccounts: [],
    journal_account:'',
    userrights:{},


   };

   componentDidMount(){
    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
      username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)

   }else{
      sacco= ''
      bizuserid= ''
      username=''
   }
   
   if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/accounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          users:res.data
        })
      })


      axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
      .then(res => {  
          this.setState({
            companyprofile: res.data
          })
      })

      axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
      .then(res => {
  
        //console.log(res.data)
        this.setState({
          members:res.data
        })
  
      })

      axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?sacco=${sacco}`)
      .then(res => {
          this.setState({
              companyaccounts:res.data
          })
          this.setState({datarequested:false})
      })
  

      axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
      .then(res => {
          this.setState({
            userrights:res.data[0]
          })
      })

  }
 
   handledatechange= (date, dateString) =>{
     this.setState({ dateone: dateString[0]});
     this.setState({ datetwo: dateString[1]});
   } 

   handleentrantChange= (e) => this.setState({ entrant: e});

   //search sales method
   searchSales=()=>{

    if (this.state.searchbyjno===true){
      let form_data = new FormData();
      form_data.append('journalno', this.state.journalno);
      form_data.append('sacco', sacco);
      form_data.append('action', "journalno");

      if(this.state.journalno===0){
        alert("Please enter journal number")
      }else{
          this.setState({datarequested:true})
  
          //Now submit sale data to database
          axios.post(serverconfig.backendserverurl+'/customqueries/gettillsheetreport', form_data,{
          headers: {
            'content-type': 'multipart/form-data'
          }
          })
          .then(res =>{
            this.setState({datarequested:false})
  
              this.setState({sheetitems:JSON.parse(res.data.report)})
              this.setState({sheetitems_placeholders:JSON.parse(res.data.report)})

              this.setState({totaldebit:res.data.totalDebit})
              this.setState({totalcredit:res.data.totalCredit})
              this.setState({entrantname:res.data.entrantname})
              
          } 
          )
          .catch(error => console.log(error))   
      }

    }else{
      let form_data = new FormData();
      form_data.append('dateone', this.state.dateone);
      form_data.append('datetwo', this.state.datetwo);
      form_data.append('entrant', this.state.entrant);
      form_data.append('sacco', sacco);
      form_data.append('action', "daterange");

      console.log(this.state.entrant)
        
      if(this.state.entrant===''||this.state.dateone===''||this.state.datetwo===''){
        alert("Please select entrant or dates missing")
      }else{
          this.setState({datarequested:true})
  
          //Now submit sale data to database
          axios.post(serverconfig.backendserverurl+'/customqueries/gettillsheetreport', form_data,{
          headers: {
            'content-type': 'multipart/form-data'
          }
          })
          .then(res =>{
            this.setState({datarequested:false})
  
              this.setState({sheetitems:JSON.parse(res.data.report)})
              this.setState({sheetitems_placeholders:JSON.parse(res.data.report)})

              this.setState({totaldebit:res.data.totalDebit})
              this.setState({totalcredit:res.data.totalCredit})
              this.setState({entrantname:res.data.entrantname})
          } 
          )
          .catch(error => console.log(error))   
      }
    }

    }

   ////////////////////////////////////
  // USER SEARCH SELECT METHODS
  onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }
 
  calculateTotalDebit=()=>{
    var total=0

    this.state.sheetitems.map((item)=>{
      total+=Number(item.Debit)

    })
    return total.toFixed(2);

  }

  calculateTotalCredit=()=>{
    var total=0

    this.state.sheetitems.map((item)=>{
      total+=Number(item.Credit)

    })
    return total.toFixed(2);

  }



   ////////////////////////////////////////////
   // RENDERING METHOD HERE
   render() {
     
       if(this.state.datarequested===true){
        return(
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
          <Spin indicator={antIcon} />
          </div>
        )
  
      }else{

        return(
          <div>
            <div style={{display: "none"}}>
               &nbsp;&nbsp;
              <ReportToPrint
              totaldebit={this.state.totaldebit} 
              totalcredit={this.state.totalcredit} 
              dateone={this.state.dateone}
              datetwo={this.state.datetwo}
              sheetitems={this.state.sheetitems}
              entrantname={this.state.entrantname}
              searchbyjno={this.state.searchbyjno}
              ref={el => (this.componentRef = el)} /> 
            </div>
  
              <Collapse defaultActiveKey={['1']} onChange={this.callback}>
              <Panel header="JOURNAL REPORT" key="1">


              <div style={{display:'flex',justifyContent:'flex-start',flexDirection:'row'}}>
                <ReactToPrint
                  trigger={() => 
                  <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
                  Print
                  </Button>
                  }
                  content={() => this.componentRef}
                  />
                &nbsp;&nbsp;

              <ExcelFile filename={"Pisacco - Tillsheet report from "+this.state.dateone+" to "+this.state.datetwo}  element={<Button type='primary' icon={<DownloadOutlined/>}>Download (Excel)</Button>}>
                <ExcelSheet data={this.state.sheetitems} name={"Journal entries report"}>
                    <ExcelColumn label="No" value="item_no"/>
                    <ExcelColumn label="Date" value="date"/>
                    <ExcelColumn label="Account" value="account"/>
                    <ExcelColumn label="Description" value="description"/>
                    <ExcelColumn label="Journal no" value="journal_no"/>
                    <ExcelColumn label="User" value="user"/>
                    <ExcelColumn label="Debit" value="Debit"/>
                    <ExcelColumn label="Credit" value="Credit"/>
                </ExcelSheet>
              </ExcelFile>
               </div>
               <br></br>
               <br></br>


              <Form  layout="inline" >
              <FormItem label="Search by Journal No">
                <Switch
                  checked={this.state.searchbyjno}
                  onChange={val=>{this.setState({searchbyjno:val})}}
                  checkedChildren="True"
                  unCheckedChildren="False"
                  />
              </FormItem>

              <FormItem label="Search by member">
                <Switch
                  checked={this.state.searchbymemeber}
                  onChange={val=>{this.setState({searchbymemeber:val})}}
                  checkedChildren="True"
                  unCheckedChildren="False"
                  />
              </FormItem>

              {
                this.state.searchbyjno===true?
                <div>
                <FormItem label="Journal Number"          
                >
                  <Input name="journalno" type={'number'} placeholder="0" value={this.state.journalno} onChange={(val)=>{this.setState({journalno:val.target.value})}} />
                </FormItem>  
                </div>
                :
                <div style={{display:'flex',flexDirection:'row'}}>
                    <FormItem label="Users">
                     <Select 
                     placeholder="User"
                     style={{ width: 200 }} 
                     value={this.state.entrant} 
                     onChange={this.handleentrantChange}
                      
                     showSearch
                     optionFilterProp="children"
                     onFocus={this.onFocus}
                     onBlur={this.onBlur}
                     onSearch={this.onSearch}
                     filterOption={(input, option) =>
                       option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                     }                     
                      >
                        <Option value={0}>All Users</Option>
                       {this.state.users.map(
                         (user)=>(
                           <Option value={user.id}>{user.username}</Option>
                         ))}
                     </Select>
                 </FormItem>
 
                  <FormItem label="Date Range">
                      <RangePicker onChange={this.handledatechange} format={dateFormat} />
                  </FormItem>
  
                </div>
              }
              

              {
                this.state.searchbymemeber===true?
                <FormItem label="Member">
                    <Select 
                    placeholder="Account No | Name" 
                    style={{ width:300 }} 
                    value={this.state.selectedaccount}
                    onChange={(val)=>{this.setState({selectedaccount:val})}} 
                    showSearch
                    optionFilterProp="children"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}   
                    
                                     
                    >
                      {this.state.members.map(
                        (accnt)=>(
                          <Option value={accnt.id}>{accnt.AccountNumber} | {accnt.FullName}</Option>
                        ))}
                    </Select>
                </FormItem>
                :
                null}


                  <FormItem>
                  <Button onClick={()=>{

                    if(this.state.searchbymemeber===true){
                      let form_data = new FormData();
                      form_data.append('sacco', sacco);
                      form_data.append('selectedaccount', this.state.selectedaccount);
                      form_data.append('dateone', this.state.dateone);
                      form_data.append('datetwo', this.state.datetwo);
                      if(this.state.selectedaccount===""){
                        message.error("Please select account")
                      }else if(this.state.dateone===''||this.state.datetwo===''){
                        message.error("Please dates are missing")
                      }else{
                          this.setState({datarequested:true})
                  
                          //Now submit sale data to database
                          axios.post(serverconfig.backendserverurl+'/customqueries/gettillsheetreport_bymember', form_data,{
                          headers: {
                            'content-type': 'multipart/form-data'
                          }
                          })
                          .then(res =>{
                            this.setState({datarequested:false})
                  
                              this.setState({sheetitems:JSON.parse(res.data.report)})
                              this.setState({totaldebit:res.data.totalDebit})
                              this.setState({totalcredit:res.data.totalCredit})
                              this.setState({entrantname:res.data.entrantname})
                          } 
                          )
                          .catch(error => console.log(error))   
                      }

                    }else{
                      this.searchSales()
                    }


                  }} type="primary" htmlType="button">Search</Button>
                  </FormItem> 
  
             </Form>
  
             <br></br>

             <Form  layout="inline" >

            <FormItem label="Search by user">
            <Search
                  placeholder="Search by user"
                  onChange={(value) => {
                    var val=value.target.value
                    console.log(val)
                    if (val !== '' || val !== undefined) {
                      //set one is by NAME
                      const results_1 = this.state.sheetitems.filter((request) => {
                        return String(request.user).toLowerCase().startsWith(val.toLowerCase());
                        // Use the toLowerCase() method to make it case-insensitive
                      });
                
                      if (results_1.length === 0) {
                       this.setState({sheetitems:this.state.sheetitems_placeholders});

                      } else {
                        this.setState({sheetitems:results_1});
                        console.log(results_1)
                      }
                
                    } else {
                      this.setState({sheetitems:this.state.sheetitems_placeholders});
                    }
                
                  }}
                  style={{
                    width: 400,
                    margin:5
                  }}
                  />
              </FormItem>
              </Form>


          <br></br>
             <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Date</th>
                          <th>Account</th>
                          <th>Desc</th>
                          <th>Journal No</th>
                          <th>User</th>
                          <th>Debit</th>
                          <th>Credit</th>

                          <th>Member name</th>


                          {
                          this.state.companyprofile.allow_transaction_reversal && this.state.userrights.can_make_transaction_reversals?
                          <th>Edit</th>
                          :
                          null}

                          {
                          this.state.companyprofile.allow_transaction_reversal && this.state.userrights.can_make_transaction_reversals?
                          <th>Delete</th>
                          :
                          null}

                        </tr>
                      </thead>
                      <tbody>
                      {this.state.sheetitems.map(
                        (item)=>(
                          <tr>
                          <td>{item.item_no}</td>
                          <td>{item.date}</td>
                          <td>{item.account}</td>
                          <td>{item.description}</td>
                          <td>{item.journal_no}</td>
                          <td>{item.user}</td>
                          <td className="align-right">{<CurrencyFormat value={item.Debit} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td className="align-right">{<CurrencyFormat value={item.Credit} displayType={'text'} thousandSeparator={true}/>}</td>
                          
                          <td>{item.member_name}</td>

                          {
                          this.state.companyprofile.allow_transaction_reversal && this.state.userrights.can_make_transaction_reversals?                          <td><Button type='primary' onClick={()=>{
                            this.setState({selected_jounal:item.id})
                            this.setState({selected_jounal_obj:item})

                            this.setState({journal_description:item.description})
                            this.setState({journaldebit_amount:item.Debit})
                            this.setState({journalcredit_amount:item.Credit})
                            this.setState({journal_date:item.date})
                            this.setState({journal_account:item.account})

                            this.setState({jounalform_visible:true})

                          }}>Edit</Button></td>
                          :
                          null}


                        {
                          this.state.companyprofile.allow_transaction_reversal && this.state.userrights.can_make_transaction_reversals?                       
                             <td>


                        <Popconfirm
                         title="Sure to delete?" 
                          onConfirm={() => {
                             axios.delete(`${serverconfig.backendserverurl}/api/till/${item.id}`).then(res =>{
                              window.location.reload(false)
                              message.info("successfully deleted") 
                            }
                            );
                         }}>
                          <DeleteOutlined style={{color:'red'}}/>
                        </Popconfirm>
                              
                              
                          </td>
                          :
                          null}

                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                        <td></td>
                        <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalDebit()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td className="align-right"style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalCredit()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td></td>
                        <td></td>

                        </tr>

                    </tbody>
                </reactstrp.Table> 



              </Panel>
              </Collapse>


              <Modal
                  visible={this.state.jounalform_visible}
                  title="Journal edit form"
                  width={1200}
                  onCancel={()=>{this.setState({jounalform_visible:false})}}
                  footer={[
                    <Button key="back" onClick={()=>{this.setState({jounalform_visible:false})}}>
                      Cancel
                    </Button>,
                    <Button key="ok" type='primary'
                     onClick={()=>{
                      //edit now
                      let form_data = new FormData();
                      form_data.append('journal_description', this.state.journal_description);
                      form_data.append('journaldebit_amount', this.state.journaldebit_amount);
                      form_data.append('journalcredit_amount', this.state.journalcredit_amount);
                      form_data.append('journal_id', this.state.selected_jounal);
                      form_data.append('journal_date', this.state.journal_date);
                      form_data.append('journal_account', this.state.journal_account);
                      form_data.append('username', username);
                      form_data.append('sacco', sacco);

                      axios.post(serverconfig.backendserverurl+'/customqueries/editjournalentry', form_data, {
                        headers: {
                          'content-type': 'multipart/form-data'
                        }
                      })
                    .then(res =>{
                      message.info(res.data.message)
                      this.setState({jounalform_visible:false})

                      //qery again now
                      this.searchSales()
                      
                    })
                    .catch(error => console.log(error))

                    }}>
                    Update
                    </Button>,

                    ]}
                    >
                  
                  <FormItem label={"Date ( "+this.state.journal_date+" )"}
                    >
                        <DatePicker onChange={(date, dateString) => this.setState({ journal_date: dateString})} format={dateFormat} />
                    </FormItem> 

                  <FormItem label="Debit">
                  <Input name="Debit" type='number' placeholder="Debit" value={this.state.journaldebit_amount} onChange={(val)=>{this.setState({journaldebit_amount:val.target.value})}} />
                  </FormItem>

                  <FormItem label="Credit">
                    <Input name="Credit" type='number' placeholder="Credit" value={this.state.journalcredit_amount} onChange={(val)=>{this.setState({journalcredit_amount:val.target.value})}} />
                  </FormItem>

                  <FormItem label="Description">
                    <Input name="Description"  placeholder="Description" value={this.state.journal_description} onChange={(val)=>{this.setState({journal_description:val.target.value})}} />
                  </FormItem>

                  <FormItem label={"Account"} 
                   
                      >
                          <Select 
                          placeholder="Account" 
                          style={{  }} 
                          value={this.state.journal_account}
                          onChange={(val)=>{this.setState({journal_account:val})}} 
                          showSearch
                          optionFilterProp="children"
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          onSearch={this.onSearch}                    
                          >
                            {this.state.companyaccounts.map(
                              (accnt)=>(
                                <Option value={accnt.account_name}>{accnt.account_name} | {accnt.account_code}</Option>
                              ))}
                          </Select>
                      </FormItem>
                      
              </Modal>
          </div>
      ) }

   }
}

export default TillSheetReport; 
