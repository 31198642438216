import React from 'react';
import MemberForm from '../components/MemberForm'
import GroupForm from '../components/GroupForm'

import ActivateMemberForm from '../components/ActivateMemberForm'
import MemberImportForm from '../components/MemberImportForm'
import MemberTransactionsImportForm from '../components/MemberTransactionsmportForm'
import MembershipPaymentForm from '../components/MembershipPaymentForm'
import ApproveMemberForm from '../components/ApproveMemberForm'

import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Descriptions,Modal,Spin,Card,Tabs,Tooltip } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,RollbackOutlined,DeleteOutlined,FundViewOutlined,EditOutlined,DownloadOutlined,WalletFilled,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';
import ReactExport from "@ibrahimrahmani/react-export-excel";

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

var sacco= ''
var bizuserid= ''
var bizuserid= ''
var token= ''

class MemberList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    members: [],
    viewpaymentmodal:false,
    expensedetails:{},
    userprofile:{},
    datarequested:true,
    memberactivationformvisible:false,
    selectedmemberid:'',
    userrights:{},

    juniormembers: [],
    dormantaccounts: [],
    pendingaccounts:[],
    membsershippaymentvisible:false,
    memberaapprovalformvisible:false,
    approvedaccounts:[],
    rejectedaccounts:[],
    jointaccounts:[],
    ministryaccounts:[],

  };

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
       bizuserid= ''
    }
    
    if(localStorage.getItem("bizuserid")){
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
     }else{
        bizuserid= ''
     }
    
     if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

   this.componentmountactions();
}


//component mount method
componentmountactions=()=>{
  axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}&member_type=${'senior'}&account_status=${'active'}`)
  .then(res => {
      this.setState({
        members:res.data
      })
  })

  axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}&member_type=${'junior'}&account_status=${'active'}`)
  .then(res => {
      this.setState({
        juniormembers:res.data
      })
  })

  axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}&account_status=${'pending'}`)
  .then(res => {
      this.setState({
        pendingaccounts:res.data
      })
  })

  axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}&account_status=${'approved'}`)
  .then(res => {
      this.setState({
        approvedaccounts:res.data
      })
  })

  axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}&account_status=${'rejected'}`)
  .then(res => {
      this.setState({
        rejectedaccounts:res.data
      })
  })


  axios.get(serverconfig.backendserverurl+`/api/dormantaccounts/?sacco=${sacco}`)
  .then(res => {
      this.setState({
        dormantaccounts:res.data
      })
  })

  axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}&member_type=${'joint'}&account_status=${'active'}`)
  .then(res => {
      this.setState({
        jointaccounts:res.data
      })
  })

  axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}&member_type=${'ministry'}&account_status=${'active'}`)
  .then(res => {
      this.setState({
        ministryaccounts:res.data
      })
  })


  axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
  .then(res => {
      this.setState({
        userrights:res.data[0]
      })
      this.setState({datarequested:false})
  })

  axios.get(serverconfig.backendserverurl+`/api/accounts/${bizuserid}`)
  .then(res => {
      this.setState({
        userprofile:res.data
      })
      this.setState({datarequested:false})
  })

}

    //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    let form_data1 = new FormData();
    form_data1.append('itemid', id);
    form_data1.append('sacco', sacco);
    form_data1.append('table_model', "Member");

    axios.post(`${serverconfig.backendserverurl}/customqueries/puttorecyclebin`, form_data1, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      }).then(res =>{
      message.info("successfully deleted") 
      this.componentmountactions();
     }
     );
  };
  

    //open mdi guarantor form
    openmemberactivationform=(val)=>{
      this.setState({selectedmemberid:val})
      this.setState({memberactivationformvisible:true})
    }
  
    closememberactivationform=()=>{
      this.setState({memberactivationformvisible:false})
    }


getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};

  render() {

    var pendingcolumns = [
      {
        title: 'Temporary Account No',
        dataIndex: 'temporary_accountnumber',
        key: 'id',
        ...this.getColumnSearchProps('temporary_accountnumber'),
        render: (text,record) =><p>
          {
           record.tobe_reviewed?
             <p style={{color:'orange'}}>
              <Tooltip title={record.reason_for_review}>
              {record.temporary_accountnumber}
              </Tooltip>
              </p>
            :
             <p> {record.temporary_accountnumber}</p>
          }
        </p>
        
      },
      {
        title: 'Account Name',
        dataIndex: 'FullName',
        key: 'id',
        ...this.getColumnSearchProps('FullName'),
      },
      {
        title: 'Gender',
        dataIndex: 'gender',
        key: 'id',
        ...this.getColumnSearchProps('gender'),
      },
      {
        title: 'Account Type',
        dataIndex: 'accounttypename',
        key: 'id',
        ...this.getColumnSearchProps('accounttypename'),
      },
      {
        title: 'Account Contact',
        dataIndex: 'RegisteredPhoneNumber1',
        key: 'id',
        ...this.getColumnSearchProps('RegisteredPhoneNumber1'),
      },
      {
        title: 'Account Status',
        dataIndex: 'account_status',
        key: 'id',
        ...this.getColumnSearchProps('account_status'),
      },

      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
        <p>
        <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
         <Link to={`/members/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
        </Popover>
        &nbsp;&nbsp;&nbsp;

        {
          record.membership_cleared===true?
          null
          :
          <Popover content={<p>Click here to add membership payment</p>} title="Membership Payment">
          <WalletFilled  onClick={()=>{
                this.setState({selectedmemberid:text})
                this.setState({membsershippaymentvisible:true})
          }}/>
          </Popover>
        }

        &nbsp;&nbsp;&nbsp;
        {
          this.state.userrights.can_approvemembers && record.membership_cleared===true && record.tobe_reviewed===false?
          <Popover content={<p>Click here to approve</p>} title="Account Approval">
          <EditOutlined style={{color:'green'}} onClick={()=>{
            //this.openmemberactivationform(text)
            this.setState({selectedmemberid:text})
            this.setState({memberaapprovalformvisible:true})
            }}/>
          </Popover>
          :
          null
        }

          &nbsp;&nbsp;&nbsp;
        {
          record.tobe_reviewed===true && this.state.userrights.can_clear_members_from_preview===true?
          <Tooltip title={<p>Click here to clear member from preview state for account approval. Make sure the reason for account preview has been fullfilled.</p>} >
            <Popconfirm title="Are you sure?" onConfirm={() => {
            
            let form_data1 = new FormData();
            form_data1.append('member_id', record.id);
        
            axios.post(`${serverconfig.backendserverurl}/customqueries/clearmemberpreviewstate`, form_data1, {
              headers: {
                'content-type': 'multipart/form-data'
              }
              }).then(res =>{
                message.info("successfully clearance")
                window.location.reload(false)
             }
             );
            }}>
              <EditOutlined style={{color:'green'}}/>
          </Popconfirm>
          </Tooltip>
          :
          null
        }

        </p>,
      }

    ];


    var approvedcolumns = [
      {
        title: 'Account Number',
        dataIndex: 'AccountNumber',
        key: 'id',
        ...this.getColumnSearchProps('AccountNumber'),
      },
      {
        title: 'Account Name',
        dataIndex: 'FullName',
        key: 'id',
        ...this.getColumnSearchProps('FullName'),
      },
      {
        title: 'Gender',
        dataIndex: 'gender',
        key: 'id',
        ...this.getColumnSearchProps('gender'),
      },
      {
        title: 'Account Type',
        dataIndex: 'accounttypename',
        key: 'id',
        ...this.getColumnSearchProps('accounttypename'),
      },
      {
        title: 'Account Contact',
        dataIndex: 'RegisteredPhoneNumber1',
        key: 'id',
        ...this.getColumnSearchProps('RegisteredPhoneNumber1'),
      },
      {
        title: 'Account Status',
        dataIndex: 'account_status',
        key: 'id',
        ...this.getColumnSearchProps('account_status'),
      },

      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
        <p>
        <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
         <Link to={`/members/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
        </Popover>
        &nbsp;&nbsp;&nbsp;

        {
            this.state.userrights.accountactivation?
            <Popover content={<p>Click here to activate account</p>} title="Activate">
            <EditOutlined style={{color:'green'}} onClick={()=>{this.openmemberactivationform(text)}}/>
            </Popover>
            :
            null
          }
        </p>,
      }

    ];



    var rejectedcolumns = [
      {
        title: 'Temporary Account No',
        dataIndex: 'temporary_accountnumber',
        key: 'id',
        ...this.getColumnSearchProps('temporary_accountnumber'),
      },
      {
        title: 'Account Name',
        dataIndex: 'FullName',
        key: 'id',
        ...this.getColumnSearchProps('FullName'),
      },
      {
        title: 'Gender',
        dataIndex: 'gender',
        key: 'id',
        ...this.getColumnSearchProps('gender'),
      },
      {
        title: 'Account Type',
        dataIndex: 'accounttypename',
        key: 'id',
        ...this.getColumnSearchProps('accounttypename'),
      },
      {
        title: 'Account Contact',
        dataIndex: 'RegisteredPhoneNumber1',
        key: 'id',
        ...this.getColumnSearchProps('RegisteredPhoneNumber1'),
      },
      {
        title: 'Account Status',
        dataIndex: 'account_status',
        key: 'id',
        ...this.getColumnSearchProps('account_status'),
      },

      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
        <p>
        <Popover content={<p>Click here to view details & reason for rejection</p>} title="Edit,View">
         <Link to={`/members/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
        </Popover>
           &nbsp;&nbsp;&nbsp;

        {
          this.state.userrights.can_reverse_membership_approval===true?
          <Popover content={<p>Click here to resend back to pending </p>} title="Resend back">
            
             <Popconfirm title="Sure to resend?" onConfirm={() => {
                this.setState({datarequested:true})

                let form_data = new FormData();
                form_data.append('memberid', record.id);
                //Now submit sale data to database
                axios.post(serverconfig.backendserverurl+'/customqueries/sendbackaccounttopending', form_data,{
                  headers: {
                    'content-type': 'multipart/form-data'
                  }})
                .then(res =>{
                  this.setState({datarequested:false})
                  window.location.reload(false)
                }).catch(error => console.log(error)) 
                  }}>

            <RollbackOutlined style={{color:'blue'}} />
            </Popconfirm>
          </Popover>
          :
          null
        }

        </p>,
      }

    ];


      var columns = [
        {
          title: 'Account Number',
          dataIndex: 'AccountNumber',
          key: 'id',
          ...this.getColumnSearchProps('AccountNumber'),
        },
        {
          title: 'Account Name',
          dataIndex: 'FullName',
          key: 'id',
          ...this.getColumnSearchProps('FullName'),
        },
        {
          title: 'Gender',
          dataIndex: 'gender',
          key: 'id',
          ...this.getColumnSearchProps('gender'),
        },
        {
          title: 'Account Type',
          dataIndex: 'accounttypename',
          key: 'id',
          ...this.getColumnSearchProps('accounttypename'),
        },
        {
          title: 'Account Contact',
          dataIndex: 'RegisteredPhoneNumber1',
          key: 'id',
          ...this.getColumnSearchProps('RegisteredPhoneNumber1'),
        },
        {
          title: 'Account Status',
          dataIndex: 'account_status',
          key: 'id',
          ...this.getColumnSearchProps('account_status'),
        },
        {
          title: 'User',
          dataIndex: 'username',
          key: 'id',
          ...this.getColumnSearchProps('username'),
        },
        {
          title: 'Savings Balance',
          dataIndex: 'savings_balance',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Shares Balance',
          dataIndex: 'shareamount_balance',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'SuperSave Balance',
          dataIndex: 'supersaver_balance',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Teeba Ggoolo Balance',
          dataIndex: 'teebagoolo_balance',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },

        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: text =>
          <p>
          <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
           <Link to={`/members/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
          </Popover>
          &nbsp;&nbsp;&nbsp;

          {
            this.state.userrights.accountactivation?
            <Popover content={<p>Click here to activate account</p>} title="Activate">
            <EditOutlined style={{color:'green'}} onClick={()=>{this.openmemberactivationform(text)}}/>
            </Popover>
            :
            null
          }

          &nbsp;&nbsp;&nbsp;
          <Popover content={<p>Click here to delete</p>} title="Delete">
          <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
            <DeleteOutlined style={{color:'red'}}/>
           </Popconfirm>
          </Popover>
          </p>,
        }
  
      ];
    

      var juniorcolumns = [
        {
          title: 'Account Number',
          dataIndex: 'AccountNumber',
          key: 'id',
          ...this.getColumnSearchProps('AccountNumber'),
        },
        {
          title: 'Account Name',
          dataIndex: 'FullName',
          key: 'id',
          ...this.getColumnSearchProps('FullName'),
        },
        {
          title: 'Gender',
          dataIndex: 'gender',
          key: 'id',
          ...this.getColumnSearchProps('gender'),
        },
        {
          title: 'Account Type',
          dataIndex: 'accounttypename',
          key: 'id',
          ...this.getColumnSearchProps('accounttypename'),
        },
        {
          title: 'Account Contact',
          dataIndex: 'RegisteredPhoneNumber1',
          key: 'id',
          ...this.getColumnSearchProps('RegisteredPhoneNumber1'),
        },
        {
          title: 'Account Status',
          dataIndex: 'account_status',
          key: 'id',
          ...this.getColumnSearchProps('account_status'),
        },
        {
          title: 'User',
          dataIndex: 'username',
          key: 'id',
          ...this.getColumnSearchProps('username'),
        },
        {
          title: 'Savings Balance',
          dataIndex: 'savings_balance',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Shares Balance',
          dataIndex: 'shareamount_balance',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },

        {
          title: 'Parent Account Number',
          dataIndex: 'parentaccount_no',
          key: 'id',
          ...this.getColumnSearchProps('parentaccount_no'),
        },

        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: text =>
          <p>
          <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
           <Link to={`/members/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
          </Popover>
          &nbsp;&nbsp;&nbsp;

          {
            this.state.userrights.accountactivation?
            <Popover content={<p>Click here to activate account</p>} title="Activate">
            <EditOutlined style={{color:'green'}} onClick={()=>{this.openmemberactivationform(text)}}/>
            </Popover>
            :
            null
          }

          &nbsp;&nbsp;&nbsp;
          <Popover content={<p>Click here to delete</p>} title="Delete">
          <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
            <DeleteOutlined style={{color:'red'}}/>
           </Popconfirm>
          </Popover>
          </p>,
        }
  
      ];


      var dormantcolumns = [
        {
          title: 'Account Number',
          dataIndex: 'accountno',
          key: 'id',
          ...this.getColumnSearchProps('accountno'),
        },
        {
          title: 'Account Name',
          dataIndex: 'accountname',
          key: 'id',
          ...this.getColumnSearchProps('accountname'),
        },
        {
          title: 'Date Of Dormancy',
          dataIndex: 'date_ordormancy',
          key: 'id',
        },
      
        {
          title: 'Action',
          dataIndex: 'account',
          key: 'id',
          render: text =>
          <p>
          <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
           <Link to={`/members/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
          </Popover>
          &nbsp;&nbsp;&nbsp;
          {
            this.state.userrights.accountactivation?
            <Popover content={<p>Click here to activate account</p>} title="Activate">
            <EditOutlined style={{color:'green'}} onClick={()=>{this.openmemberactivationform(text)}}/>
            </Popover>
            :
            null
          }
          </p>,
        }
      ];



      var jointcolumns = [
        {
          title: 'Account Number',
          dataIndex: 'AccountNumber',
          key: 'id',
          ...this.getColumnSearchProps('AccountNumber'),
        },
        {
          title: 'Account Name',
          dataIndex: 'FullName',
          key: 'id',
          ...this.getColumnSearchProps('FullName'),
        },
        {
          title: 'Account Type',
          dataIndex: 'accounttypename',
          key: 'id',
          ...this.getColumnSearchProps('accounttypename'),
        },
        {
          title: 'Account Contact',
          dataIndex: 'RegisteredPhoneNumber1',
          key: 'id',
          ...this.getColumnSearchProps('RegisteredPhoneNumber1'),
        },
        {
          title: 'Account Status',
          dataIndex: 'account_status',
          key: 'id',
          ...this.getColumnSearchProps('account_status'),
        },
        {
          title: 'Savings Balance',
          dataIndex: 'savings_balance',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: text =>
          <p>
          <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
           <Link to={`/members/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
          </Popover>
          &nbsp;&nbsp;&nbsp;
          {
            this.state.userrights.accountactivation?
            <Popover content={<p>Click here to activate account</p>} title="Activate">
            <EditOutlined style={{color:'green'}} onClick={()=>{this.openmemberactivationform(text)}}/>
            </Popover>
            :
            null
          }
          </p>,
        }
      ];


      var ministrycolumns = [
        {
          title: 'Account Number',
          dataIndex: 'AccountNumber',
          key: 'id',
          ...this.getColumnSearchProps('AccountNumber'),
        },
        {
          title: 'Account Name',
          dataIndex: 'FullName',
          key: 'id',
          ...this.getColumnSearchProps('FullName'),
        },
        {
          title: 'Account Type',
          dataIndex: 'accounttypename',
          key: 'id',
          ...this.getColumnSearchProps('accounttypename'),
        },
        {
          title: 'Account Contact',
          dataIndex: 'RegisteredPhoneNumber1',
          key: 'id',
          ...this.getColumnSearchProps('RegisteredPhoneNumber1'),
        },
        {
          title: 'Account Status',
          dataIndex: 'account_status',
          key: 'id',
          ...this.getColumnSearchProps('account_status'),
        },
        {
          title: 'Savings Balance',
          dataIndex: 'savings_balance',
          key: 'id',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'Action',
          dataIndex: 'id',
          key: 'id',
          render: text =>
          <p>
          <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
           <Link to={`/members/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
          </Popover>
          &nbsp;&nbsp;&nbsp;

          {
            this.state.userrights.accountactivation?
            <Popover content={<p>Click here to activate account</p>} title="Activate">
            <EditOutlined style={{color:'green'}} onClick={()=>{this.openmemberactivationform(text)}}/>
            </Popover>
            :
            null
          }
          </p>,
        }
      ];


    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{
      return(
        <div>

        <Tabs defaultActiveKey="1" onChange={callback}>

           <TabPane tab="Pending Accounts" key="1">
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
                <Panel header={<h4 style={{fontWeight:'bolder'}}>PENDING ACCOUNTS</h4>} key="1">
                <Table   
                columns={pendingcolumns} 
                scroll={{ x: 1000 }}
                dataSource={this.state.pendingaccounts} 
                pagination={{showQuickJumper:true,showSizeChanger:true }}
                bordered/>
                <br />
                </Panel>
            </Collapse>
          </TabPane>

          <TabPane tab="Approved Accounts" key="2">
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
                <Panel header={<h4 style={{fontWeight:'bolder'}}>APPROVED ACCOUNTS</h4>} key="1">
                <Table   
                columns={approvedcolumns} 
                scroll={{ x: 1000 }}
                dataSource={this.state.approvedaccounts} 
                pagination={{showQuickJumper:true,showSizeChanger:true }}
                bordered/>
                <br />
                </Panel>
            </Collapse>
          </TabPane>

          <TabPane tab="Rejected Accounts" key="3">
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
                <Panel header={<h4 style={{fontWeight:'bolder'}}>REJECTED ACCOUNTS</h4>} key="1">
                <Table   
                columns={rejectedcolumns} 
                scroll={{ x: 1000 }}
                dataSource={this.state.rejectedaccounts} 
                pagination={{showQuickJumper:true,showSizeChanger:true }}
                bordered/>
                <br />
                </Panel>
            </Collapse>
          </TabPane>


            <TabPane tab="Personal Accounts" key="4">
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
                    <Panel header={<h4 style={{fontWeight:'bolder'}}>PERSONAL ACCOUNTS</h4>} key="1">
                    
              <Table   
              columns={columns} 
              scroll={{ x: 1000 }}
              dataSource={this.state.members} 
              pagination={{showQuickJumper:true,showSizeChanger:true }}
              bordered/>
              <br />
              </Panel>
              </Collapse>
              <br></br>

              <ExcelFile filename={"Pisys - Personal accounts report"}  element={<Button type='primary' icon={<DownloadOutlined/>}>Download Report</Button>}>
                <ExcelSheet data={this.state.members} name="Personal accounts">
                    <ExcelColumn label="Acc No" value="AccountNumber"/>
                    <ExcelColumn label="Acc Name" value="FullName"/>
                    <ExcelColumn label="Phone1" value="RegisteredPhoneNumber1"/>
                    <ExcelColumn label="Phone2" value="RegisteredPhoneNumber2"/>
                    <ExcelColumn label="Date of joining" value="DateOfJoining"/>
                    <ExcelColumn label="Gender" value="gender"/>
                </ExcelSheet>
            </ExcelFile>

            </TabPane>


            <TabPane tab="Joint Accounts" key="5">
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
                    <Panel header={<h4 style={{fontWeight:'bolder'}}>JOINT ACCOUNTS</h4>} key="1">
                    <Table   
                      columns={jointcolumns} 
                      scroll={{ x: 1000 }}
                      dataSource={this.state.jointaccounts} 
                      pagination={{showQuickJumper:true,showSizeChanger:true }}
                      bordered/>
              <br></br>
              <ExcelFile filename={"Pisys - Joint accounts report"}  element={<Button type='primary' icon={<DownloadOutlined/>}>Download Report</Button>}>
                <ExcelSheet data={this.state.jointaccounts} name="Joint accounts">
                    <ExcelColumn label="Acc No" value="AccountNumber"/>
                    <ExcelColumn label="Acc Name" value="FullName"/>
                    <ExcelColumn label="Phone1" value="RegisteredPhoneNumber1"/>
                    <ExcelColumn label="Date of joining" value="DateOfJoining"/>
                </ExcelSheet>
              </ExcelFile>
      </Panel>
                  </Collapse>
            </TabPane>

            <TabPane tab="Group Accounts" key="6">
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
                    <Panel header={<h4 style={{fontWeight:'bolder'}}>GROUP ACCOUNTS</h4>} key="1">
                    <Table   
                      columns={ministrycolumns} 
                      scroll={{ x: 1000 }}
                      dataSource={this.state.ministryaccounts} 
                      pagination={{showQuickJumper:true,showSizeChanger:true }}
                      bordered/>

                         <br></br>
                    <ExcelFile filename={"Pisys - Group accounts report"}  element={<Button type='primary' icon={<DownloadOutlined/>}>Download Report</Button>}>
                      <ExcelSheet data={this.state.jointaccounts} name="Group accounts">
                          <ExcelColumn label="Acc No" value="AccountNumber"/>
                          <ExcelColumn label="Acc Name" value="FullName"/>
                          <ExcelColumn label="Phone1" value="RegisteredPhoneNumber1"/>
                          <ExcelColumn label="Date of joining" value="DateOfJoining"/>
                      </ExcelSheet>
                    </ExcelFile>                

                </Panel>
              </Collapse>
                  
            </TabPane>

            <TabPane tab="Junior Accounts" key="7">
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
                    <Panel header={<h4 style={{fontWeight:'bolder'}}>JUNIOR ACCOUNTS</h4>} key="1">
                    <Table   
                    columns={juniorcolumns} 
                    scroll={{ x: 1000 }}
                    dataSource={this.state.juniormembers} 
                    pagination={{showQuickJumper:true,showSizeChanger:true }}
                    bordered/>
                    <br />
                    </Panel>
                    </Collapse>
                    <br></br>

                    <ExcelFile filename={"Pisys - Junior Members report"} element={<Button type='primary' icon={<DownloadOutlined/>}>Download Report</Button>}>
                      <ExcelSheet data={this.state.juniormembers} name="Junior Members Data">
                          <ExcelColumn label="Acc No" value="AccountNumber"/>
                          <ExcelColumn label="Acc Name" value="FullName"/>
                          <ExcelColumn label="Account Balance" value="UserBalance"/>
                          <ExcelColumn label="Saving Period" value="saving_period"/>
                          <ExcelColumn label="School" value="child_account_school"/>
                          <ExcelColumn label="Class" value="child_account_school_class"/>
                          <ExcelColumn label="Contact" value="RegisteredPhoneNumber1"/>
                      </ExcelSheet>
                  </ExcelFile>

            </TabPane>

            <TabPane tab="Dormant Accounts" key="8">
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
                    <Panel header={<h4 style={{fontWeight:'bolder'}}>DORMANT ACCOUNTS</h4>} key="1">
                    <Table   
                    columns={dormantcolumns} 
                    scroll={{ x: 1000 }}
                    dataSource={this.state.dormantaccounts} 
                    pagination={{showQuickJumper:true,showSizeChanger:true }}
                    bordered/>
                    <br />
                    </Panel>
                </Collapse>
                <br></br>

                  <ExcelFile filename={"Pisys - Dormant accounts report"}  element={<Button type='primary' icon={<DownloadOutlined/>}>Download Dormant Accounts Excel</Button>}>
                    <ExcelSheet data={this.state.dormantaccounts} name="Dormant Accounts">
                        <ExcelColumn label="Acc No" value="accountno"/>
                        <ExcelColumn label="Acc Name" value="accountname"/>
                        <ExcelColumn label="Date Of Dormancy" value="date_ordormancy"/>
                    </ExcelSheet>
                  </ExcelFile>
            </TabPane>
          </Tabs>

            <br></br>
            {
              this.state.userrights.can_register_members?
              <Row>
              <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
              <Collapse defaultActiveKey={['0']} onChange={callback}>
              <Panel header="Collapse Panel to open Single Member Form" key="1">
                <h2>Create new member here</h2>
                <MemberForm 
                requestType="post"
                expenseID={null}
                btnText="Create"
                /> 
              </Panel>
              </Collapse>  
                </Col>

               <Col xs="12" sm="6" lg="6" style={{padding: '5px'}}>
                <Card>

                {
                  this.state.userprofile.branch_manager===true?
                <div>
                <h4 style={{fontWeight:'bolder'}}>Upload members data here</h4>
                <MemberImportForm /> 
                <br></br>
                </div>
                :
                null
                }


                {
                  this.state.userprofile.branch_manager===true?
                  <div>

                  <h4 style={{fontWeight:'bolder'}}>Upload member transactions data here</h4>
                  <MemberTransactionsImportForm />   
  
                  </div>
                  :
                  null
                }

                </Card>
              </Col> 
              </Row>
              :
              null
            }

            <Modal              
              visible={this.state.memberactivationformvisible}
              title={"Member Activation Form"}
              onCancel={this.closememberactivationform}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={this.closememberactivationform}>
                  Cancel
                </Button>
                ]}
                >
                <ActivateMemberForm memberid={this.state.selectedmemberid} />

            </Modal> 

            <Modal              
              visible={this.state.membsershippaymentvisible}
              title={"Membership Payment Form"}
              onCancel={()=>{this.setState({membsershippaymentvisible:false})}}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={()=>{this.setState({membsershippaymentvisible:false})}}>
                  Cancel
                </Button>
                ]}
                >
                <MembershipPaymentForm memberid={this.state.selectedmemberid} />

            </Modal> 


            <Modal              
              visible={this.state.memberaapprovalformvisible}
              title={"Membership Approval Form"}
              onCancel={()=>{this.setState({memberaapprovalformvisible:false})}}
              style={{width:1000}}
              footer={[
                <Button key="back" onClick={()=>{this.setState({memberaapprovalformvisible:false})}}>
                  Cancel
                </Button>
                ]}
                >
                <ApproveMemberForm memberid={this.state.selectedmemberid} />

            </Modal> 
        </div>
    )

    }

  }
}

export default MemberList; 
