import React,{ useRef } from 'react';
import StaffForm from '../components/StaffForm'
import axios from 'axios'
import { Tooltip,Popover,Result,Table,Popconfirm,Input, Button,Collapse,Card,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin,Switch } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined,SettingFilled, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { DeleteOutlined,SearchOutlined,CameraOutlined,PlusOutlined,EditOutlined,PlusSquareFilled,SaveOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';
import { ToWords } from 'to-words';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const toWords = new ToWords();

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});

var token= ''
var sacco= ''
var username=''
var bizuserid= ''


class SaccoInterAccountTransfer extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    selectedaccount:'',
    selectedaccountobject:{},
    members:[],
    datarequested:true,
    totalamount:0,
    paidinby:'',
    transactorcontact:'',
    previoustransactions:[],
    datasubmittedsuccessfully:false,
    companyprofile:{},
    accountchange:false,
    userprofile:{},
    transaction_details:'',
    voucher_number:'',

    savingsamount:0,
    defsavings:0,
    supersavings:0,
    teebagooloamount:0,
    shares:0,
    companyaccounts: [],
    debitaccount:'',

    loan_amount:0,
    defsettings:{},
    defsett_modalvisible:false,
    noofinstallments:0,
    amountperinstallment:0,
    startingdate:'',

    availableloans:[],
    selectedloan:'',
    thermalprint:false,

    supersave_profile:{},
    teebagoolo_profile:{},
    creditaccount:'',
    transferamount:0

  }
  

  callback(key) {
    console.log(key);
  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    let form_data1 = new FormData();
    form_data1.append('userid', bizuserid);
    
    //get the user profile here
    axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data1,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          userprofile:res.data
        })
    })


    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
    })


    axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
            companyaccounts:res.data
        })
        this.setState({datarequested:false})
    })

  }

  
  ////////////////////////////////////
  // PRODUCT SEARCH SELECT METHODS
   onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }


  //submit the deposit now
  handleFormSubmit=(event) =>{
    
    if(Number(this.state.transferamount)<=0){
      message.error("Transfer amount cant be less or equal to zero")
    }else if(this.state.creditaccount===this.state.debitaccount){
      message.error("Receiver cant be the same as sender")
    }
    
    else{
      this.setState({datarequested:true})
      console.log(username)

      let form_data = new FormData();
      form_data.append('date',this.state.date);
      form_data.append('transferamount', this.state.transferamount);
      form_data.append('sacco', sacco);
      form_data.append('username', username);
      form_data.append('userid', bizuserid);
      form_data.append('creditaccount', this.state.creditaccount);
      form_data.append('debitaccount', this.state.debitaccount);
      axios.post(serverconfig.backendserverurl+'/customqueries/org_interaccounttransfer', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
      .then(res => {
        this.setState({datarequested:false})
        message.info(res.data.message)
        if (res.data.message==="success"){
          this.setState({datasubmittedsuccessfully:true})
        }
      })
      .catch(error => console.log(error))
    }
    
  }
  
  


  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>

            <Row >
                  <Col xs="12" sm="6" lg="6">
                    <Card>
                    <Result
                    status="success"
                    title="Successfully Transfer "
                    extra={[
                  
                      <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
                    ]}
                    />

                  </Card>
                  </Col>
            </Row>
          </div>

        )

      }else{
        return(
          <div>
            <div>
  
              <Row >
                <Col xs="12" sm="6" lg="6">
                <Card>

                 <h3 style={{fontWeight:'bold',alignSelf:'center',color:'#154360'}}> ORGANISATION INTERACCOUNT TRANSFER FORM</h3>
                  <Form
                      onFinish={(event) => this.handleFormSubmit(event)}
                  >

                  {
                    this.state.companyprofile.automated_transaction_date===true?
                    <FormItem label="Date"
                    name='date'
                    rules={[
                      {
                        required: true,
                        message: 'Please Select date',
                      },
                      ]}
                    >
                        <DatePicker value={moment()} onChange={(date, dateString) => this.setState({ date: dateString})} format={dateFormat} />
                    </FormItem>                 
                    :
                    null
                  }


                  <FormItem label={<h6 style={{fontWeight:'bold'}}>Tranfer Amount </h6>} >
                      <Input name="transferamount"   placeholder="0.0" value={this.state.transferamount} onChange={(e) => this.setState({ transferamount: e.target.value})}/>
                  </FormItem>


                  <FormItem label={<h4 style={{fontWeight:'bold'}}>Paying A/C (Credit A/C).</h4>}
                    name="creditaccount"
                    rules={[
                      {
                        required: true,
                        message: 'Please select account',
                      },
                    ]}
                  >
                        <Select 
                        placeholder="Account" 
                        style={{  }} 
                        value={this.state.creditaccount}
                        onChange={(val)=>{this.setState({creditaccount:val})}} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}                    
                        >
                          {this.state.companyaccounts.map(
                            (accnt)=>(
                              <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                            ))}
                        </Select>
                    </FormItem>

                   

                  <FormItem label={<h4 style={{fontWeight:'bold'}}>Receiving A/C (Debit A/C).</h4>}
                    name="debitaccount"
                    rules={[
                      {
                        required: true,
                        message: 'Please select account',
                      },
                    ]}
                  >
                        <Select 
                        placeholder="Account" 
                        style={{  }} 
                        value={this.state.debitaccount}
                        onChange={(val)=>{
                          if(val===this.state.creditaccount){
                            message.error("Receiver cant be the same as sender")
                          }else{
                            this.setState({debitaccount:val})

                          }
                        
                        }} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}                    
                        >
                          {this.state.companyaccounts.map(
                            (accnt)=>(
                              <Option value={accnt.id}>{accnt.account_name} | {accnt.account_code}</Option>
                            ))}
                        </Select>
                    </FormItem>


                  <FormItem>
                    <Button  type="primary" htmlType="submit">Transfer</Button>
                  </FormItem>
                  </Form>
                  </Card>
                   
                  </Col>

               </Row>

            </div>
       </div>              
                      
       )
      }
     
    }
   
  }
}

export default SaccoInterAccountTransfer; 
